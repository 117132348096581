import { FormControl, Validators } from '@angular/forms';
import { DateValidator } from '../../../create/properties/validator/date-validator';
import { ValidatorRegex } from '../../../create/properties/validator/validator-enum';

export interface UpdateSensorForm {
  instrumentType: FormControl;
  locationName: FormControl;
  startDate: FormControl;
  endDate: FormControl;
  xcoordinate: FormControl;
  ycoordinate: FormControl;
  zcoordinate: FormControl;
}

export const UpdateSensorFormConfig: UpdateSensorForm = {
  instrumentType: new FormControl(null, [
    Validators.required,
    Validators.maxLength(255),
  ]),
  locationName: new FormControl(null, [
    Validators.required,
    Validators.maxLength(40),
  ]),
  startDate: new FormControl(null, [
    Validators.required,
    DateValidator.validate(),
  ]),
  endDate: new FormControl(null, [
    DateValidator.validate(),
  ]),
  xcoordinate: new FormControl(null, [
    Validators.required,
    Validators.maxLength(10),
    Validators.pattern(ValidatorRegex.Decimal),
  ]),
  ycoordinate: new FormControl(null, [
    Validators.required,
    Validators.maxLength(10),
    Validators.pattern(ValidatorRegex.Decimal),
  ]),
  zcoordinate: new FormControl(null, [
    Validators.maxLength(10),
    Validators.pattern(ValidatorRegex.Decimal),
  ]),
};
