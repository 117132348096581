import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Sensor } from '../../../model/sensor.model';
import {
  GeneralUpdateLocationForm,
  HeightOfTideUpdateLocationForm,
  TidalStreamUpdateLocationForm,
} from './update-location-form';
import { ActivatedRoute } from '@angular/router';
import { UpdateLocationFormController } from './update-location-form-controller';
import { ValidatorMessage } from '../../../create/properties/validator/validator-enum';
import { ModalComponent } from '../../../../../shared/modal/modal.component';
import { UpdateSensorDto } from '../../../model/update-sensor-dto.model';
import { Feature } from '../../../model/feature.model';
import { MeasurementLocation } from '../../../../../shared/iot-platform/model/measurement-location';
import { MeasurementLocationFactory } from '../../../factory/measurement-location-factory';
import { SensorService } from '../../../service/sensor.service';
import { UpdateSensorDtoFactory } from '../../../factory/update-sensor-dto.factory';
import { FeatureTypeService } from '../../../service/feature-type.service';
import { FeatureType } from '../../../model/feature-type.model';
import { LocationEditingMode } from './location-editing-mode';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/mapTo';
import { SessionService } from '../../../../../shared/service/session.service';
import { FeatureService } from '../../../service/feature.service';
import { SensorTypeEnum } from '../../../../../shared/utils/sensor-type-enum';

@Component({
  selector: 'app-update-feature',
  templateUrl: './update-location.component.html',
  styleUrls: ['./update-location.component.scss'],
})
export class UpdateLocationComponent implements OnInit {
  public locationEditingMode = LocationEditingMode;

  public editingMode: LocationEditingMode = this.locationEditingMode.NOT_EDITING;

  public updateFeatureController: UpdateLocationFormController;
  public validatorMessage = ValidatorMessage;
  public updateLocationForm: FormGroup;

  @ViewChild('locationModal')
  public locationModal: ModalComponent;

  private updateSensorDtoFactory: UpdateSensorDtoFactory = new UpdateSensorDtoFactory();

  @Input()
  public sensor: Sensor;
  private featureId: string;
  public featureTypeNames: string[];

  constructor(private route: ActivatedRoute,
              private formBuilder: FormBuilder,
              private sensorService: SensorService,
              private featureTypeService: FeatureTypeService,
              private featureService: FeatureService,
              private sessionService: SessionService) {
  }

  public ngOnInit() {
    if (this.sensor.sensorType.name === SensorTypeEnum.TIDAL_STREAM) {
      this.updateLocationForm = this.formBuilder.group(TidalStreamUpdateLocationForm);
    } else if (this.sensor.sensorType.name === SensorTypeEnum.HEIGHT_OF_TIDE) {
      this.updateLocationForm = this.formBuilder.group(HeightOfTideUpdateLocationForm);
    } else {
      this.updateLocationForm = this.formBuilder.group(GeneralUpdateLocationForm);
    }
    this.updateFeatureController = new UpdateLocationFormController(this.updateLocationForm);
  }

  public onCancelClick(): void {
    this.hide();
    this.resetState();
  }

  public submit(): void {
    let observable: Observable<void> = null;

    switch (this.editingMode) {
      case LocationEditingMode.ADD_FEATURE_LOCATION:
        observable = this.submitNewFeature();
        break;
      case LocationEditingMode.EDIT_SENSOR_LOCATION:
        observable = this.submitSensorChanges();
        break;
      case LocationEditingMode.EDIT_FEATURE_LOCATION:
        observable = this.submitFeatureChanges();
        break;
      default:
        observable = Observable.of(undefined);
        break;
    }

    observable.subscribe(
      () => {
        this.resetState();
        this.locationModal.hide();
      }
    );
  }

  public submitNewFeature(): Observable<void> {
    const measurementLocation = this.updateFeatureController.getMeasurementLocation();

    return this.sensorService.addSensorLocation(measurementLocation, this.sensor.id)
      .switchMap(() => this.featureService.getSensorFeatures(this.sensor.id))
      .do((features: Feature[]) => this.sessionService.updateSharedFeatures(features))
      .mapTo(undefined);
  }

  public submitFeatureChanges(): Observable<void> {
    const measurementLocation = this.updateFeatureController.getMeasurementLocation();

    return this.sensorService.updateSensorLocation(measurementLocation, this.sensor.id, this.featureId)
      .switchMap(() => this.featureService.getSensorFeatures(this.sensor.id))
      .do((features: Feature[]) => this.sessionService.updateSharedFeatures(features))
      .mapTo(undefined);
  }

  public submitSensorChanges(): Observable<void> {
    const updateSensorDto: UpdateSensorDto = this.createUpdateSensorDto();

    return this.sensorService.updateSensor(updateSensorDto, this.sensor.id)
      .switchMap(() => this.sensorService.getSensor(this.sensor.id))
      .do((sensor: Sensor) => this.sessionService.updateSharedSensor(sensor))
      .mapTo(undefined);
  }

  public editSensor(sensor: Sensor): void {
    this.setEditingMode(LocationEditingMode.EDIT_SENSOR_LOCATION);

    const measurementLocation = this.sensorToMeasurementLocation(sensor);
    this.updateLocationForm.setValue(measurementLocation);
    this.featureTypeNames = Array.of(measurementLocation.type);
    this.updateFeatureController.typeControl.disable();
    this.updateFeatureController.nameControl.disable();
    this.show();
  }

  public editFeature(feature: Feature): void {
    this.setEditingMode(LocationEditingMode.EDIT_FEATURE_LOCATION);
    this.featureId = feature.id;

    const measurementLocation = MeasurementLocationFactory.mapFeature(feature);
    this.updateLocationForm.patchValue(measurementLocation);
    this.updateFeatureController.resetForm(measurementLocation);
    this.featureTypeNames = Array.of(measurementLocation.type);
    this.updateFeatureController.typeControl.disable();
    this.updateFeatureController.nameControl.disable();
    this.show();
  }

  public addFeature(): void {
    this.setEditingMode(LocationEditingMode.ADD_FEATURE_LOCATION);

    this.updateLocationForm.reset();
    this.featureTypeService.getAllFeatureTypesBySensorType(this.sensor.sensorType.name)
      .subscribe((featureTypes: FeatureType[]) => {
        this.featureTypeNames = featureTypes.map(featureType => featureType.name);
        if (this.featureTypeNames && this.featureTypeNames.length > 0) {
          this.updateFeatureController.typeControl.setValue(this.featureTypeNames[0]);
        }
        this.updateFeatureController.typeControl.enable();
        this.updateFeatureController.nameControl.enable();
        this.show();
      });
  }

  public show(): void {
    this.locationModal.show();
  }

  public hide(): void {
    this.locationModal.hide();
  }

  private createUpdateSensorDto(): UpdateSensorDto {
    return this.updateSensorDtoFactory.create(
      this.sensor.startDate.toISOString(),
      this.sensor.endDate ? this.sensor.endDate.toISOString() : null,
      this.sensor.instrumentType,
      this.sensor.locationName,
      this.updateFeatureController.xCoordinate,
      this.updateFeatureController.yCoordinate,
      this.updateFeatureController.zCoordinate);
  }

  private sensorToMeasurementLocation(sensor: Sensor): MeasurementLocation {
    return MeasurementLocationFactory.create(
      sensor.name,
      '',
      undefined,
      sensor.x,
      sensor.y,
      sensor.z,
      sensor.endDate
      );
  }

  private setEditingMode(editingMode: LocationEditingMode): void {
    this.editingMode = editingMode;
  }

  private resetState(): void {
    this.featureId = '';
  }

  public isFormInvalid(): boolean {
    if (this.editingMode === this.locationEditingMode.EDIT_FEATURE_LOCATION ||
      this.editingMode === this.locationEditingMode.ADD_FEATURE_LOCATION) {

      return this.isUpdateFeatureFormInvalid();
    }
    if (this.editingMode === this.locationEditingMode.EDIT_SENSOR_LOCATION ) {
      return this.isUpdateSensorLocationFormValid();
    }
    return true;
  }

  private isUpdateSensorLocationFormValid() {
    return this.updateFeatureController.xCoordinateControl.invalid ||
      this.updateFeatureController.yCoordinateControl.invalid ||
      this.updateFeatureController.zCoordinateControl.invalid;
  }

  private isUpdateFeatureFormInvalid(): boolean {
    if (this.updateFeatureController.type === 'Tidal stream' || this.updateFeatureController.type === 'Height of tide') {
      return this.updateLocationForm.invalid;
    } else {
      return this.updateFeatureController.typeControl.invalid ||
        this.updateFeatureController.nameControl.invalid ||
        this.updateFeatureController.descriptionControl.invalid ||
        this.updateFeatureController.xCoordinateControl.invalid ||
        this.updateFeatureController.yCoordinateControl.invalid ||
        this.updateFeatureController.zCoordinateControl.invalid ||
        this.updateFeatureController.endDateControl.invalid;
    }
  }
}
