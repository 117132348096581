import { SessionService } from '../../../shared/service/session.service';
import { AuthorizationComponent } from '../../authorization/authorization.component';
import { GenericMetaData } from '../model/generic-meta-data';
import { searchProperties } from './search-properties';

export class TableFunctionality extends AuthorizationComponent  {

  public dateTo: Date;
  public dateFrom: Date;

  private readonly statusKeySuccess: string = searchProperties.statusSuccess;
  private readonly statusKeySucceeded: string = searchProperties.statusSucceeded;
  private readonly statusKeyFailed: string = searchProperties.statusFailed;
  private readonly statusKey: string = searchProperties.statusKey;

  public searchTerm: string;

  public displayStatusSucceeded: boolean;
  public displayStatusFailed: boolean;

  public filteredMetaData: any;
  public listMetaData: any;
  public steps: number[];
  public step: any;
  public page: any;
  public isPreviousButtonDisabled: any;
  public maxPage: any;
  public isNextButtonDisabled: any;
  public pages: any;
  public displayedMetaData: any;

  constructor(listMetaData, sessionService: SessionService, resource: string, rights: string[]) {
    super(sessionService, resource, rights);

    this.listMetaData               = listMetaData.reverse();
    this.displayedMetaData          = listMetaData;
    this.displayStatusFailed        = true;
    this.displayStatusSucceeded     = true;
    this.steps                      = [10, 20, 30, 40, 50, 100];
    this.step                       = this.steps[4];
    this.page                       = 0;
  }

  /* creating method for setting the list of metadata
   * and the displayed metadata */

  // setting list of metadata
  public setListMetaData(metaData: any[]): void {
    this.listMetaData = metaData;
  }

  // get list of metadata
  public getListMetadata(): any[] {
    return this.listMetaData;
  }

  // setting displayedMetadata
  public setDisplayedMetadata(metaData: any[]): void {
    this.displayedMetaData = metaData;
  }

  public filterMetaData(): void {
    this.filteredMetaData = [];
    if (this.searchTerm !== undefined) {
      this.page = 0;
    }

    for (const metaData of this.listMetaData) {
      if (metaData) {
        if (this.shouldFilterOnDates(metaData) && this.shouldFilterOnStatus(metaData)
          && this.shouldFilterSearchTerm(metaData)) {
          this.filteredMetaData.push(metaData);
        }
      }
    }
  }

  private shouldFilterOnDates(metaData: any): boolean {
    const metaDataGeneric = metaData as GenericMetaData;
    const metaDataDate: Date = metaDataGeneric.getDate();

    if ((this.dateFrom === undefined || metaDataDate.getTime() >= new Date(this.dateFrom).getTime())
    && (this.dateTo === undefined || metaDataDate.getTime() <= new Date(this.dateTo).getTime())) {

      return true;
    }

    return false;
  }

  private shouldFilterSearchTerm(metaData: any): boolean {
    if (this.searchTerm !== undefined) {
      const termToLowerCase = this.searchTerm.toLowerCase();
      for (const property in metaData) {
        if (metaData.hasOwnProperty(property)) {
          if ((metaData[property] + '').toLowerCase().indexOf(termToLowerCase) > -1) {
            return true;
          }
        }
      }
    } else {
      return true;
    }

    return false;
  }

  private shouldFilterOnStatus(metaData: any): boolean {
    if (metaData.hasOwnProperty(this.statusKey)) {
      const metaDataStatus = metaData[this.statusKey].toUpperCase();

      const hasStatusSuccessful = (
        metaDataStatus === this.statusKeySucceeded
        || metaDataStatus === this.statusKeySuccess);
      const hasStatusFailed = metaDataStatus === this.statusKeyFailed;

      if (this.displayStatusSucceeded && hasStatusSuccessful) {
        return true;
      } else if (this.displayStatusFailed && hasStatusFailed) {
        return true;
      } else {
        return false;
      }
    }

    return false;
  }

  public updateMetaDataArray(): void {
    const currentMetaData = [];
    for (let i = this.page * this.step; i < this.step + this.page * this.step; i++) {
      if (this.filteredMetaData[i]) {
        currentMetaData.push(this.filteredMetaData[i]);
      } else {
        break;
      }
    }

    this.displayedMetaData = currentMetaData;
    this.maxPage           = Math.ceil(this.filteredMetaData.length / this.step);
    this.pages             = [];

    for (let i = 0; i < this.maxPage; i++) {
      this.pages.push(i);
    }

    this.isPreviousButtonDisabled = this.page <= 0;
    this.isNextButtonDisabled     = this.page >= this.maxPage - 1;
  }

  public changePage(page: number): void {
    this.page = page;

    this.updateMetaDataArray();
  }

  public changeStep(step: number): void {
    this.step = step;
    this.page = 0;
    this.updateMetaDataArray();
  }
}
