import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { environment } from '../../../../../../../../../environments/environment';
import { Observation } from '../../../../../../../observation/model/observation.model';
import { ObservationChartSerie } from '../model/observation-chart-series.model';
import { StructuredObservations } from '../model/structured-observations.model';
import {
  ObservationDefaultHidden,
  ObservationMarkerEnabled,
  ObservationMarkerIndex,
  ObservationMarkerSymbols,
  ObservationRounding,
  ObservationTypesColors,
  ObservationTypesEnabledInNavigator,
  ObservationTypesGapSize,
  ObservationTypesLineWidth,
  ObservationTypesMarkerRadius,
} from '../properties/default-series-properties';

import * as moment from 'moment';

const shownCheckResultStatus: Array<string> = ['failed', 'invalidated'];

@Injectable()
export class ObservationsConvertService {


  constructor(private logger: NGXLogger) {
    this.logger.debug('constructing ObservationConvertService.');
  }

  /**
   * This function create the lines for the chart and also the related checks.
   * Observations have to be in order earliest -> latest!
   * @param structuredObservations the source for creating the line
   */
  public convertToChartStreamSeries(structuredObservations: StructuredObservations): ObservationChartSerie[] {

    // initialize variables
    const firstObservation = structuredObservations.observations[0];

    const observationChartSeries
      = new ObservationChartSerie(structuredObservations.stream, [], firstObservation.ticks,
        ObservationTypesColors[structuredObservations.stream], ObservationTypesLineWidth[structuredObservations.stream],
        !!ObservationTypesEnabledInNavigator[structuredObservations.stream]);
    observationChartSeries.marker.symbol = ObservationMarkerSymbols[structuredObservations.stream];
    observationChartSeries.gapSize = ObservationTypesGapSize[structuredObservations.stream];

    const observationChartSeriesChecks
      = new ObservationChartSerie(structuredObservations.stream + ' - CHECKS', [], firstObservation.ticks,
        ObservationTypesColors['CHECKS'], ObservationTypesLineWidth['CHECKS'],
      !!ObservationTypesEnabledInNavigator[structuredObservations.stream], ':previous', 1, false);

    observationChartSeriesChecks.gapSize =  ObservationTypesGapSize[structuredObservations.stream];

    // push data to the series.
    observationChartSeries.data = this.convertToChartStreamSeriesData(structuredObservations.observations);
    if (ObservationMarkerIndex[observationChartSeries.name] !== undefined) {
      observationChartSeries.yAxis = ObservationMarkerIndex[observationChartSeries.name];
    }

    if (ObservationDefaultHidden[observationChartSeries.name] !== undefined) {
      observationChartSeries.visible = false;
    }

    observationChartSeriesChecks.data = this.convertToChartStreamSeriesCheckData(structuredObservations.observations);
    return [observationChartSeries, observationChartSeriesChecks];
  }

  /**
   * This function converts observation (their checks) to a represenatble line on the chart.
   *
   * @param observations the observation to create check series from.
   */
  public convertToChartStreamSeriesCheckData(observations: Observation[]): any {

    const points = [];

    for (const observation of observations) {

      let valueToPlot = 1;

      for (const result in observation.results) {
        if (shownCheckResultStatus.indexOf(observation.results[result]) !== -1) {

          const tooltip = observation.datastream + ': ' + result + ': ' + observation.results[result].toUpperCase();
          const marker = {
            enabled: true,
            radius: ObservationTypesMarkerRadius['CHECKS'],
            symbol: ObservationMarkerSymbols[result],
          };

          points.push({
            x: observation.ticks, y: valueToPlot, tooltip, marker,
          });
          // this causes the the failed checks for every point to be seperated from each other.
          valueToPlot++;
        }
      }
    }

    return points;
  }

  /**
   * convert data to be presentable in a line on the chart.
   *
   * @param observations observation to create a points in the line.
   */
  public convertToChartStreamSeriesData(observations: Observation[]): any {

    const observationValuePoints: any[] = [];

    for (const observation of observations) {

      let value;
      if (observation.value) {
        if (ObservationRounding[observation.datastream] !== undefined) {
          value = parseFloat(observation.value.toFixed(parseFloat(ObservationRounding[observation.datastream])));
        } else {
          value = parseFloat(observation.value.toFixed(2));
        }
      } else {
        // if observation has no value, show a gap in the line
        value = null;
      }

      const marker = {
        enabled: !!ObservationMarkerEnabled[observation.datastream],
        radius: ObservationTypesMarkerRadius[observation.datastream],
      };

      // We want to show latency when the test environment is enabled.
      if (environment.env !== 'test') {
        observationValuePoints.push({ x: observation.ticks, y: value, tooltip: observation.datastream + ': ' + value + observation.uom, marker });
      } else {
        // latency is moment of insertion in the chart minus phenomentime
        const latency: number = ((moment().utc().toDate().getTime() - moment(observation.phenomenonTime).toDate().getTime()) / 1000);
        // Tooltips have to be defined on point level as per highcharts API docs.
        const tooltip: string = observation.datastream + ': ' + value + '<br>Latency: ' + latency + 's';
        observationValuePoints.push({ x: observation.ticks, y: value, tooltip, marker });
      }

    }

    return observationValuePoints;
  }
}
