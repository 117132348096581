import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs/Observable';
import { ApiDataService } from '../../../api/api.data.service';
import { OsrMetaData } from '../model/osr-meta-data.model';

@Injectable()
export class OsrMetaDataService {

  constructor(private logger: NGXLogger,
              private apiDataService: ApiDataService) {
    this.logger.debug('constructing OsrDataService');
  }

  public getAllOsrMetaData(): Observable<OsrMetaData[]> {
    this.logger.debug('AstronomicalDataService: retrieving metadata.');
    return this.apiDataService.getAll('filemetadata/osr');
  }

}
