import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { MapComponent } from './map.component';
import { MapRoutingModule } from './map.routing.module';

import { SharedModule } from '../../shared/shared.module';
import { UiModule } from '../../shared/ui/ui.module';
import { EsriMapService } from './service/map.service';

@NgModule({
  imports: [
    RouterModule,
    SharedModule,
    UiModule,
    MapRoutingModule,
  ],
  providers: [
    EsriMapService,
  ],
  declarations: [MapComponent],
})
export class MapModule {
}
