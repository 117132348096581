import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { ObservationChartSerie } from './sensor/model/observation-chart-series.model';

@Injectable()
export class ObservationChartService {

  chartSeries: ObservationChartSerie[] = [];

  constructor(private logger: NGXLogger) {
    this.logger.debug('ObservationChartService: constructing.');
  }

}
