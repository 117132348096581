import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';

import 'rxjs/add/observable/forkJoin';

export let mockResources = [
  {
    id: '1',
    url: '/sensors',
  },
  {
    id: '2',
    url: '/sensors/observations',
  },
  {
    id: '3',
    url: '/sensors/checks',
  },
  {
    id: '4',
    url: '/sensors/publishing',
  },
  {
    id: '5',
    url: '/sensors/csvexport',
  },
  {
    id: '6',
    url: '/map',
  },
  {
    id: '7',
    url: '/imports/rws',
  },
  {
    id: '8',
    url: '/imports/osr',
  },
  {
    id: '9',
    url: '/upload/astro',
  },
  {
    id: 10,
    url: '/external-interfaces',
  },
  {
    id: 11,
    url: '/imports',
  },
  {
    id: 12,
    url: '/watson',
  },
  {
    id: 13,
    url: '/alerts',
  },
];

export let mockRights = [
  {
    id: '1',
    type: 'readAll',
  },
  {
    id: '2',
    type: 'readOne',
  },
  {
    id: '3',
    type: 'create',
  },
  {
    id: '4',
    type: 'update',
  },
  {
    id: '5',
    type: 'delete',
  },
];

export let mockResourceRights = [
  // sensorManager
  [
    {
      resource: mockResources[0],
      rights: mockRights,
    },
    {
      resource: mockResources[1],
      rights: mockRights,
    },
    {
      resource: mockResources[2],
      rights: [mockRights[0], mockRights[1], mockRights[3]],
    },
    {
      resource: mockResources[3],
      rights: mockRights,
    },
    {
      resource: mockResources[4],
      rights: mockRights,
    },
    {
      resource: mockResources[5],
      rights: mockRights,
    },
    {
      resource: mockResources[6],
      rights: mockRights,
    },
    {
      resource: mockResources[7],
      rights: mockRights,
    },
    {
      resource: mockResources[8],
      rights: mockRights,
    },
    {
      resource: mockResources[9],
      rights: mockRights,
    },
    {
      resource: mockResources[10],
      rights: mockRights,
    },
    {
      resource: mockResources[11],
      rights: mockRights,
    },
    {
      resource: mockResources[12],
      rights: mockRights,
    },
  ],
  // guest
  [
    {
      resource: mockResources[0],
      rights: [mockRights[0], mockRights[1]],
    },
    {
      resource: mockResources[1],
      rights: [mockRights[0], mockRights[1]],
    },
    {
      resource: mockResources[2],
      rights: [mockRights[0], mockRights[1]],
    },
    {
      resource: mockResources[5],
      rights: [mockRights[0], mockRights[1]],
    },
    {
      resource: mockResources[6],
      rights: [mockRights[0], mockRights[1]],
    },
    {
      resource: mockResources[7],
      rights: [mockRights[0], mockRights[1]],
    },
    {
      resource: mockResources[8],
      rights: [mockRights[0], mockRights[1]],
    },
    {
      resource: mockResources[9],
      rights: mockRights,
    },
    {
      resource: mockResources[10],
      rights: mockRights,
    },
  ],
];

export let mockRoles = [
  {
    id: 1,
    name: 'HM_SensorMgr',
    resourceRights: mockResourceRights[0],
  },
  {
    id: 2,
    name: 'HM_Guest',
    resourceRights: mockResourceRights[1],

  },
];

@Injectable()
export class ResourceRightsService {

  public getRoles(names: string[]): Observable<any> {
    const roles: Array<Observable<any>> = [];

    for (const roleName of names) {
      switch (roleName) {
        case 'HM_SensorMgr': {
          roles.push(Observable.of(mockRoles[0]));
          break;
        }
        case 'HM_Guest': {
          roles.push(Observable.of(mockRoles[1]));
          break;
        }
      }
    }

    return Observable.forkJoin(roles);
  }
}
