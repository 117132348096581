import {Component, OnInit} from '@angular/core';

@Component({
  selector:    'app-ext-interface',
  templateUrl: './ext-interface.component.html',
  styleUrls:   ['./ext-interface.component.scss'],
})
export class ExtInterfaceComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }

}
