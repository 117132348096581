import { Component } from '@angular/core';
import { NGXLogger } from 'ngx-logger';

@Component({
  template: '<ng-content></ng-content>',
})
export class AuthenticationComponent {

  constructor(private logger: NGXLogger) {
    this.logger.debug('constructing AuthComponent');
  }
}
