import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BasePagination } from './model/abstract/pagination.abstract';
import { PaginationProperties } from './model/pagination.properties';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent extends BasePagination implements OnInit {

  /* input(s) for property binding purpose
   * @input: steps , the number of steps
   * @input: pages, the number of pages
   * @input: step, current step selected
   * @input: page, current page number selected
   * @input: maxPage, maximum page available
   * @input: filteredData, filtered data to sync with search bar function
   * @input: displayed data
   */

  @Input() steps: number[];
  @Input() pages: number[];
  @Input() step: number;
  @Input() page: number;
  @Input() maxPage: number;

  @Input() filteredData: any[];
  @Input() displayedData: any[];

  /* input(s) for button(s) configuration
   * @input: isPreviousButtonDisabled, check whether the previous button is disabled
   * @input: isNextButtonDisabled, check whether the next button is disabled */
  @Input() isPreviousButtonDisabled: boolean;
  @Input() isNextButtonDisabled: boolean;

  /* output(s) for emitting event to change page
   * @output: change step
   * @output: change page event emitter */

  // updating step
  @Output() updateStep: EventEmitter<any> = new EventEmitter<any>();

  // updating previous button
  @Output() clickPreviousButton: EventEmitter<any> = new EventEmitter<any>();

  // updating next button
  @Output() clickNextButton: EventEmitter<any> = new EventEmitter<any>();

  constructor() {
    super();
  }

  ngOnInit() {

  }

  /* UPDATING STEP */
  public updatingStepEventEmitter(): void {
    this.updateStep.emit(this.changeStepAndUpdatePagingProperties());
  }

  /* CLICKING PREVIOUS BUTTON */
  public clickPreviousButtonEventEmitter(): void {
    this.clickPreviousButton.emit(this.clickPreviousButtonAndUpdatePagingProperties());
  }

  /* CLICKING NEXT BUTTON */
  public clickNextButtonEventEmitter(): void {
    this.clickNextButton.emit(this.clickNextButtonAndUpdatePagingProperties());
  }

  /* updating pagination properties when step is change
   * when step is changing then displayed data should be updated */
  public changeStepAndUpdatePagingProperties(): PaginationProperties {

    const properties = this.setPaginationProperties();
    const updatedStepValue = this.changeStepValue(this.step);
    const updatedDisplay = this.updateDisplayedDataArray(updatedStepValue, properties.getPage());

    properties.setDisplayedData(updatedDisplay);
    properties.setMaxPage(this.retrieveMaxPage());
    properties.setPages(this.setPages());
    properties.setIsPreviousButtonDisabled(properties.getPage() <= 0);
    properties.setIsNextButtonDisabled(properties.getPage() >= properties.getMaxPage() - 1);

    return properties;
  }

  /* updating pagination properties to parent component when previous button is clicked
   * when clicked display should change*/
  public clickPreviousButtonAndUpdatePagingProperties(): PaginationProperties {
    const properties = this.setPaginationProperties();
    const changeToPreviousPage = this.changePageValue(this.page - 1);
    const updatedDisplay = this.updateDisplayedDataArray(properties.getStep(), changeToPreviousPage);

    // updating pages
    properties.setDisplayedData(updatedDisplay);
    properties.setPage(properties.getPage() - 1);
    properties.setMaxPage(this.retrieveMaxPage());
    properties.setPages(this.setPages());
    properties.setIsPreviousButtonDisabled(properties.getPage() <= 0);
    properties.setIsNextButtonDisabled(properties.getPage() >= properties.getMaxPage() - 1);

    return properties;
  }

  /* updating pagination properties to parent component when next button is clicked
   * when clicked display should change */
  public clickNextButtonAndUpdatePagingProperties(): PaginationProperties {
    const properties = this.setPaginationProperties();
    const changeToNextPage = this.changePageValue(this.page + 1);
    const updatedDisplay = this.updateDisplayedDataArray(properties.getStep(), changeToNextPage);

    /* updating properties
     to make sure paging properties are properly updated */
    properties.setDisplayedData(updatedDisplay);
    properties.setPage(properties.getPage() + 1);
    properties.setMaxPage(this.retrieveMaxPage());
    properties.setPages(this.setPages());
    properties.setIsPreviousButtonDisabled(properties.getPage() <= 0);
    properties.setIsNextButtonDisabled(properties.getPage() >= properties.getMaxPage() - 1);

    return properties;
  }

  /* setting pagination properties that will be used
   * as data exchange mechanism / to be updated
   * in the UI */
  public setPaginationProperties(): PaginationProperties {
    const paginationProperties = new PaginationProperties();

    paginationProperties.setDisplayedData(this.filteredData);
    paginationProperties.setStep(this.step);
    paginationProperties.setPage(this.page);
    paginationProperties.setMaxPage(this.maxPage);
    paginationProperties.setPages(this.pages);
    paginationProperties.setIsNextButtonDisabled(this.isNextButtonDisabled);
    paginationProperties.setIsPreviousButtonDisabled(this.isPreviousButtonDisabled);
    paginationProperties.setFilteredData(this.filteredData);

    return paginationProperties;
  }

  /* displayed data takes input from search bar component
   * the display data will change when pagination buttons are interacted  */
  public updateDisplayedDataArray(step: number, page: number): any {
    const currentDisplayedData = [];
    const searchedData = this.filteredData;
    for (let i = page * step; i < step + page * step; i++) {
      if (searchedData[i]) {
        currentDisplayedData.push(searchedData[i]);
      } else {
        break;
      }
    }
    return currentDisplayedData;
  }

  /* retrieving value for maximum page number */
  public retrieveMaxPage(): number {
    return Math.ceil(this.filteredData.length / this.step);
  }

  /* setting pages */
  public setPages(): number[] {
    const pages = [];
    for (let i = 0; i < this.retrieveMaxPage(); i++) {
      pages.push(i);
    }
    return pages;
  }

  /* setting previous button */
  public setDisablePreviousButton(): boolean {
    return this.page <= 0;
  }

  /* setting next button */
  public setDisableNextButton() {
    return this.page >= this.retrieveMaxPage() - 1;
  }

  /* retrieve size of displayed data */
  public retrieveSizeOfDisplayedData(): number {
    return this.filteredData.length;
  }

  /* retrieve minimum/lowest boundary for displayed data in UI */
  public retrieveMinSizeOfDisplayedData(): number {
    let value: number = 0;
    if (this.filteredData.length === 0) {
      value = 0;
    } else {
      value = (this.step * this.page) + 1;
    }
    return value;
  }

  /* retrieve maximum/highest boundary for displayed data in UI */
  public retrieveMaxSizeOfDisplayedData() {
    let result: number;
    const maxSizeOfDisplayedData: boolean =
          this.filteredData.length < (this.page + 1) * this.step;
    if (maxSizeOfDisplayedData) {
      result = this.filteredData.length;
    } else {
      result = (this.page + 1) * this.step;
    }
    return result;
  }

  /* change step when user clicked it */
  public changeStepValue(step: number): number {
    return step;
  }

  /* change page when user click it */
  public changePageValue(page: number): number {
    return page;
  }
}
