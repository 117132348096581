import { Injectable } from '@angular/core';
import { Resolve, Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import { Observable } from 'rxjs/Observable';
import { SensorType } from '../model/sensor-type.model';
import { SensorTypeService } from '../service/sensor-type.service';

@Injectable()
export class SensorTypeResolver implements Resolve<SensorType[]> {

  constructor(private deviceService: SensorTypeService,
              private logger: NGXLogger,
              private router: Router) {
    this.logger.debug('SensorTypeResolver constructing.');
  }

  resolve(): Observable<SensorType[]> {
    this.logger.debug('SensorTypeResolver retrieve all sensorTypes');
    return this.deviceService.getAllSensorTypes().catch((error) => {
      if (error === 404) {
        this.router.navigate(['404']);
      } else {
        this.router.navigate(['error']);
      }
      return Observable.of(null);
    });

  }

}
