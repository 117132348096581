export const WChartProperties: any = {
  yAxis: [
    { // Observations chart.
      opposite: false,
      labels: {
        align: 'right',
        x: -3,
        format: '{value}',
      },
      title: {
        text: 'Velocity (m/s)',
      },
      height: '80%',
      lineWidth: 2,
      resize: {
        enabled: true,
      },
    },
    { // Checks chart.
      labels: {
        enabled: false,
        align: 'right',
        x: -3,
        formatter() {
          return this.value + 'm';
        },
      },
      title: {
        text: 'Checks',
      },
      top: '85%',
      height: '15%',
      offset: 0,
      lineWidth: 2,
    },
    { // Observations chart right.
      opposite: true,
      showEmpty: false,
      labels: {
        align: 'right',
        x: -3,
        format: '{value} ',
      },
      title: {
        text: 'Degrees',
      },
      height: '80%',
      lineWidth: 2,
      resize: {
        enabled: true,
      },
      offset: 0,
      floating: true,
    },
    { // Observations chart right.
      opposite: true,
      showEmpty: false,
      labels: {
        align: 'right',
        x: -3,
        format: '{value} ',
      },
      title: {
        text: 'Gust (m/s)',
      },
      height: '80%',
      lineWidth: 2,
      resize: {
        enabled: true,
      },
      floating: true,
    },
    { // Observations chart right.
      opposite: true,
      showEmpty: false,
      labels: {
        align: 'right',
        x: -3,
        format: '{value} ',
      },
      title: {
        text: 'Backing (degrees)',
      },
      height: '80%',
      lineWidth: 2,
      resize: {
        enabled: true,
      },
      floating: true,
    },
    { // Observations chart right.
      opposite: true,
      showEmpty: false,
      labels: {
        align: 'right',
        x: -3,
        format: '{value} ',
      },
      title: {
        text: 'Veering (degrees)',
      },
      height: '80%',
      lineWidth: 2,
      resize: {
        enabled: true,
      },
      floating: true,
    },
  ],
};
