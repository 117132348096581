export class MeasurementLocation {

  constructor(
    public name: string = '',
    public type: string = '',
    public description: string = '',
    public x: number = 0,
    public y: number = 0,
    public z: number = 0,
    public endDate: Date = null,
    public distance: string = '',
    public directionFrom: string = '',
    public directionTo: string = '',
    public napToALatConversion: string = '') {
  }
}
