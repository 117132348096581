const sensorAction = {
  title: 'Sensor Details',
  id: 'sensor-detail',
  className: 'esri-icon-radio-checked',
};

const LoaderOptions = {
  url: 'https://js.arcgis.com/4.6/',
};

export {
  sensorAction, LoaderOptions,
};
