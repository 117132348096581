import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
export class RangeValidator {

  public static validate(minRange: number, maxRange: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const error: ValidationErrors = { invalidRange: true };
      if (control.value && (control.value < minRange || control.value > maxRange)) {
        control.setErrors(error);
        return error;
      } else {
         return null;
      }
    };
  }
}
