import {Component, Input, OnInit} from '@angular/core';
import {NGXLogger} from 'ngx-logger';

@Component({
  selector:    'app-error-message',
  templateUrl: './error-message.component.html',
  styleUrls:   ['./error-message.component.scss'],
})
export class ErrorMessageComponent implements OnInit {

  @Input() message: string = 'default error message';

  constructor(private logger: NGXLogger) {
    this.logger.debug('ErrorMessageComponent: constructing.');
  }

  ngOnInit() {
  }

}
