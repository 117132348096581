export const environment = {
	env: 'dev',
	apiUrl:'https://hbr-hm-api-gateway-service-accp.san4tb2gbtb.eu-gb.codeengine.appdomain.cloud/observations/api/v1/',
	loginUrl:'https://hbr-hm-api-gateway-service-accp.san4tb2gbtb.eu-gb.codeengine.appdomain.cloud/observations/api/v1/',
	apiKey:'d8fb4c2a-7d78-42d8-9f53-393b8a24570c',
							
	expectedEndPointIndicator:'7',
	astro_api:'https://hbr-hm-api-gateway-service-accp.san4tb2gbtb.eu-gb.codeengine.appdomain.cloud/astro/api/v1/',
							
	astro_api_clientId:'d8fb4c2a-7d78-42d8-9f53-393b8a24570c',
	osr_api:'https://hbr-hm-api-gateway-service-accp.san4tb2gbtb.eu-gb.codeengine.appdomain.cloud/osr/api/v1/',
	osr_api_clientId:'d8fb4c2a-7d78-42d8-9f53-393b8a24570c',
							
	mapPortalId:'bf617d31e6c94ddeae0d78a76afa7567',
	localUrl:'',
	isLogin: false,
							
	apiAuthUrl: 'https://hbr-hm-auth-helper-service-accp.san4tb2gbtb.eu-gb.codeengine.appdomain.cloud/api/v1/auth/token',
              
	activeDirectoryClientId:'71d03a9d-a3d6-44e4-8e60-306b2b5c9ffe',
	activeDirectoryTenantId:'30453998-4784-4b0e-bdb0-a8ba14eff494',
							
	activeDirectoryRedirectUrl:'https://hydrometeo.naiade-accp.portofrotterdam.com/auth',
	activeDirectoryResource:'http://customappsso/f42f24a7-a3aa-4e61-ad14-3116cc4a2e6b',
              
	title:'Aeolus 2.5 ACCP', 
	rws_api:'https://hbr-hm-api-gateway-service-accp.san4tb2gbtb.eu-gb.codeengine.appdomain.cloud/rws/api/v1/',
	rws_api_clientId:'d8fb4c2a-7d78-42d8-9f53-393b8a24570c',
                                                        
	watsonUrl:'https://aeo4jv.internetofthings.ibmcloud.com/dashboard/#/devices/browse-v2',
	naiadeMapUrl:'https://portmaps.maps.arcgis.com/apps/webappviewer/index.html?id=5e07677930d64e2a861212150ebaf615',
	alertsUrl:'https://ibmbmmgmt.eu-gb.mybluemix.net/index?subscriptionId=2a3e9ea6-c5a4-4902-8602-b1363bf43775&dashboard=ans.dashboard.alertviewer'
};
