import 'rxjs/add/observable/of';
import { environment } from '../environments/environment';
import { ExternalUrlComponent } from './shared/external-url/external-url.component';

import { NgModule } from '@angular/core';

import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { AppRoutingModule } from './app.routing.module';

import { LoggerModule, NGXLogger } from 'ngx-logger';

import { AppComponent } from './app.component';

import { Configs } from './config';

import { ApiDataService } from './api/api.data.service';
import { SessionService } from './shared/service/session.service';

import { ErrorComponent } from './error/error.component';
import { PageNotFoundComponent } from './error/page-not-found.component';

import { MainModule } from './components/main.module';

import { HIGHCHARTS_MODULES } from 'angular-highcharts';
import * as exporting from 'highcharts/modules/exporting.src.js';
import * as highstock from 'highcharts/modules/stock.src.js';

import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';

import { AuthenticationService } from './authentication/service/authentication.service';
import { AuthGuard } from './components/authorization/service/auth-guard';
import { ResourceRightsService } from './components/authorization/service/resource-rights.service';
import { NotAuthorizedComponent } from './error/not-authorized-component';
import { LoginModule } from './login/login.module';
import { LoginService } from './login/service/login.service';
import { ExternalUrlResolver } from './shared/external-url/external-url.resolver';
import { StatusModalService } from './shared/modal/status-modal.service';
import { HttpInterceptorService } from './shared/service/http-interceptor.service';
import { SharedModule } from './shared/shared.module';

/**
 *
 * Make the extra modules for the highcharts api available.
 */
export function highchartsModules() {
  return [exporting, highstock];
}

@NgModule({
  imports: [

    // angular
    HttpClientModule,
    BrowserAnimationsModule,
    BrowserModule,
    CommonModule,

    // routing
    RouterModule,
    AppRoutingModule,

    // components
    MainModule,

    // logging
    LoggerModule.forRoot(Configs.LOGGER_CONFIG),

    LoginModule,
    SharedModule,
  ],
  exports: [],
  declarations: [
    AppComponent,

    ExternalUrlComponent,
    ErrorComponent,
    PageNotFoundComponent,
    NotAuthorizedComponent,
  ],
  providers: [
    StatusModalService,
    NGXLogger,
    ExternalUrlResolver,
    ResourceRightsService,
    {
      provide: AuthGuard,
      useFactory: AuthGuardLoader,
      deps: [Router, ActivatedRoute, SessionService, NGXLogger],
    },
    {
      provide: SessionService,
      useFactory: sessionServiceLoader,
      deps: [NGXLogger, ResourceRightsService],
    },
    {
      provide: HIGHCHARTS_MODULES,
      useFactory: highchartsModules,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true,
    },
    {
      provide: ApiDataService,
      useFactory: restServiceLoader,
      deps: [NGXLogger, HttpClient, Router, StatusModalService],
    },
    {
      provide: LoginService,
      useFactory: loginServiceLoader,
      deps: [NGXLogger, AuthenticationService, Router, SessionService],
    },
  ],
  bootstrap: [AppComponent],
})

export class AppModule {
  constructor() {
  }
}

export function AuthGuardLoader(router: Router, route: ActivatedRoute,
                                sessionService: SessionService, logger: NGXLogger) {
    return new AuthGuard(router, route, sessionService, logger);
}

/**
 * Based on the current environment, the proper api service will be loaded.
 */
export function restServiceLoader(logger: NGXLogger,
                                  http: HttpClient,
                                  router: Router,
                                  statusModalService: StatusModalService,
                                  loginService: LoginService): any {
    return new ApiDataService(logger, http, router, statusModalService, loginService);
}

/**
 * Based on the current environment, the proper login service will be loaded.
 */
export function loginServiceLoader(logger: NGXLogger, api: AuthenticationService, router: Router, sessionService: SessionService): any {
    return new LoginService(logger, api);
}

/**
 * Based on the current environment, get the Current Date
 */
export function getCurrentDate(): Date {

  if (environment.env === 'e2e') {
    return new Date('2018-05-02 09:15:00');
  }

  return new Date();
}

/**
 * Based on the current environment, the proper session service will be loaded.
 */
export function sessionServiceLoader(logger: NGXLogger, resourceRightsService: ResourceRightsService): any {
    return new SessionService(logger, resourceRightsService);
}
