import { AbstractControl, FormArray, FormGroup, ValidatorFn } from '@angular/forms';
import { ObjectUtils } from '../../../../../shared/utils/object-utils';
import { TimeUtils } from '../../../../../shared/utils/time-conversion-properties';

export class DateValidator {

  public static readonly INVALID_END_DATE = { invalidEndDate: true };

  /*
  * validate return the ValidatorFn for the 'startDate' and
  * 'endDate' FormControls.
  *
  * return:
  *   - validateEndDate: a ValidatorFn
  * */
  public static validate(): ValidatorFn {
    return DateValidator.validateEndDate;
  }

  /*
  * validateEndDate check if the 'endDate' is greater than 'startDate'.
  * The validation is done when modifying both 'endDate' and
  * 'startDate' FormControls are updated.
  *
  * params:
  *   - formControl: an AbstractControl that needs to be validate
  * return:
  *   - {'invalidEndDate': true } iff
  *   the FormControl is the endDate and the check failed
  *
  *   - null if
  *   1) the FormControl is the endDate and the check failed
  *   2) the FormControl is the startDate.
  *   In case (2) the check is done anyway and the FormControl of endDateSensorOperational
  *   is updated accordingly, but no error will be returned to the startDateSensorOperational.
  * */
  private static validateEndDate(formControl: AbstractControl): { [key: string]: any } {

    let toReturn = null;
    let parent: FormGroup | FormArray;
    let controls;
    let startDateControl: AbstractControl;
    let endDateControl: AbstractControl;

    if (formControl.parent !== undefined && formControl.value !== null) {
      parent                      = formControl.parent;
      controls                    = parent.controls;
      startDateControl            = controls['startDate'];
      endDateControl              = controls['endDate'];
      const startDateEpoc: number = startDateControl.value ? startDateControl.value.epoc : null;
      const endDateEpoc: number   = endDateControl.value ? endDateControl.value.epoc : null;

      if (ObjectUtils.isInstantiated(startDateEpoc) && ObjectUtils.isInstantiated(endDateEpoc)) {
        const invalidDates = TimeUtils.checkInvalidDates(startDateEpoc, endDateEpoc);
        toReturn           = invalidDates ? DateValidator.INVALID_END_DATE : null;
        endDateControl.setErrors(toReturn); // setError allow to set an error in the endDate, when updating startDate
      }
    }
    return formControl === endDateControl ? toReturn : null;
  }

}
