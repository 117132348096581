import {Injectable} from '@angular/core';
import {NGXLogger} from 'ngx-logger';
import {Check} from '../../../../check/model/check.model';
import {Datastream} from '../../../../datastream/model/datastream.model';

@Injectable()
export class ChecksService {

  private sensorId: string;
  private editedChecks: Check[];
  private dataStreams: Datastream[];
  private dataStreamsBackup: Datastream[];

  constructor(private logger: NGXLogger) {
    this.logger.debug('constructing ChecksService.');
    this.sensorId = '';
    this.editedChecks = [];
    this.dataStreams = [];
    this.dataStreamsBackup = [];
  }

  public getSensorId(): string {
    return this.sensorId;
  }

  public setSensorId(id): void {
    this.sensorId = id;
  }

  public getChangedChecks(): Check[] {
    return this.editedChecks;
  }

  public setChangedChecks(changedChecks: Check[]): void {
    this.editedChecks = changedChecks;
  }

  public getDatastreams(): Datastream[] {
    return this.dataStreams;
  }

  public setDatastreams(dataStreams: Datastream[]): void {
    this.dataStreams = dataStreams;
  }

  public getDatastreamsBackup(): Datastream[] {
    return this.dataStreamsBackup;
  }

  public setDatastreamsBackup(dataStreamsBackup: Datastream[]): void {
    this.dataStreamsBackup = dataStreamsBackup;
  }
}
