export interface SensorLogInterface {
  sensorId: string;
  user: string;
  actionTaken: string;
  reason: string;
  actionTime: Date;
}

export class SensorLog implements SensorLogInterface {
  sensorId: string;
  user: string;
  actionTaken: string;
  reason: string;
  actionTime: Date;
  timeString: string;

  constructor(sensorId: string, user: string, actionTaken: string, reason: string, actionTime: Date) {
    this.sensorId = sensorId;
    this.user = user;
    this.actionTaken = actionTaken;
    this.reason = reason;
    this.actionTime = actionTime;
    const dateString = actionTime.toString();
    const dateSplit: string[] = (dateString.split('T'))[1].split(':');
    if (dateSplit && dateSplit[1]) {
      this.timeString = dateSplit[0] + ':' + dateSplit[1];
    } else {
      this.timeString = null;
    }
  }
}
