export const VisChartProperties: any = {
  yAxis: [
    { // Observations chart.
      opposite: false,
      labels: {
        align: 'right',
        x: -3,
        format: '{value} m',
      },
      title: {
        text: 'Visibility (m)',
      },
      height: '80%',
      lineWidth: 2,
      resize: {
        enabled: true,
      },
    },
    { // Checks chart.
      labels: {
        enabled: false,
        align: 'right',
        x: -3,
        formatter() {
          return this.value + 'm';
        },
      },
      title: {
        text: 'Checks',
      },
      top: '85%',
      height: '15%',
      offset: 0,
      lineWidth: 2,
    },
  ],
};
