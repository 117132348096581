import * as momentTimeZone from 'moment-timezone';
import { TimeUtils } from '../../../shared/utils/time-conversion-properties';

export class TableTimeConversion {
  /* date time keys that are used on upload page of astro, rws, osr
   * these properties are used to map time from UK server time to local time*/
  public dateTimePropertyKeys = [
    'dateTime',
    'time',
    'lastUpdate',
    'lastImport',
  ];
  public data: any[];

  constructor(data: any[]) {
    this.data = data;
  }

  /* check dataset whether contains data-time keys */
  public convertDateTimeFormat(): void {
    if (this.data.length !== 0) {
      for (let i = 0; i < this.data.length; i++) {
        this.checkDateTimeKeyPropertiesFromDataset(this.data[i]);
      }
    }
  }

  /* check whether dataset contains date time property
   * to be assigned to */
  private checkDateTimeKeyPropertiesFromDataset(data: any): void {
    const keys: any[] = [];
    for (let i = 0; i < this.dateTimePropertyKeys.length; i++) {
      if (data.hasOwnProperty(this.dateTimePropertyKeys[i])) {
        keys.push(this.dateTimePropertyKeys[i]);
      }
    }
    this.convertUTCToLocal(data, keys);
  }

  /* setting data with date-time related key from UTC to local time */
  private convertUTCToLocal(data: any, keys: string[]): void {

    let dateTimeServer_UK;
    let dateTimeLocal_AMS;
    let timeLocal_AMS;

    for (let i = 0; i < keys.length; i++) {
      if (keys[i] === 'dateTime') {
        /* setting date time based on UK server, verified for Astro & OSR */
        dateTimeServer_UK = this.setDateTimeBasedOnTimezone(data[keys[i]], TimeUtils.utcTimeZone).format();

        /* setting date time format in Amsterdam timezone */
        dateTimeLocal_AMS = this.setDateTimeBasedOnTimezone(dateTimeServer_UK, TimeUtils.dynamicLocalTimeZoneFormat);
        data[keys[i]] = dateTimeLocal_AMS.format(TimeUtils.dateTimeFormatString);
      } else if (keys[i] === 'time') {

        /* setting time in UK/London Format */
        timeLocal_AMS = this.setDateTimeBasedOnTimezone(data['dateTime'], TimeUtils.dynamicLocalTimeZoneFormat);
        data[keys[i]] = timeLocal_AMS.format(TimeUtils.timeFormatString);
      }
    }
  }

  /* setting dataset from database in UTC/GMT format */
  private setDateTimeBasedOnTimezone(stringDate: string, formatTimeZone: string): any {
    return momentTimeZone.tz(`${stringDate}`, formatTimeZone);
  }
}
