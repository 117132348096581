import {NgModule} from '@angular/core';
import {ObservationChartModule} from './observations/observation-chart.module';

@NgModule({
  imports:      [
    ObservationChartModule,
  ],
  declarations: [],
  providers:    [],
  exports:      [
    ObservationChartModule,
  ],
})

export class MeteoChartsModule {

}
