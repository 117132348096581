export interface SensorTypeEnumInterface {
  HEIGHT_OF_TIDE: string;
  TIDAL_STREAM: string;
  SALINITY: string;
  VISIBILITY: string;
  WIND_MIERJ: string;
  WINDX_SIAM: string;
}

export const SensorTypeEnum = {
  HEIGHT_OF_TIDE: 'Height of Tide',
  TIDAL_STREAM: 'Tidal Stream',
  SALINITY: 'Salinity',
  VISIBILITY: 'Visibility',
  WIND_MIERJ: 'Wind Mierij',
  WINDX_SIAM: 'Wind X-Siam',
};
