/// <reference types="node" />
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { AppMockingModule } from './app/app.mocking.module';

import { environment } from './environments/environment';
import { logger } from 'codelyzer/util/logger';

if (environment['production']) {
  enableProdMode();
}

// change AppModule to AppMockingModule for e2e to include mocking data.
platformBrowserDynamic().bootstrapModule(AppModule)
.catch((err) =>
  logger.error(err)
);
