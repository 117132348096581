import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as Fuse from 'fuse.js';
import { BasePagination } from '../pagination/model/abstract/pagination.abstract';
import { PaginationProperties } from '../pagination/model/pagination.properties';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
})
export class SearchBarComponent extends BasePagination implements OnInit {
  /* input(s) for property binding
   * @input: placeholderTitle, parameter to change placeholder
   * @input: data, data from server to be filtered
   * @input: searchTerm, search term
   * @input: searchOption, fuseJS specific parameters for filtering data
   * @input: step,
   * @input: page */
  @Input() placeholderTitle: string;
  @Input() data: any[];
  @Input() searchTerm: string;
  @Input() searchProperties: any; // this needs to be defined at component level
  @Input() page: number;
  @Input() step: number;

  /* output(s) to change the list of displayed data in the parent component */
  @Output() updateDisplayData: EventEmitter<any> = new EventEmitter<any>();

  constructor() {
    super();
  }

  ngOnInit() {
  }

  //  updating display data based on search keywords in the UI
  public updateDisplayDataEventEmitter(): void {
    this.updateDisplayData.emit(this.setPaginationProperties());
  }

  public setPaginationProperties(): PaginationProperties {
    const paginationProperties = new PaginationProperties();

    paginationProperties.setDisplayedData(this.updateDisplayedDataArray(this.step, 0));
    paginationProperties.setMaxPage(this.retrieveMaxPage());
    paginationProperties.setPages(this.setPages());
    paginationProperties.setIsNextButtonDisabled(this.setDisableNextButton());
    paginationProperties.setIsPreviousButtonDisabled(this.setDisablePreviousButton());
    paginationProperties.setFilteredData(this.filterDataBySearchTerm());

    return paginationProperties;
  }

  /* updating data array after filter to be displayed in the UI*/
  public updateDisplayedDataArray(step: number, page: number): any {
    const currentDisplayedData = [];
    const searchedData = this.filterDataBySearchTerm();
    for (let i = page * step; i < step + page * step; i++) {
      if (searchedData[i]) {
        currentDisplayedData.push(searchedData[i]);
      } else {
        break;
      }
    }
    return currentDisplayedData;
  }

  // retrieving value for max page
  public retrieveMaxPage(): number {
    return Math.ceil(this.filterDataBySearchTerm().length / this.step);
  }

  // seting value for pages array
  public setPages(): number[] {
    const pages = [];
    for (let i = 0; i < this.retrieveMaxPage(); i++) {
      pages.push(i);
    }
    return pages;
  }

  // setting previous button
  public setDisablePreviousButton(): boolean {
    return this.page <= 0;
  }

  // setting next button
  public setDisableNextButton(): boolean {
    return this.page >= this.retrieveMaxPage() - 1;
  }

  /* creating filter by search term */
  public filterDataBySearchTerm(): any[] {
    let results: any[];
    if (this.searchTerm === undefined || this.searchTerm === null ||
      this.searchTerm === '') {
      results = this.data;
    } else {
      const fuse = new Fuse(this.data, this.searchProperties);
      results = fuse.search(this.searchTerm);
    }
    return results;
  }
}
