import {
  ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit,
  Output
} from '@angular/core';
import {NGXLogger} from 'ngx-logger';
import {TableProperties} from './model/table-properties.model';

@Component({
  selector:    'app-table',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent implements OnInit {

  @Input() tableRowObjects: object[]          = [];
  @Input() tableAttributes: TableProperties[] = [];
  @Output() onTableRowClick                   = new EventEmitter<any>();

  constructor(private logger: NGXLogger) {
    this.logger.debug('TableComponent: constructing.');
  }

  ngOnInit(): void {
  }


  private onRowClick(object): void {
    this.onTableRowClick.emit(object);
  }

  private returnDate(dateString: string): Date {
    return new Date(dateString);
  }
}
