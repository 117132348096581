import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs/Observable';
import { SensorService } from '../service/sensor.service';

import 'rxjs/add/operator/finally';
import { SensorSummary } from '../overview/model/sensor-overview.model';

@Injectable()
export class SensorSummaryResolver implements Resolve<SensorSummary[]> {

  constructor(private sensorService: SensorService,
              private logger: NGXLogger) {
  }

  resolve(route: ActivatedRouteSnapshot,
          state: RouterStateSnapshot): Observable<SensorSummary[]> {
    this.logger.debug('SensorSummaryResolver: Waiting for sensor summaries to be available.');

    return this.sensorService.getAllSensorSummaries();
  }
}
