const activityLogSearchOption = {
  shouldSort: true,
  threshold: 0.4,
  location: 0,
  distance: 100,
  maxPatternLength: 32,
  minMatchCharLength: 2,
  keys: [
    'actionTaken',
    'reason',
    'timeString',
    'user',
    'actionTime',
  ],
};

export { activityLogSearchOption };
