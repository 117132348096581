import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ErrorMessageComponent} from './error-message.component';

@NgModule({
  imports:      [
    CommonModule,
  ],
  declarations: [ErrorMessageComponent],
  providers:    [],
  exports:      [
    ErrorMessageComponent,
  ],
})
export class ErrorMessageModule {
}
