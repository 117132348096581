import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {NGXLogger} from 'ngx-logger';
import {Observable} from 'rxjs/Observable';
import {Sensor} from '../model/sensor.model';
import {SensorService} from '../service/sensor.service';

import 'rxjs/add/operator/finally';

@Injectable()
export class SensorsResolver implements Resolve<Sensor[]> {

  constructor(private sensorDataService: SensorService,
              private logger: NGXLogger) {
    this.logger.debug('SensorsResolver: constructing.');
  }

  resolve(route: ActivatedRouteSnapshot,
          state: RouterStateSnapshot): Observable<Sensor[]> {
    this.logger.debug('SensorsResolver: Waiting for sensor data to be available.');

    return this.sensorDataService.getAllSensors();
  }
}
