import {Component, OnInit} from '@angular/core';

@Component({
  selector:    'app-sensor',
  templateUrl: './sensor.component.html',
  styleUrls:   ['./sensor.component.scss'],
})
export class SensorComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }
}
