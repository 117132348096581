export class UpdateSensorDto {

  public startDate: string;
  public endDate: string;
  public instrumentType: string;
  public locationName: string;
  public xcoordinate: number;
  public ycoordinate: number;
  public zcoordinate: number;

  constructor(startDate: string,
              endDate: string,
              instrumentType: string,
              locationName: string,
              xcoordinate: number,
              ycoordinate: number,
              zcoordinate: number) {
    this.startDate = startDate;
    this.endDate = endDate;
    this.instrumentType = instrumentType;
    this.locationName = locationName;
    this.xcoordinate = xcoordinate;
    this.ycoordinate = ycoordinate;
    this.zcoordinate = zcoordinate;
  }
}
