import { AbstractControl, FormGroup } from '@angular/forms';
import { IMyDateModel, IMyDate } from 'mydatepicker';
import { Sensor } from '../../../model/sensor.model';
import { myParseFloat } from '../../../create/properties/utility/float-parser';
import { MyDatePickerMapper } from './MyDatePickerMapper';

export class UpdateSensorFormController {

  private myDatePickerMapper: MyDatePickerMapper;

  constructor(private updateSensorForm: FormGroup) {
    this.myDatePickerMapper = new MyDatePickerMapper();
  }

  public get instrumentTypeControl(): AbstractControl {
    return this.updateSensorForm.get('instrumentType');
  }

  public get instrumentType(): string {
    return this.instrumentTypeControl.value;
  }

  public get locationNameControl(): AbstractControl {
    return this.updateSensorForm.get('locationName');
  }

  public get locationName(): string {
    return this.locationNameControl.value;
  }

  public get startDateControl(): AbstractControl {
    return this.updateSensorForm.get('startDate');
  }

  public get startDate(): IMyDateModel {
    return this.startDateControl.value;
  }

  public get startDateISOString(): string {
    const date: Date = this.startDate.jsdate;

    return date ? date.toISOString() : '';
  }

  public get endDateControl(): AbstractControl {
    return this.updateSensorForm.get('endDate');
  }

  public get endDate(): IMyDateModel {
    return this.endDateControl.value;
  }

  public get endDateISOString(): string {
    const date = this.endDate ? this.endDate.jsdate : null;

    return date ? date.toISOString() : '';
  }

  public get xcoordinateControl(): AbstractControl {
    return this.updateSensorForm.get('xcoordinate');
  }

  public get xcoordinate(): number {
    return myParseFloat(this.xcoordinateControl.value);
  }

  public get ycoordinateControl(): AbstractControl {
    return this.updateSensorForm.get('ycoordinate');
  }

  public get ycoordinate(): number {
    return myParseFloat(this.ycoordinateControl.value);
  }

  public get zcoordinateControl(): AbstractControl {
    return this.updateSensorForm.get('zcoordinate');
  }

  public get zcoordinate(): number {
    return myParseFloat(this.zcoordinateControl.value);
  }

  public resetUpdateSensorForm(sensor: Sensor) {
    this.instrumentTypeControl.setValue(sensor.instrumentType);
    this.locationNameControl.setValue(sensor.locationName);
    this.startDateControl.setValue(this.convertDate(sensor.startDate));
    this.endDateControl.setValue(this.convertDate(sensor.endDate));
    this.xcoordinateControl.setValue(sensor.x);
    this.ycoordinateControl.setValue(sensor.y);
    this.zcoordinateControl.setValue(sensor.z);
  }

  public convertDate(date: Date): IMyDateModel {
    return date ? this.myDatePickerMapper.toIMyDateModel(date) : null;
  }
}
