import { Process } from '../../process/model/process.model';
import { FeatureType } from './feature-type.model';
import { FeatureMetadata } from './feature-metadata.model';

export interface FeatureDTO {
  id: string;
  name: string;
  description: string;
  active: boolean;
  endDate: string;
  wkid: number;
  x: number;
  y: number;
  z: number;
  featureType: FeatureType;
  featureMetadata: FeatureMetadata[];
  processes: Process[];
}

export class Feature {

  public id: string;
  public name: string;
  public description: string;
  public active: boolean;
  public endDate: Date;
  public wkid: number;
  public x: number;
  public y: number;
  public z: number;
  public featureType: FeatureType;
  public featureMetadata: FeatureMetadata[];
  public processes: Process[];

  constructor(featureDto: FeatureDTO) {
    this.id = featureDto.id;
    this.name = featureDto.name;
    this.description = featureDto.description;
    this.endDate = featureDto.endDate ? new Date(featureDto.endDate) : null;
    this.active = featureDto.active;
    this.wkid = featureDto.wkid;
    this.x = featureDto.x;
    this.y = featureDto.y;
    this.z = featureDto.z;
    this.featureMetadata = featureDto.featureMetadata;
    this.featureType = featureDto.featureType;
    this.processes = featureDto.processes;
  }
}
