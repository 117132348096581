import { AbstractControl, ValidatorFn } from '@angular/forms';
import { Sensor } from '../../../model/sensor.model';
import { SensorSummary } from '../../../overview/model/sensor-overview.model';

export class SensorNameValidator {

  public static validate(sensors: SensorSummary[]): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      const sensorName: string = control.value; // input value of the user
      const trimmedSensorName = sensorName ? sensorName.trim() : null;
      const forbidden = sensors.find((sensor) => {
        return sensor.name === trimmedSensorName;
      });
      return forbidden ? {forbiddenName: {value: trimmedSensorName}} : null;
    };
  }
}
