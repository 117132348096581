import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MyDatePickerModule } from 'mydatepicker';
import { ClickOutsideModule } from 'ng-click-outside';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { ModalModule } from '../../../shared/modal/modal.module';
import { PaginationModule } from '../../../shared/ui/pagination/pagination.module';
import { SearchBarModule } from '../../../shared/ui/search-bar/search-bar.module';
import { TableModule } from '../../../shared/ui/table/table.module';
import { DeviceService } from '../../../shared/iot-platform/service/device.service';
import { ObservationModule } from '../../observation/observation.module';
import { ObservationService } from '../../observation/service/observation.service';
import { ActivityResolver } from '../resolver/activity.resolver';
import { SensorResolver } from '../resolver/sensor.resolver';
import { SensorLogResolver } from '../resolver/sensorLog.resolver';
import { ActivityService } from '../service/activity.service';
import { FeatureService } from '../service/feature.service';
import { SensorService } from '../service/sensor.service';
import { SensorDetailChecksComponent } from './checks/sensor-detail-checks.component';
import { ChecksService } from './checks/service/checks.service';
import { MeteoChartsModule } from './data/charts/meteo-charts.module';
import { SensorDetailDataComponent } from './data/sensor-detail-data.component';
import { SensorDetailDataUpdatingService } from './data/service/sensor-detail-data-updating.service';
import { SensorDetailInfoComponent } from './info/sensor-detail-info.component';
import { SensorDetailComponent } from './sensor-detail.component';
import { SensorDetailRoutingModule } from './sensor-detail.routing.module';
import { SensorStreamPropertiesResolver } from './stream/resolver/sensor-stream-properties-resolver';
import { SensorDetailStreamUpdateLogsNotificationService } from './stream/sensor-detail-stream-update-logs-notification-service';
import { SensorDetailStreamComponent } from './stream/sensor-detail-stream.component';
import { IotDevicesResolver } from '../../../shared/iot-platform/resolver/iot-devices-resolver.service';
import { DeviceResolver } from '../resolver/device.resolver';
import { UpdateLocationComponent } from './info/update-location/update-location.component';
import { FeatureResolver } from '../resolver/feature.resolver';

@NgModule({
  imports: [
    MyDatePickerModule,
    RouterModule,
    SensorDetailRoutingModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    Ng2SearchPipeModule,
    ModalModule,
    ObservationModule,
    MeteoChartsModule,
    TableModule,
    ClickOutsideModule,
    PaginationModule,
    SearchBarModule,
  ],
  declarations: [
    SensorDetailComponent,
    SensorDetailInfoComponent,
    SensorDetailDataComponent,
    SensorDetailStreamComponent,
    UpdateLocationComponent,
    SensorDetailChecksComponent,
  ],
  providers: [
    DeviceService,
    SensorService,
    FeatureService,
    ObservationService,
    SensorResolver,
    IotDevicesResolver,
    FeatureResolver,
    ActivityResolver,
    ActivityService,
    SensorDetailDataUpdatingService,
    ChecksService,
    SensorLogResolver,
    DeviceResolver,
    SensorStreamPropertiesResolver,
    SensorDetailStreamUpdateLogsNotificationService,
  ],
})
export class SensorDetailModule {
}
