import {NgxLoggerLevel} from 'ngx-logger';

export class Configs {

  // ngrx-logger config
  static readonly LOGGER_CONFIG = {
    serverLoggingUrl: '/logs',
    level:            NgxLoggerLevel.DEBUG,
    serverLogLevel:   NgxLoggerLevel.ERROR,
  };

}
