import { SensorState } from '../../sensor-states';
import { sensorStates } from './sensor-detail';

interface ISensorStatus {
  isSensorActive: boolean;
  sensorStateIndex: number;
  sensorStates: any[];
  isPublishing: boolean;

  setSensorStateIndex(sensorStatus: boolean, publishStatus: boolean);
}

export class SensorStatus implements ISensorStatus {
  private _isSensorActive: boolean;
  private _isPublishing: boolean;
  private _sensorStateIndex: number;
  private readonly _sensorStates: any[];

  constructor() {
    this._isSensorActive = false;
    this._sensorStateIndex = 0;
    this._sensorStates = sensorStates;
    this._isPublishing = false;
  }

  get isSensorActive(): boolean {
    return this._isSensorActive;
  }

  set isSensorActive(value: boolean) {
    this._isSensorActive = value;
  }

  get sensorStateIndex(): number {
    return this._sensorStateIndex;
  }

  get sensorStates(): any[] {
    return this._sensorStates;
  }

  get isPublishing(): boolean {
    return this._isPublishing;
  }

  set isPublishing(value: boolean) {
    this._isPublishing = value;
  }

  setSensorStateIndex(status: boolean): void {
    if (status) {
      this._sensorStateIndex = 1;
    } else {
      this._sensorStateIndex = 0;
    }
  }

  changeSensorStateMessage(sensorStatus: boolean, defaultMessage: any): string {
    const message = defaultMessage;
    if (!sensorStatus) {
      message.mode = SensorState.ON;

      return message;
    } else {
      message.mode = SensorState.OFF;

      return message.mode;
    }
  }

  getPublishingMessage(sensorStatus: boolean, publishStatus: boolean) {
    if (!sensorStatus) {
      return (SensorState.OFF).toLocaleLowerCase();
    } else if (sensorStatus && publishStatus) {
      return (SensorState.PUBLISHING).toLowerCase();
    } else {
      return (SensorState.NOT_PUBLISHING).toLowerCase();
    }
  }

  setUIPublishingState(status: boolean, defaultMessage: any): any {
    const message = defaultMessage;
    if (status) {
      message.mode = SensorState.PUBLISHING;
      return message;
    } else {
      message.mode = SensorState.NOT_PUBLISHING;
      return message;
    }
  }
}
