import { SessionService } from '../../../shared/service/session.service';
import { ResourceRight } from './resource-right.model';
import { Role } from './role.model';

export class PermissionBasedEntity {

  public resource: string;
  public rights: Map<string, boolean>;

  public visible: boolean = false;

  constructor(public sessionService: SessionService,
              resource: string, rights: string[]) {

    this.rights = new Map<string, boolean>();
    this.resource = resource;

    for (const right of rights) {
      this.rights.set(right, false);
    }

    this.determineRights(sessionService.getCurrentUser().roles);
    this.determineVisibility();

  }

  /**
   * if even one right is permited for the user, the component has to be visible.
   */
  public determineVisibility() {
    this.rights.forEach((authorized: boolean) => {
      if (authorized && !this.visible) {
        this.visible = true;
      }
    });
  }

  /**
   * determine if the current user has correct rights for this component.
   */
  public determineRights(userRoles: Role[]) {

    const rights = new Map<string, boolean>();
    userRoles.forEach((role) => {
      // Walk trough the rights needed for this component.
      this.rights.forEach((authorized: boolean, componentRight: string) => {
        // Walk trough the rights that are provided for the user.
        role.resourceRights.forEach((resourceRight: ResourceRight) => {
          // If the current user right resource is the same as the current components
          if (this.resource === resourceRight.resource.url) {

            // Walk trough the rights on the resource for the current user.
            for (const userRight of resourceRight.rights) {

              if (userRight.type === componentRight) {
                rights.set(componentRight, true);
              } else if (rights[componentRight] === null) {
                rights.set(componentRight, false);
              }
            }
          }
        });
      });
    });

    rights.forEach((authorized: boolean, componentRight: string) => {
      this.rights.set(componentRight, authorized);
    });
  }
}
