import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import 'rxjs/add/operator/mergeMap';
import { environment } from '../../../../environments/environment';
import { SessionService } from '../../../shared/service/session.service';
import { TabItem } from '../../../shared/ui/tab-item/model/tab-item.model';
import { AuthorizationComponent } from '../../authorization/authorization.component';
import { Feature } from '../model/feature.model';
import { SensorLog } from '../model/sensor-log.model';
import { defautModeMsg } from '../model/sensor-properties/details/sensor-detail';
import { SensorStatus } from '../model/sensor-properties/details/sensor-status';
import { SensorState } from '../model/sensor-states';
import { Sensor } from '../model/sensor.model';
import { FeatureService } from '../service/feature.service';
import { SensorService } from '../service/sensor.service';
import { SensorDetailStreamUpdateLogsNotificationService } from './stream/sensor-detail-stream-update-logs-notification-service';
import { Subject } from 'rxjs/Subject';
import { UpdateSensorStatusDtoFactory } from '../factory/update-sensor-status-dto.factory';
import { UpdateSensorStatusDto } from '../model/update-sensor-status-dto.model';

@Component({
  selector:    'app-sensor-detail',
  templateUrl: './sensor-detail.component.html',
  styleUrls:   ['./sensor-detail.component.scss'],
})
export class SensorDetailComponent extends AuthorizationComponent implements OnInit, OnDestroy {

  @Output() logsUpdate = new EventEmitter<SensorLog[]>();

  public sensor: Sensor;

  public formError: boolean = false;
  public selectModeMsg: any = defautModeMsg;
  public sensorStatus = new SensorStatus();
  public sensorStates: any[] = this.sensorStatus.sensorStates;

  private observableGuard = new Subject<void>();

  public featureList: any = [];
  public showFeatureList: boolean = false;
  public selectedFeature: any = [];
  private updateSensorStatusDtoFactory: UpdateSensorStatusDtoFactory = new UpdateSensorStatusDtoFactory();

  constructor(private route: ActivatedRoute,
              private router: Router,
              private logger: NGXLogger,
              private sensorDataService: SensorService,
              sessionService: SessionService,
              public streamNotificationService: SensorDetailStreamUpdateLogsNotificationService
  ) {
    super(sessionService, '/sensors', ['readOne', 'update']);
    this.sensor = route.snapshot.data['sensor'];
    this.sessionService.sharedSensor
      .takeUntil(this.observableGuard)
      .subscribe((sensor: Sensor) => {
        this.sensor = sensor;
      });
    this.selectModeMsg.user = sessionService.getCurrentUser().unique_name;

    /* function getFeatures() results in altering behaviour of protractor test
     due to actual API call to server, therefore the getFeature() function
     is called only when the environment is not e2e */
    if (environment.env !== 'e2e') {
      this.logger.debug('working on actual service');
      this.sessionService.sharedFeatures
        .takeUntil(this.observableGuard)
        .subscribe((features: Feature[]) => {
          this.getFeatures(features);
        });
    }
  }

  public optionTabs = [
    new TabItem(this.sessionService, 'general information', 'info', '/sensors', ['readOne']),
    new TabItem(this.sessionService, 'data stream', 'stream', '/sensors', ['readOne']),
    new TabItem(this.sessionService, 'checks', 'checks', '/sensors/checks', ['readOne']),
    new TabItem(this.sessionService, 'incoming data', 'data', '/sensors/observations', ['readOne']),
  ];

  public updateSingleCurrentFeature(feature: Feature) {
    for (const idx in this.featureList) {
      if (this.featureList.hasOwnProperty(idx) && this.featureList[idx].id === feature.id) {
        this.featureList[idx] = feature;
        break;
      }
    }
  }

  public ngOnInit(): void {
    this.refreshSensorState();
  }

  public ngOnDestroy(): void {
    this.observableGuard.next();
    this.observableGuard.complete();
  }

  private refreshSensorState(): void {
    this.sensorStatus.isSensorActive = this.sensor.active;
    this.sensorStatus.setSensorStateIndex(this.sensorStatus.isSensorActive);
    this.sensorStatus.isPublishing = this.sensor.publishing;
  }

  public getFeatures(features: Feature[]): void {
    this.logger.info('init features');
    const keyList = {};
    const featureList = [];
    for (const feature of features) {
      if (keyList[feature.id] === undefined) {
        keyList[feature.id] = feature;
        featureList.push(feature);
      }
    }

    this.updateFeatureList(featureList);
  }

  private updateFeatureList(items) {
    this.featureList = items;
    this.setSelectedFeature(items[0]);
  }

  public setSelectedFeature(feature) {
    this.selectedFeature = feature;
    this.streamNotificationService.setFeature(feature);
  }

  public saveChanges(func: any): void {
    const message           = this.selectModeMsg;
    this.sensor.startDate   = new Date(this.sensor.startDate);
    this.sensor.endDate     = new Date(this.sensor.endDate);

    if (message.reason === '' || message.reason === undefined || message.reason === null) {
      this.formError = true;

      return;
    }

    if (message.mode === SensorState.ON || message.mode === SensorState.OFF) {
      this.sensorStatus.isSensorActive = !this.sensorStatus.isSensorActive;
      this.sensorStatus.setSensorStateIndex(this.sensorStatus.isSensorActive);

      this.logger.debug(`Changing the sensor its 'active' status to '${this.sensorStatus.isSensorActive}'`);
    } else if (this.sensorStatus.isSensorActive && (message.mode === SensorState.PUBLISHING || message.mode === SensorState.NOT_PUBLISHING)) {
      this.sensorStatus.isPublishing = !this.sensorStatus.isPublishing;

      this.logger.debug(`Changing publishing status to ${this.sensorStatus.isPublishing}`);
    } else if (message.mode === SensorState.PUBLISHING || message.mode === SensorState.NOT_PUBLISHING) {
      this.sensorStatus.isSensorActive = !this.sensorStatus.isSensorActive;
      this.sensorStatus.setSensorStateIndex(this.sensorStatus.isSensorActive);
      this.sensorStatus.isPublishing = message.mode === SensorState.PUBLISHING;

      this.logger.debug(`Changing the sensor its 'active' status to '${this.sensorStatus.isSensorActive}'`);
      this.logger.debug(`Changing publishing status to '${this.sensorStatus.isPublishing}'`);
    }

    this.submitChanges();

    this.toggleModeMenu(this.sensorStates);
    this.formError = false;
    func.hide();
  }

  public submitChanges(): void {
    const updateSensorStatusDto: UpdateSensorStatusDto = this.updateSensorStatusDtoFactory.create(
      this.selectModeMsg.reason,
      this.sensorStatus.isSensorActive,
      this.sensorStatus.isPublishing
    );

    this.sensorDataService.updateSensorStatus(updateSensorStatusDto, this.sensor.id)
      .subscribe(() => {
        this.sensorDataService.getSensor(this.sensor.id)
          .subscribe((updatedSensor: Sensor) => {
            this.sessionService.updateSharedSensor(updatedSensor);
            this.refreshAfterUpdate();
          });
      }, () => {
        this.refreshAfterUpdate();
      }
    );
  }

  private refreshAfterUpdate(): void {
    this.refreshSensorState();
    this.selectModeMsg.reason = '';
    this.logger.debug('Updating sensor log.');
    this.sensorDataService.getAllSensorLogs(this.sessionService.getCurrentSensor().id).subscribe(
      (retrievedLogs: SensorLog[]) => {
        this.streamNotificationService.updateData(retrievedLogs);
      });
  }

  public cancelChange(): void {
    this.selectModeMsg.reason = '';
  }

  public toggleModeMenu(collection: any[]): void {
    collection.forEach((item) => {
      item.hidden = !item.hidden;
    });
  }

  public disableModeMenu(collection: any[]): void {
    collection.forEach((item) => {
      item.hidden = true;
    });
  }
}
