import { SessionService } from '../../shared/service/session.service';
/**
 * This resolver gets called whenever ADFS returns from the login section. This means
 * that the resolver function will try to retrieve a token by the use of the code
 * provided by ADFS in the URL parameter.
 */

import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { Resolve, Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { AuthenticationService } from '../service/authentication.service';

import * as jwt from 'jwt-decode';

@Injectable()
export class AuthenticationResolver implements Resolve<any> {

  constructor(private logger: NGXLogger,
              private authService: AuthenticationService,
              private location: Location,
              private sessionService: SessionService,
              private router: Router) {
    this.logger.debug('constructing Auth resolver.');
  }

  resolve(): void {

    // Take the authorization code from the current url.
    const code = this.getAuthCodeFromUrl(this.location.path(false));

    // Retrieve the token from ADFS.
    this.authService.getToken(this.authService.createTokenRequestData(code)).subscribe((data) => {
      this.logger.debug('AuthResolver: token retrieved');

      // set access token
      this.sessionService.setAccessTokenInMemory(data['access_token']);
      this.sessionService.setAccessTokenInStorage(data['access_token']);

      // decode the data that we retrieve
      const decodedToken: any = this.decodeAccessToken(data);

      // set current user from token
      this.sessionService.setCurrentUserFromToken(decodedToken);

      this.router.navigate(['app/sensors']);
    });
  }

  getAuthCodeFromUrl(url: string): string {
    // Slice the code from the url. +5 because 'code=' has 5 characters.
    return url.slice(url.indexOf('code=') + 5);
  }

  decodeAccessToken(encodedAccessToken: object): object {
    return jwt(encodedAccessToken['access_token']);
  }
}
