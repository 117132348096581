import { PaginationProperties } from '../pagination.properties';

export abstract class BasePagination {

  protected constructor() {

  }

  abstract setPaginationProperties(): PaginationProperties;

  abstract updateDisplayedDataArray(step: number, page: number): any;

  abstract retrieveMaxPage(): number;

  abstract setPages(): number[];

  abstract setDisablePreviousButton(): boolean;

  abstract setDisableNextButton(): boolean;
}
