import { FormControl, Validators } from '@angular/forms';
import { DateValidator } from '../validator/date-validator';
import { ValidatorRegex } from '../validator/validator-enum';

export const createSensorForm = {
  sensorType: new FormControl(null, [
    Validators.required,
  ]),
  sensorName: new FormControl(null, [
    Validators.required,
    Validators.maxLength(55),
  ]),
  device: new FormControl(null, [
    Validators.required,
  ]),
  instrumentType: new FormControl(null, [
    Validators.required,
    Validators.maxLength(255),
  ]),
  locationName: new FormControl(null, [
    Validators.required,
    Validators.maxLength(40),
  ]),
  locationX: new FormControl(null, [
    Validators.required,
    Validators.maxLength(10),
    Validators.pattern(ValidatorRegex.Decimal),
  ]),
  locationY: new FormControl(null, [
    Validators.required,
    Validators.maxLength(10),
    Validators.pattern(ValidatorRegex.Decimal),
  ]),
  locationZ: new FormControl(null, [
    Validators.maxLength(10),
    Validators.pattern(ValidatorRegex.Decimal),
  ]),
  startDate: new FormControl(null, [
    Validators.required,
    DateValidator.validate(),
  ]),
  endDate: new FormControl(null, [
    DateValidator.validate(),
  ]),
  measurementLocations: new FormControl(null, [
    Validators.required,
  ]),
};
