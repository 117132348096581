import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs/Observable';
import { ApiDataService } from '../../../api/api.data.service';
import { Observation } from '../model/observation.model';

import * as moment from 'moment';
import 'rxjs/add/observable/combineLatest';
import 'rxjs/add/observable/forkJoin';
import 'rxjs/add/operator/combineLatest';
import { Process } from '../../process/model/process.model';

@Injectable()
export class ObservationService {

  private readonly PHENOMENON_TIME_DESC: string = 'phenomenonTime%2CDesc';

  constructor(private logger: NGXLogger,
              private apiDataService: ApiDataService) {

    this.logger.debug('constructing ObservationService.');
  }

  /**
   *
   * @param datastreamId which datastream we need the observations from
   * @param beginDate beginnig for the period to retrieve observations
   * @param endDate ending for the period to retrieve observations
   * @param sort
   * @param page which page to retrieve the data from
   * @param size
   * @param shouldModalBeDisplayed whether or not to show a loading modal.
   */
  private getObservationsByDatastream(datastreamId: string,
                                      beginDate: Date,
                                      endDate: Date,
                                      sort: string = this.PHENOMENON_TIME_DESC,
                                      page: number = 0,
                                      size: number = 10000,
                                      shouldModalBeDisplayed: boolean = true): Observable<Observation[]> {

    // Format the date parameters so they are usable in the REST call.
    const beginDateString: string = beginDate.toISOString().replace('T', ' ').replace('Z', ' ');
    const endDateString: string = endDate.toISOString().replace('T', ' ').replace('Z', ' ');

    return this.apiDataService.findByQuery('observations', 'datastreamId=' + datastreamId,
      'beginDate=' + beginDateString, 'endDate=' + endDateString, page, shouldModalBeDisplayed, size, sort);
  }

  /**
   *
   * @param processes processes to get the observations from
   * @param beginDate beginnig for the period to retrieve observations
   * @param endDate ending for the period to retrieve observations
   * @param size the amount of observations will be retrieved
   * @param shouldModalBeDisplayed whether or not to show a loading modal.
   */
  public getObservationsByProcesses(processes: Process[],
                                    size: number,
                                    beginDate: Date,
                                    endDate: Date = moment().toDate(),
                                    shouldModalBeDisplayed: boolean = false): Observable<any> {

    const observables: Array<Observable<any>> = [];

    for (const process of processes) {
      for (const datastream of process.datastreams) {
        observables.push(this.getObservationsByDatastream(
          datastream.id,
          beginDate,
          endDate,
          this.PHENOMENON_TIME_DESC,
          undefined,
          size,
          shouldModalBeDisplayed
        ));
      }
    }

    return Observable.forkJoin(observables);
  }
}
