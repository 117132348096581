import {Injectable} from '@angular/core';
import {NGXLogger} from 'ngx-logger';
import {Observable} from 'rxjs/Observable';
import {ApiDataService} from '../../../api/api.data.service';
import {Activity} from '../model/activity.model';

@Injectable()
export class ActivityService {

  constructor(private logger: NGXLogger,
              private apiDataService: ApiDataService) {
    this.logger.debug('constructing ActivityService.');
  }

  getAllActivities(page: number = 0, size: number = 0,
                   sort: string                   = 'desc'): Observable<Activity[]> {
    this.logger.debug('ActivityService: retrieving activities.');
    return this.apiDataService.getAll('activities', page, size, sort);
  }
}
