import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { PaginationProperties } from '../../../../shared/ui/pagination/model/pagination.properties';
import { TimeUtils } from '../../../../shared/utils/time-conversion-properties';
import { Property } from '../../../property/model/property.model';
import { SensorLog, SensorLogInterface } from '../../model/sensor-log.model';
import { activityLogSearchOption } from './properties/activity-log-search-option';
import { SensorDetailStreamUpdateLogsNotificationService } from './sensor-detail-stream-update-logs-notification-service';

export class Activity {
  public date: string;
  public time: string;
  public sensorMode: string;
  public reason: string;
  public user: string;
}

@Component({
  selector: 'app-sensor-detail-stream',
  templateUrl: './sensor-detail-stream.component.html',
  styleUrls: ['./sensor-detail-stream.component.scss'],
})
export class SensorDetailStreamComponent implements OnInit {

  @Input()
  updatedSensorLogs: SensorLog[];

  private readonly NOT_PUBLISHING: string = 'Not publishing';

  visible: boolean = false;

  public sensorMode = '';
  public sensorText = '';
  public paramText = '';
  public paramTextSeconds = '';
  public paramTextMinutes = '';
  public activities: Activity[] = [];
  public sensorLogs: SensorLog[];
  public streamProperties: Property[];
  public tableProperties = [];

  // DEBUG
  public updatedViewSensorLogs;

  // pagination properties
  public paginationProperties: PaginationProperties;

  /* specific properties for search bar */
  public searchbarPlaceholderTitle: string = 'search activity logs table';
  public searchTerm: string;
  public searchOption: any;

  constructor(private route: ActivatedRoute,
              private streamNotificationService: SensorDetailStreamUpdateLogsNotificationService,
              private logger: NGXLogger
  ) {
    this.sensorLogs = this.convertLogInterfaceListToModelList(this.route.snapshot.data['sensorLog']);
    this.streamProperties = this.route.snapshot.data['streamProperties'];
    this.tableProperties = [
      {
        header: 'Date',
        accessor: { main: 'actionTime' },
        containsDate: true,
      },
      {
        header: 'Time',
        accessor: {
          main: 'timeString',
        },
        containsDate: false,
      },
      {
        header: 'Activity',
        accessor: { main: 'actionTaken' },
        containsDate: false,
      },
      {
        header: 'Reason',
        accessor: { main: 'reason' },
        containsDate: false,
      },
      {
        header: 'User',
        accessor: { main: 'user' },
        containsDate: false,
      },
    ];
    this.paginationProperties = new PaginationProperties();
    this.updatedViewSensorLogs = this.streamNotificationService.
      getData().subscribe((data) => {
        this.logger.debug('updated sensor data: ', data);
        this.sensorLogs = this.convertLogInterfaceListToModelList(data);
        this.setPaginationProperties(this.sensorLogs);
      });
    this.setSearchBarProperties();
    this.setPaginationProperties(this.sensorLogs);
  }

  ngOnInit() {
    this.unpublishSensor();
    this.setHidden(true);
    this.updateSensorLogs();
  }

  public getParam(feature) {
    if (feature !== undefined && feature.id !== undefined) {
      const prop = [];
      for (const proc of feature.processes) {
        for (const dat of proc.datastreams) {
          prop.push(dat.property);
        }
      }
      return prop;
    } else {
      return this.streamProperties;
    }
  }

  public publishSensor(): void {
    this.sensorMode = 'published';
    this.sensorText = 'Data of the parameters is published';
    this.paramText = 'Publishing';
    this.paramTextMinutes = 'Publishing';
    this.paramTextSeconds = this.NOT_PUBLISHING;
  }

  public unpublishSensor(): void {
    this.sensorMode = 'unpublished';
    this.sensorText = 'Data of the parameters is not published';
    this.paramTextMinutes = this.NOT_PUBLISHING;
    this.paramTextSeconds = this.NOT_PUBLISHING;
  }

  public disableSensor(): void {
    this.sensorMode = 'disabled';
    this.sensorText = 'Sensor is off, no data is published';
    this.paramTextMinutes = 'Off';
    this.paramTextSeconds = 'Off';
  }

  setHidden(hide: boolean): void {
    this.visible = hide;
  }

  /* setting search bar properties */
  public setSearchBarProperties(): void {
    this.searchOption = activityLogSearchOption;
  }

  /* debug event emitter from search bar */
  public updateDisplayDataEventEmitter(payload): void {
    this.updatePaginationProperty(payload);
    this.logger.debug('updating pagination property: ', this.paginationProperties);

  }

  public updateSensorLogs(): void {
    this.streamNotificationService.getData().subscribe((data) => {
      this.sensorLogs = this.convertLogInterfaceListToModelList(data);
    });
  }

  /* update step from UI  */
  public updateStep(payload): void {
    this.logger.debug('updating properties after step button is clicked: ', payload);
    this.updatePaginationProperty(payload);
  }

  /* click previous step from UI */
  public clickPreviousButton(payload): void {
    this.logger.debug('updating properties after previous button is clicked: ', payload);
    this.updatePaginationProperty(payload);
  }

  /* click next button from UI */
  public clickNextButton(payload): void {
    this.logger.debug('updating properties after next button is clicked: ', payload);
    this.updatePaginationProperty(payload);
  }

  private convertLogInterfaceListToModelList(logItems: SensorLogInterface[]): SensorLog[] {
    const logs: SensorLog[] = [];
    logItems.forEach((item: SensorLogInterface) => {
      logs.push(this.logInterfaceToModel(item));
    });
    return logs;
  }

  private logInterfaceToModel(sensorLogInterface: SensorLogInterface): SensorLog {
    return new SensorLog(
      sensorLogInterface.sensorId,
      sensorLogInterface.user,
      sensorLogInterface.actionTaken,
      sensorLogInterface.reason,
      /* action time is changed to Amsterdam/Rotterdam local time but only on client-side,
       * on server side and ODS it remains on UTC format */
      TimeUtils.convertUTCToLocal(sensorLogInterface.actionTime)
    );
  }

  /* updating pagination properties */
  private updatePaginationProperty(property: PaginationProperties): void {
    this.paginationProperties.setDisplayedData(property.getDisplayedData());
    this.paginationProperties.setStep(property.getStep());
    this.paginationProperties.setPage(property.getPage());
    this.paginationProperties.setPages(property.getPages());
    this.paginationProperties.setMaxPage(property.getMaxPage());
    this.paginationProperties.setIsNextButtonDisabled(property.getIsNextButtonDisabled());
    this.paginationProperties.setIsPreviousButtonDisabled(property.getIsPreviousButtonDisabled());
    this.paginationProperties.setFilteredData(property.getFilteredData());
  }

  /* settings page properties */
  private setPaginationProperties(dataset: any[]): void {
    // initialize dataset size to display first 10 rows
    const initDataset = this.paginationProperties.initializeDisplayDataset(dataset);

    this.paginationProperties.setFilteredData(dataset);
    this.paginationProperties.setDisplayedData(initDataset);
    this.paginationProperties.setStep(this.paginationProperties.getSteps()[0]);
    this.paginationProperties.setPage(0);

    const initMaxPage = this.paginationProperties.calculateMaxPage(
      this.paginationProperties.getFilteredData(), this.paginationProperties.getStep()
    );
    this.paginationProperties.setMaxPage(initMaxPage);

    const initPages = this.paginationProperties.calculateNumberOfPages(initMaxPage);
    const isPreviousButtonDisabled = this.paginationProperties.disablePreviousButton(
      this.paginationProperties.getPage()
    );

    const isNextButtonDisabled = this.paginationProperties.disableNextButton(this.paginationProperties.getPage(),
      this.paginationProperties.getMaxPage());

    this.paginationProperties.setPages(initPages);
    this.paginationProperties.setIsPreviousButtonDisabled(isPreviousButtonDisabled);
    this.paginationProperties.setIsNextButtonDisabled(isNextButtonDisabled);

    this.logger.debug('INIT PROPERTIES: ', this.paginationProperties);
  }
}
