import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import 'rxjs/add/operator/filter';
import { v4 } from 'uuid';
import { SensorType } from '../../sensor-type/model/sensor-type.model';
import { CommandController } from './properties/controller/create-command-controller';
import { DeviceController } from './properties/controller/device-controller';
import { MeasurementLocationFormController } from './properties/controller/measurement-form-controller';
import { PageController } from './properties/controller/page-controller';
import { AddNewSensorFormController } from './properties/controller/sensor-form-controller';
import { createSensorForm } from './properties/form/create-sensor-form';
import {
  GenericMeasurementFormConfig, HeightOfTideMeasurementFormConfig,
  TidalStreamMeasurementFormConfig,
} from './properties/form/measurement-form';
import { routeProperties } from './properties/general/form-properties';
import { CreateSensorDataService } from '../service/create-sensor-data.service';
import { SensorNameValidator } from './properties/validator/sensor-name-validator';
import { DeviceService } from '../../../shared/iot-platform/service/device.service';
import { Device } from '../../../shared/iot-platform/model/iot-device';
import { TableProperties } from '../../../shared/ui/table/model/table-properties.model';
import { FeatureTypeService } from '../service/feature-type.service';
import { FeatureType } from '../model/feature-type.model';
import { ValidatorMessage } from './properties/validator/validator-enum';
import { SensorSummary } from '../overview/model/sensor-overview.model';
import { SensorTypeEnum } from '../../../shared/utils/sensor-type-enum';

@Component({
  selector: 'app-add-sensor',
  templateUrl: './create-sensor.component.html',
  styleUrls: ['./create-sensor.component.scss'],
})
export class AddSensorComponent implements OnInit {

  // resolvers
  public devices: Device[] = [];
  public sensorTypes: SensorType[] = [];
  public sensors: SensorSummary[] = [];
  public featureTypes: FeatureType[] = [];
  // forms
  public createSensorForm: FormGroup;
  public measurementLocationForm: FormGroup;
  // controllers
  public pageController: PageController;
  public addNewSensorFormController: AddNewSensorFormController;
  public measurementLocationFormController: MeasurementLocationFormController;
  public deviceController: DeviceController;
  public commandController: CommandController;
  public validatorMessage = ValidatorMessage;

  // table data
  public tableProperties: TableProperties[] = [];

  constructor(private formBuilder: FormBuilder,
              private router: Router,
              private route: ActivatedRoute,
              public createSensorDataService: CreateSensorDataService,
              public deviceService: DeviceService,
              public featureTypeService: FeatureTypeService) {
    this.tableProperties = [
      {header: 'Name', accessor: {main: 'name'}, containsDate: false},
      {header: 'Type', accessor: {main: 'type'}, containsDate: false},
      {header: 'X-position RD', accessor: {main: 'x'}, containsDate: false},
      {header: 'Y-position RD', accessor: {main: 'y'}, containsDate: false},
      {header: 'Z-position NAP in M', accessor: {main: 'z'}, containsDate: false},
    ];
  }

  public ngOnInit(): void {
    this.retrieveDataFromResolvers();
    this.createMeasurementLocationForm();
    this.createAddSensorForm();
    this.initControllers();
    this.setDefaultSensorType();
  }

  private setDefaultSensorType(): void {
    if (this.sensorTypes && this.sensorTypes.length > 0) {
      this.addNewSensorFormController.sensorTypeControl.setValue(this.sensorTypes[0]);
    }
  }

  public initControllers(): void {
    this.pageController = new PageController();
    this.addNewSensorFormController = new AddNewSensorFormController(this.createSensorForm);
    this.measurementLocationFormController = new MeasurementLocationFormController(this.measurementLocationForm, this.addNewSensorFormController);
    this.deviceController = new DeviceController(this.addNewSensorFormController, null);
    this.commandController = new CommandController(this.addNewSensorFormController);
  }

  public backToSensorsOverview(): Promise<boolean> {
    this.pageController.clearStepper();

    return this.router.navigate([routeProperties.sensorsOverviewPage]);
  }

  public goToNextStep(): void {
    const nextStep: number = this.pageController.currentStep + 1;
    this.pageController.setStepCompleted(this.pageController.currentStep);
    this.goToStep(nextStep);
    PageController.goToTop();
  }

  public goToStep(step: number): void {

    if (step < 0 || step > 4) {
      return;
    }

    if (step === 4) {
      this.addNewSensor();
      return;
    }

    switch (step) {
      case 1:
        if (this.deviceController.selectedDevice !== null) {
          const changedDeviceType = this.deviceController.selectedDevice.deviceType !== this.addNewSensorFormController.sensorType.deviceType;
          if (changedDeviceType) {
            this.deviceController.selectedDevice = null;
            this.pageController.setStepIncomplete(1);
            this.createSensorForm.patchValue({
              device: null,
            });
            this.retrieveDevices();
            this.deviceController.devices = this.devices;
          }
        } else {
          this.retrieveDevices();
          this.deviceController.devices = this.devices;
        }
        this.createMeasurementLocationFormFromSensorType(this.addNewSensorFormController.sensorType);
        break;
      case 2:
        this.retrieveFeatureTypes();
        break;
      case 3:
        this.commandController.createPayload();
        break;
    }
    this.pageController.setNavigationTextForward(step);
    this.pageController.setStepVisited(step);
    this.pageController.setCurrentStep(step);
  }

  public goToPreviousStep(): void {
    if (this.pageController.currentStep > 0) {
      this.goToStep(this.pageController.currentStep - 1);
    }
  }

  public retrieveDevices(): void {
    this.devices = [];
    this.deviceService.getAllDevicesBySensorType(this.addNewSensorFormController.sensorType.deviceType)
    .subscribe(deviceList => {
      deviceList.forEach(device => {
        this.devices.push(new Device(device.deviceId, device.dateAdded, device.location,
          device.gatewayId, device.gatewayTypeId, device.deviceType));
      });
    });
  }

  private retrieveDataFromResolvers(): void {
    this.sensorTypes = this.route.snapshot.data['sensorTypes'];
    this.sensors = this.route.snapshot.data['sensorSummaries'];
  }

  private addNewSensor(): void {
    this.createSensorDataService.postCreateSensorData(this.commandController.getPayload()).subscribe(() => {
        this.router.navigate(['app/sensors']);
        setTimeout(() => {
          this.pageController.clearStepper();
        }, 2500);
      });
  }

  private createAddSensorForm(): void {
    this.createSensorForm = this.formBuilder.group(createSensorForm);
    this.createSensorForm.reset();
    this.createSensorForm.get('sensorName').setValidators([Validators.required, Validators.maxLength(55), SensorNameValidator.validate(this.sensors)]);
  }

  private createMeasurementLocationForm(): void {
    this.measurementLocationForm = this.formBuilder.group(GenericMeasurementFormConfig);
    this.measurementLocationForm.reset();
  }

  private createMeasurementLocationFormFromSensorType(sensorType: SensorType): void {
    if (sensorType.name === SensorTypeEnum.TIDAL_STREAM) {
    this.measurementLocationForm = this.formBuilder.group(TidalStreamMeasurementFormConfig);
    } else if (sensorType.name === SensorTypeEnum.HEIGHT_OF_TIDE) {
      this.measurementLocationForm = this.formBuilder.group(HeightOfTideMeasurementFormConfig);
    } else {
      this.measurementLocationForm = this.formBuilder.group(GenericMeasurementFormConfig);
    }
    this.measurementLocationForm.reset();
    this.measurementLocationFormController = new MeasurementLocationFormController(this.measurementLocationForm, this.addNewSensorFormController);
  }

  public retrieveFeatureTypes(): void {
    this.featureTypeService.getAllFeatureTypesBySensorType(this.addNewSensorFormController.sensorType.name)
      .subscribe(featureTypes => this.featureTypes = featureTypes);
  }

  public isNextButtonDisabledAtStep(step: number): boolean {
    switch (step) {
      case 0: {
        return this.addNewSensorFormController.isTypeAndNameInvalid();
      }
      case 1: {
        return this.addNewSensorFormController.isDeviceInvalid();
      }
      case 2: {
        return this.addNewSensorFormController.isGeneralInformationInvalid();
      }
      default: {
        return false;
      }
    }
  }

  public isPreviousButtonDisabledAtStep(step: number): boolean {
    return step === 0;
  }
}
