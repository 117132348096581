import {Component, OnInit} from '@angular/core';
import {NGXLogger} from 'ngx-logger';
import {ModalStatus, StatusModalInfo} from './status-modal-info';
import {StatusModalService} from './status-modal.service';

@Component({
  selector:    'app-status-modal',
  templateUrl: 'status-modal.component.html',
  styleUrls:   ['./status-modal.component.scss'],
})
export class StatusModalComponent implements OnInit {

  public visible        = false;
  public statusText     = '';
  public visibleAnimate = false;
  public openCalls      = 0;

  constructor(private modalService: StatusModalService, private logger: NGXLogger) {
    this.logger.debug('constructing statusModalComponent.');
  }

  ngOnInit(): void {
    this.modalService.trigger.subscribe((modalInfo) => {

      if (this.openCalls < 0) {
        this.openCalls = 0;
      }

      if (modalInfo && modalInfo.status !== ModalStatus.CLOSE) {
        this.logger.debug('opening status modal');
        this.openCalls++;
        this.show(modalInfo);
      } else if (this.openCalls > 1) {
        this.logger.debug('lowering status modal opening calls by 1');
        this.openCalls--;
      } else {
        this.logger.debug('closing status modal');
        this.openCalls--;
        this.hide();
      }
    });
  }

  public show(modalInfo: StatusModalInfo): void {
    this.visible    = true;
    this.statusText = modalInfo.message;
  }

  public hide(): void {
    this.visible = false;
  }

}
