import { Component, OnInit } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { SessionService } from '../../shared/service/session.service';
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-menu-bar',
  templateUrl: './menu-bar.component.html',
  styleUrls: ['./menu-bar.component.scss'],
})
export class MenuBarComponent implements OnInit {
  public optionHidden: boolean = true;
  public username: string;

  constructor(private readonly logger: NGXLogger,
              private readonly sessionService: SessionService) {
    logger.debug('MenuBarComponent: constructing.');
  }

  ngOnInit() {
    this.username = this.getUsername();
  }

  getUsername(): string {
    return this.sessionService.getCurrentUser().unique_name;
  }

  public logOut(): void {
      this.sessionService.setAccessTokenInStorage('');
      this.sessionService.setAccessTokenInMemory('');

      window.location.href = 'https://login.microsoftonline.com/' + environment.activeDirectoryTenantId + '/oauth2/logout';
  }
}
