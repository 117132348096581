import { NgModule } from '@angular/core';
import { AuthenticationComponent } from '../authentication/authentication.component';
import { AuthenticationResolver } from '../authentication/resolver/authentication.resolver';
import { AuthenticationService } from '../authentication/service/authentication.service';
import { SharedModule } from '../shared/shared.module';
import { UiModule } from '../shared/ui/ui.module';
import { LoginComponent } from './login.component';

// todo: add authentication to own module

@NgModule({
  imports: [
    SharedModule,
    UiModule,
  ],
  declarations: [
    LoginComponent,
    AuthenticationComponent,
  ],
  providers: [
    AuthenticationService,
    AuthenticationResolver,
  ],
})

export class LoginModule {

}
