import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs/Observable';
import { SessionService } from '../../../shared/service/session.service';

import 'rxjs/add/operator/do';
import { Feature } from '../model/feature.model';
import { FeatureService } from '../service/feature.service';

@Injectable()
export class FeatureResolver implements Resolve<Feature[]> {

  constructor(private logger: NGXLogger,
              private sessionService: SessionService,
              private featureDataService: FeatureService) {
    this.logger.debug('constructing feature resolver.');
  }

  resolve(route: ActivatedRouteSnapshot,
          state: RouterStateSnapshot): Observable<Feature[]> {

    this.logger.debug('FeatureResolver: Waiting for feature data to be available.');

    let sensorId;
    // this resolver can be called from '/parent_route/:sensorId/component', or
    // '/route/sensorId'. This means we need to check which route is used now.
    if (route.params['id']) {
      sensorId = route.params['id'];
    } else {
      sensorId = route.parent.params['id'];
    }

    return this.featureDataService.getSensorFeatures(sensorId).do((features) => {
        this.sessionService.updateSharedFeatures(features);
    });
  }

  /**
   * retrieve metadta for feature.
   *
   * @param feature featur to retrieve metadta from.
   */
  private getMetadata(feature: Feature): Observable<any> {

    if (feature !== undefined && feature.id !== '') {
      return this.featureDataService.getMetadataByFeatureId(feature.id);
    }

    return new Observable<any>();
  }
}
