import {Injectable} from '@angular/core';
import {Resolve, Router} from '@angular/router';

import {NGXLogger} from 'ngx-logger';
import {Observable} from 'rxjs/Observable';

import 'rxjs/add/observable/of';
import 'rxjs/add/operator/delay';
import {MetaData, MetaDataInterface} from '../model/meta-data.model';
import {AstronomicalDataService} from '../service/astronomical-data.service';

import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/finally';
import 'rxjs/add/operator/map';

@Injectable()
export class UploadResolver implements Resolve<Observable<MetaData[]>> {

  constructor(private astronomicalDataService: AstronomicalDataService,
              private logger: NGXLogger,
              private router: Router) {
    this.logger.debug('UploadResolver: constructing.');
  }

  resolve(): Observable<MetaData[]> {
    this.logger.debug('UploadResolver: Waiting for upload data to be available.');

    return this.astronomicalDataService.getAllAstronomicalPredictionsMetaData()
      .map((items: MetaDataInterface[]) => {
        const metadata: MetaData[] = [];
        items.forEach((item: MetaDataInterface) => {
          metadata.push(new MetaData(item));
        });
        return metadata;
      })
      .catch((error) => {
        if (error === 404) {
          this.router.navigate(['404']);
        } else {
          this.router.navigate(['error']);
        }
        return Observable.of(null);
      });
  }
}
