import { FormControl, Validators } from '@angular/forms';
import { ValidatorRegex } from '../../../create/properties/validator/validator-enum';
import { RangeValidator } from '../../../create/properties/validator/range-validator';

interface UpdateLocationFormInterface {
  type: FormControl;
  name: FormControl;
  description: FormControl;
  x: FormControl;
  y: FormControl;
  z: FormControl;
  endDate: FormControl;
  distance: FormControl;
  directionFrom: FormControl;
  directionTo: FormControl;
  napToALatConversion: FormControl;
}

export const TidalStreamUpdateLocationForm: UpdateLocationFormInterface = {
  type: new FormControl({disabled: true}, Validators.required),
  name: new FormControl({disabled: true}, [Validators.required, Validators.maxLength(40)]),
  description: new FormControl({disabled: true}, [Validators.required, Validators.maxLength(254)]),
  x: new FormControl(null, [
    Validators.required,
    Validators.pattern(ValidatorRegex.Decimal),
  ]),
  y: new FormControl(null, [
    Validators.required,
    Validators.pattern(ValidatorRegex.Decimal),
  ]),
  z: new FormControl(null, [
    Validators.pattern(ValidatorRegex.Decimal),
  ]),
  endDate: new FormControl(null, [
  ]),
  distance: new FormControl(null, [
    Validators.required,
    Validators.pattern(ValidatorRegex.PositiveInteger),
  ]),
  directionFrom: new FormControl(null, [
    Validators.required,
    Validators.pattern(ValidatorRegex.PositiveInteger),
    RangeValidator.validate(0, 359),
  ]),
  directionTo: new FormControl(null, [
    Validators.required,
    Validators.pattern(ValidatorRegex.PositiveInteger),
    RangeValidator.validate(0, 359),
  ]),
  napToALatConversion: new FormControl(null, [
    Validators.pattern(ValidatorRegex.Decimal),
  ]),
};

export const HeightOfTideUpdateLocationForm: UpdateLocationFormInterface = {
  type: new FormControl({disabled: true}, Validators.required),
  name: new FormControl({disabled: true}, [Validators.required, Validators.maxLength(40)]),
  description: new FormControl({disabled: true}, [Validators.required, Validators.maxLength(254)]),
  x: new FormControl(null, [
    Validators.required,
    Validators.pattern(ValidatorRegex.Decimal),
  ]),
  y: new FormControl(null, [
    Validators.required,
    Validators.pattern(ValidatorRegex.Decimal),
  ]),
  z: new FormControl(null, [
    Validators.pattern(ValidatorRegex.Decimal),
  ]),
  endDate: new FormControl(null, [
  ]),
  distance: new FormControl(null, [
    Validators.pattern(ValidatorRegex.PositiveInteger),
  ]),
  directionFrom: new FormControl(null, [
    Validators.pattern(ValidatorRegex.PositiveInteger),
    RangeValidator.validate(0, 359),
  ]),
  directionTo: new FormControl(null, [
    Validators.pattern(ValidatorRegex.PositiveInteger),
    RangeValidator.validate(0, 359),
  ]),
  napToALatConversion: new FormControl(null, [
    Validators.required,
    Validators.pattern(ValidatorRegex.Decimal),
  ]),
};

export const GeneralUpdateLocationForm: UpdateLocationFormInterface = {
  type: new FormControl({disabled: true}, Validators.required),
  name: new FormControl({disabled: true}, [Validators.required, Validators.maxLength(40)]),
  description: new FormControl({disabled: true}, [Validators.required, Validators.maxLength(254)]),
  x: new FormControl(null, [
    Validators.required,
    Validators.pattern(ValidatorRegex.Decimal),
  ]),
  y: new FormControl(null, [
    Validators.required,
    Validators.pattern(ValidatorRegex.Decimal),
  ]),
  z: new FormControl(null, [
    Validators.pattern(ValidatorRegex.Decimal),
  ]),
  endDate: new FormControl(null, [
  ]),
  distance: new FormControl(null, [
    Validators.pattern(ValidatorRegex.PositiveInteger),
  ]),
  directionFrom: new FormControl(null, [
    Validators.pattern(ValidatorRegex.PositiveInteger),
    RangeValidator.validate(0, 359),
  ]),
  directionTo: new FormControl(null, [
    Validators.pattern(ValidatorRegex.PositiveInteger),
    RangeValidator.validate(0, 359),
  ]),
  napToALatConversion: new FormControl(null, [
    Validators.pattern(ValidatorRegex.Decimal),
  ]),
};
