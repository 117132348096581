import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Router } from '@angular/router';
import { StockChart } from 'angular-highcharts';
import { ToasterModule, ToasterService } from 'angular5-toaster';
import * as Highcharts from 'highcharts';
import { IMyDate, IMyDateModel, IMyDpOptions } from 'mydatepicker';
import { NGXLogger } from 'ngx-logger';
import { Observation } from '../../../../../../observation/model/observation.model';
import { Feature } from '../../../../../model/feature.model';
import { Sensor } from '../../../../../model/sensor.model';
import { FeatureService } from '../../../../../service/feature.service';
import { SensorDetailStreamUpdateLogsNotificationService } from '../../../../stream/sensor-detail-stream-update-logs-notification-service';
import { ObservationUpdateInfo } from '../../../service/observation-data-update-info.model';
import { SensorDetailDataUpdatingService } from '../../../service/sensor-detail-data-updating.service';
import { ObservationChartSerie } from './model/observation-chart-series.model';
import { StructuredObservations } from './model/structured-observations.model';
import { DefaultHChartProperties } from './properties/default-chart-properties';
import { HChartProperties } from './properties/h-chart-properties';
import { SaChartProperties } from './properties/sa-chart-properties';
import { TsChartProperties } from './properties/ts-chart-properties';
import { VisChartProperties } from './properties/vis-chart-properties';
import { WChartProperties } from './properties/w-chart-properties';
import { ObservationsConvertService } from './service/observations-conversion.service';

@Component({
  selector: 'app-sensor-observations-chart',
  templateUrl: './sensor-observations-chart.component.html',
  styleUrls: ['./sensor-observations-chart.component.scss'],
})

export class SensorObservationsChartComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() sensor: Sensor;
  @Input() observations: StructuredObservations[] = [];

  @Output() manualDateSelect = new EventEmitter<any>();

  public chart: StockChart;
  public chartSeries: ObservationChartSerie[] = [];

  public myDatePickerOptions: IMyDpOptions = {
    dateFormat: 'dd/mm/yyyy',
  };
  public manualDateSelectInfo: any = {};
  public manualDateSelectInfoFromView: IMyDate = null;
  public hide: boolean = false;
  public manualDateSelectInfoToView: IMyDate = null;
  public updating: boolean = true;
  public isLines = true;
  private featureId: string = '';

  constructor(private logger: NGXLogger, private router: Router,
              private sensorDetailDataUpdatingService: SensorDetailDataUpdatingService,
              private streamNotificationService: SensorDetailStreamUpdateLogsNotificationService,
              private featureDataService: FeatureService,
              private observationConvertService: ObservationsConvertService,
              private cdRef: ChangeDetectorRef,
              private toasterService: ToasterService) {

    this.logger.debug('SensorChartComponent: constructing.');
  }

  ngOnInit(): void {
    this.logger.debug('SensorChartComponent: initializing.');
    this.chartSeries = [];
    if (this.chart) {
      this.chart.ref = null;
    }
    // register to the updates and date selection
    this.sensorDetailDataUpdatingService.streamUpdateObservable.subscribe(
      (sensorDetailDataUpdate) => this.update(sensorDetailDataUpdate));
    this.sensorDetailDataUpdatingService.manualDateSelectObservable.subscribe(
      (manualDateSelectInfo) => this.drawChartByDate(manualDateSelectInfo));

    this.initializeObservationChartSeries(this.observations);
    this.initializeChart();
  }

  ngOnDestroy(): void {
    this.logger.debug('SensorChartComponent: destroying chart.');
    this.chartSeries = [];
  }

  // TODO: check code and structure of date functionality.
  drawChartByDate(dateSelectInfo: any): void {
    if (dateSelectInfo) {

      this.logger.debug('SensorChartComponent: drawing chart by selected dates.');
      this.chartSeries = [];

      this.initializeObservationChartSeries(this.observations);
      this.initializeChart();

      // reset the datepickers in the view.
      this.manualDateSelectInfo = {};
    }
  }

  // TODO: check code and structure of date functionality.
  /**
   * this function register user input in the date selection.
   *
   * @param event event data from the datepicker
   * @param id id to register the data to. In this case 'from' and 'to'
   */
  onDateSelect(event: IMyDateModel, id): void {

    this.logger.debug('SensorChartComponent: User has selected a \'' + id + '\' date');

    // fill the correct entry in the info object.
    switch (id) {
      case 'to':
        this.manualDateSelectInfo.to = event.jsdate;
        break;
      case 'from':
        this.manualDateSelectInfo.from = event.jsdate;
        break;
    }

    // if the user has chosen a start and ending date, notify the application.
    if (this.manualDateSelectInfo.from && this.manualDateSelectInfo.to) {
      if (this.manualDateSelectInfo.from >= this.manualDateSelectInfo.to) {
        this.toasterService.pop('warning', 'Invalid date entered', 'The to date is the same or lower than the from date.');
      } else {
        this.logger.debug('SensorChartComponent: User has selected both date inputs.');
        this.manualDateSelect.emit(this.manualDateSelectInfo);
      }
    }
  }

  /**
   * Update the data in the chart to reflect the changes on this.observations.
   *
   * @param updateInfo info needed for updating the correct lines in the chart.
   */
  update(updateInfo: ObservationUpdateInfo[]): void {
    this.logger.debug('SensorChartComponent: observations are updated. updating chart.');

    for (const info of updateInfo) {
      for (const structuredObservation of this.observations) {
        if (structuredObservation.stream === info.stream && info.amount > 0) {

          const newObservations: Observation[] = structuredObservation.observations
            .slice((structuredObservation.observations.length) - info.amount);

          for (const chartSerie of this.chartSeries) {
            if (chartSerie.name === info.stream) {
              this.logger.debug('SensorChartComponent: updating series ' + chartSerie.name);

              const points = this.observationConvertService.convertToChartStreamSeriesData(newObservations);
              const checks = this.observationConvertService.convertToChartStreamSeriesCheckData(newObservations);

              this.updateChart(chartSerie.name, points);
              this.updateChart(chartSerie.name + ' - CHECKS', checks);
            }
          }
        }
      }
    }
  }

  /**
   * Update lines in the chart with new data.
   *
   * @param name name of the series in the chart to update.
   * @param points data to add to the line.
   */
  updateChart(name: string, points: any[]): void {
    for (const series of this.chart.ref.series) {
      if (series.name === name) {
        for (const point of points) {
          series.addPoint(point);
        }
      }
    }
  }

  /**
   * Initialize the chart with the first set of observation data.
   *
   * @param observations observations needed for converting the first time loading.
   */
  initializeObservationChartSeries(observations: StructuredObservations[]): void {
    for (const structuredObservations of observations) {
      this.chartSeries = this.chartSeries.concat(
        this.observationConvertService.convertToChartStreamSeries(structuredObservations));
    }
  }

  ngAfterViewInit(): void {

    // set the global chart options.
    Highcharts.setOptions({
      global: {
        useUTC: false,
      },
    });

  }

  delay(ms: number) {
      return new Promise( (resolve) => setTimeout(resolve, ms) );
  }

  updateFeatureRef(feature: Feature): void {
    // Metada
    if (this.chart !== undefined && feature !== undefined && this.featureId !== feature.id && feature.id !== '' && this.chart.ref.yAxis.length > 2) {

      this.featureDataService.getMetadataByFeatureId(feature.id).subscribe(
        (featureData) => {
          if (featureData !== undefined && featureData !== null) {
            // Remove all old data
            this.delay(1000).then(
              () => {
                if (this.chart.options.yAxis[2].plotLines !== undefined) {
                  this.chart.options.yAxis[2].plotLines.forEach((line) => {
                      this.chart.ref.yAxis[2].removePlotLine(line.id);
                  });
                }

                // featureData.forEach(
                //   (metadata) => {
                //     this.cdRef.detach();
                //     this.chart.ref.yAxis[2].addPlotLine({
                //         id: metadata.attribute,
                //         value: metadata.value,
                //         color: 'gray',
                //         dashStyle: 'shortdash',
                //         width: 2,
                //         label: {
                //             text: metadata.attribute,
                //         },
                //     });
                //     this.cdRef.reattach();
                //   }
                // );
              }
            );
          }
        }
      );

      this.featureId = feature.id;
    }
  }

  /**
   * create the chart on the page with the correct parameters.
   */
  initializeChart(): void {

    // check if there is any data
    this.hide = false;

    const chartProperties = DefaultHChartProperties;
    chartProperties.title.text = this.sensor.sensorType.name;

    switch (this.sensor.sensorType.deviceType) {
      case 'visibility':
        chartProperties.yAxis = VisChartProperties.yAxis;
        break;
      case 'waterlevel':
        chartProperties.yAxis = HChartProperties.yAxis;
        break;
      case 'salinity':
        chartProperties.yAxis = SaChartProperties.yAxis;
        break;
      case 'tidalstream':
        chartProperties.yAxis = TsChartProperties.yAxis;
        break;
      case 'windmierij':
      case 'windxsiam':
        chartProperties.yAxis = WChartProperties.yAxis;
        break;
      default:
        this.logger.warn(`Can't find property for ${this.sensor.sensorType.deviceType}`);
        this.logger.error('Not implemented!');
        this.hide = true;
        break;
    }

    // Only fill series if there is data and yAxis is set
    if (!this.hide) {
      chartProperties.series = this.chartSeries;
    }

    this.chart = new StockChart(chartProperties);
  }
}
