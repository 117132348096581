import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './authorization/service/auth-guard';
import { MainComponent } from './main.component';

export const routes: Routes = [
  {
    path:      'app',
    component: MainComponent,
    canActivate: [AuthGuard],
    children:  [
      {
        path:         'map',
        loadChildren: 'app/components/map/map.module#MapModule',
        data: {
          resource: '/map',
          rights: ['readAll'],
        },
      },
      {
        path:         'sensors',
        loadChildren: 'app/components/sensor/sensor.module#SensorModule',
        data: {
          resource: '/sensors',
          rights: ['readAll'],
        },
      },
      {
        path:         'external-interface',
        loadChildren: 'app/components/ext-interface/ext-interface.module#ExtInterfaceModule',
        data: {
          resource: '/external-interfaces',
          rights: ['readAll'],
        },
      },
    ],
  },
  {path: '**', redirectTo: '404'},
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
  ],
})
export class MainRoutingModule {

}
