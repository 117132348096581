import { Feature } from '../model/feature.model';
import { MeasurementLocation } from '../../../shared/iot-platform/model/measurement-location';
import { FeatureMetadata } from '../model/feature-metadata.model';

export class MeasurementLocationFactory {

  private static DISTANCE: string = 'Distance';
  private static MAXIMUM: string = 'Maximum';
  private static MINIMUM: string = 'Minimum';
  private static NAP_ALAT_CONVERSION: string = 'Conversion NAP to (A)LAT (cm)';

  public static create(name: string,
                       type: string,
                       description: string,
                       xCoordinate: number,
                       yCoordinate: number,
                       zCoordinate: number,
                       endDate: Date = null,
                       distance: string = '',
                       directionFrom: string = '',
                       directionTo: string = '',
                       napToALatConversion: string = '') {
    return new MeasurementLocation(
      name,
      type,
      description,
      xCoordinate,
      yCoordinate,
      zCoordinate,
      endDate,
      distance,
      directionFrom,
      directionTo,
      napToALatConversion);
  }

  public static mapFeature(feature: Feature): MeasurementLocation {
    const featureMetaDataList: FeatureMetadata[] = feature.featureMetadata;

    return new MeasurementLocation(
      feature.name,
      feature.featureType.name,
      feature.description,
      feature.x,
      feature.y,
      feature.z,
      feature.endDate,
      feature.featureMetadata === undefined ? '' : MeasurementLocationFactory.getAttributeValue(featureMetaDataList, this.DISTANCE),
      feature.featureMetadata === undefined ? '' : MeasurementLocationFactory.getAttributeValue(featureMetaDataList, this.MINIMUM),
      feature.featureMetadata === undefined ? '' : MeasurementLocationFactory.getAttributeValue(featureMetaDataList, this.MAXIMUM),
      feature.featureMetadata === undefined ? '' : MeasurementLocationFactory.getAttributeValue(featureMetaDataList, this.NAP_ALAT_CONVERSION)
    );
  }

  private static getAttributeValue(featureMetaDataList: FeatureMetadata[], attribute: string): string {
    let metaDataValue: string = '';

    featureMetaDataList.forEach((featureMetaData: FeatureMetadata) => {
      if (featureMetaData.attribute.includes(attribute)) {
        metaDataValue = featureMetaData.value;
      }
    });

    return metaDataValue;
  }
}
