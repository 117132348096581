/**
 * The login component for the application
 */
import { Component } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { LoginService } from './service/login.service';

@Component({
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {

  constructor(private logger: NGXLogger, private service: LoginService) {
    this.logger.debug('constructing LoginComponent');
  }

  /**
   * Whenever the login button is clicked, redirect trough a service to ADFS login page.
   */
  onLoginClicked(): void {
    this.service.login();
  }
}
