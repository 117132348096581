import { AddCommand } from '../../../../../shared/iot-platform/commands/create-command';
import { AddNewSensorFormController } from './sensor-form-controller';

export class CommandController {
  public addNewSensorFormController: AddNewSensorFormController;
  public createSensorPayload: AddCommand;

  constructor(addNewSensorFormController: AddNewSensorFormController) {
    this.addNewSensorFormController = addNewSensorFormController;
    this.createSensorPayload = new AddCommand();
  }

  public getPayload(): AddCommand {
    return this.createSensorPayload;
  }

  public resetPayload(): void {
    this.createSensorPayload = new AddCommand();
  }

  public createPayload(): AddCommand {
    const payload: AddCommand = this.getPayload();
    payload.sensorTypeId = this.addNewSensorFormController.sensorType.id;
    payload.gatewayId = this.addNewSensorFormController.device.gatewayId;
    payload.gatewayType = this.addNewSensorFormController.device.gatewayTypeId;
    payload.deviceId = this.addNewSensorFormController.device.deviceId;
    payload.dateAdded = this.addNewSensorFormController.device.dateAdded;
    payload.name = this.addNewSensorFormController.sensorName;
    payload.startDate = this.addNewSensorFormController.startDateISOString;
    payload.endDate = this.addNewSensorFormController.endDateISOString;
    payload.instrumentType = this.addNewSensorFormController.instrumentType;
    payload.locationName = this.addNewSensorFormController.locationName;
    payload.xcoordinate = this.addNewSensorFormController.locationX;
    payload.ycoordinate = this.addNewSensorFormController.locationY;
    payload.zcoordinate = this.addNewSensorFormController.locationZ;
    payload.measurementLocations = this.addNewSensorFormController.measurementLocations;

    return payload;
  }

}
