import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs/Observable';
import { SessionService } from '../../../shared/service/session.service';

import 'rxjs/add/operator/do';
import { Sensor } from '../model/sensor.model';
import { SensorService } from '../service/sensor.service';

@Injectable()
export class SensorResolver implements Resolve<Sensor> {

  constructor(private logger: NGXLogger, private sessionService: SessionService, private sensorDataService: SensorService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Sensor> {
    this.logger.debug('SensorResolver: Waiting for sensor data to be available.');

    const sensorId = this.getSensorId(route);
    if (this.isCurrentSensorIdEqualsToSensorId(sensorId)) {
      return Observable.of(this.sessionService.getCurrentSensor());
    }

    return this.sensorDataService.getSensor(sensorId).do((sensor) => {
        this.sessionService.updateSharedSensor(sensor);

        return sensor;
      }
    );
  }

  private getSensorId(route: ActivatedRouteSnapshot): string {
    if (route.params['id']) {
      return route.params['id'];
    } else {
      return route.parent.params['id'];
    }
  }

  private isCurrentSensorIdEqualsToSensorId(sensorId: string): boolean {
    return this.sessionService.getCurrentSensor() &&
      this.sessionService.getCurrentSensor().id === sensorId;
  }
}
