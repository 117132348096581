import { GenericMetaData } from './generic-meta-data';

export interface RwsMetaDataInterface extends GenericMetaData {
  locationNamePoR: string;
  locationCodeRWS: string;
  items: RWSMetaDataItem[];

  getDate(): Date;
}

export interface RWSMetaDataItem {
  propertyCodePoR: string;
  parameterCodeRWS: string;
  status: string;
  lastUpdate: string;
  lastImport: string;
}

export class RwsMetaData implements RwsMetaDataInterface {

  public static readonly STATUS_SUCCEEDED = 'Succeeded';
  public static readonly STATUS_FAILED = 'Failed';
  public static readonly SOURCE = 'RWS';

  locationNamePoR: string;
  locationCodeRWS: string;
  source: string;
  parameters: number;
  status: string;
  lastUpdate: string;
  lastUpdateIso: string;
  lastImport: string;
  items: RWSMetaDataItem[];

  constructor(rwsMetaDataInterface: RwsMetaDataInterface) {
    this.locationNamePoR = rwsMetaDataInterface.locationNamePoR;
    this.locationCodeRWS = rwsMetaDataInterface.locationCodeRWS;
    this.source = RwsMetaData.SOURCE;
    this.parameters = rwsMetaDataInterface.items.length;
    this.items = rwsMetaDataInterface.items;

    let count = 0;
    for (const item of rwsMetaDataInterface.items) {
      if (item.status === RwsMetaData.STATUS_SUCCEEDED) {
        count++;
      }
    }

    this.status = RwsMetaData.STATUS_FAILED;

    if (count === this.items.length) {
      this.status = RwsMetaData.STATUS_SUCCEEDED;
    }

    if (!this.items) {
      this.lastUpdate = null;
      this.lastImport = null;
    } else {
      const lastUpdateArray: Date[] = [];
      const lastImportArray: Date[] = [];

      this.items.forEach((item) => {

        const adjustedLastUpdate = this.adjustForTimezone(this.stringToDate(item.lastUpdate));
        const adjustedLastImport = this.adjustForTimezone(this.stringToDate(item.lastImport));

        lastUpdateArray.push(adjustedLastUpdate);
        lastImportArray.push(adjustedLastImport);
      });

      this.lastUpdate = new Date(Math.max.apply(null, lastUpdateArray)).toLocaleString();
      this.lastUpdateIso = new Date(Math.max.apply(null, lastUpdateArray)).toISOString();
      this.lastImport = new Date(Math.max.apply(null, lastImportArray)).toLocaleString();
    }
  }

  adjustForTimezone(date: Date): Date {
    const timeOffsetInMS: number = date.getTimezoneOffset() * 60000;
    date.setTime(date.getTime() - timeOffsetInMS);

    return date;
  }

  stringToDate(dateAsString: string): Date {
    const parsedDateTime = dateAsString.split(' ');
    const parsedDate = parsedDateTime[0].split('/');
    const parsedTime = parsedDateTime[1].split(':');

    const year = parseInt(parsedDate[2], 10);
    const month = parseInt(parsedDate[0], 10) - 1;
    const day = parseInt(parsedDate[1], 10);
    const hours = parseInt(parsedTime[0], 10);
    const minutes = parseInt(parsedTime[1], 10);
    const seconds = parseInt(parsedTime[2], 10);

    return new Date(year, month, day, hours, minutes, seconds);
  }

  public getDate(): Date {
    return new Date(this.lastUpdateIso);
  }
}
