import { Observation } from '../../../../../../../observation/model/observation.model';

export class StructuredObservations {
 
  stream: string;
  observations: Observation[];

  constructor(stream: string, observations: Observation[]) {
    this.stream       = stream;
    this.observations = observations;
  }
}
