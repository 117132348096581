export class ObservationChartSerie {

  name: string;
  data: number[];
  pointStart: number;
  linkedTo: string;
  yAxis: number;
  lineWidth: number;
  color: string;
  showInNavigator: boolean;
  enabledInNavigator: boolean;
  turboThreshold: number = 0;
  gapSize: number = 0;
  visible: boolean = true;

  type: string = 'spline';
  tooltip: any = {
    useHTML: true,
    split: true,
    pointFormatter() {
      return this.options.tooltip;
    },
  };
  marker: any = {
    enabled: false,
    symbol: 'circle',
  };

  constructor(name: string, data?: number[], pointStart?: number, color: string = '',
              lineWidth: number = 1, showInNavigator: boolean = true, linkedTo?: string, yAxis?: number, enabledInNavigator: boolean = true, gapSize: number = 0) {

    this.name = name;
    this.data = data;
    this.pointStart = pointStart;
    this.linkedTo = linkedTo;
    this.yAxis = yAxis;
    this.color = color;
    this.lineWidth = lineWidth;
    this.showInNavigator = showInNavigator;
    this.enabledInNavigator = enabledInNavigator;
    this.gapSize = gapSize;

  }
}
