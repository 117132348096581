import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs/Observable';
import { environment } from '../../../../environments/environment';
import { SessionService } from '../../../shared/service/session.service';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private router: Router,
              private route: ActivatedRoute,
              private sessionService: SessionService,
              private logger: NGXLogger) { }

  canActivate(next: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    if (!this.sessionService.isAuthenticated()) {
      this.logger.debug('AuthGuard: User not authenticated for this route.');
      window.location.href = 'https://login.microsoftonline.com/' + environment.activeDirectoryTenantId +
        '/oauth2/authorize?response_type=code&' +
        'client_id=' + environment.activeDirectoryClientId + '&' +
        'resource=' + environment.activeDirectoryResource + '&' +
        'redirect_uri=' + environment.activeDirectoryRedirectUrl;
      return false;
    } else if (!this.isAuthorizedForRoute(next)) {
      this.logger.debug('AuthGuard: User not authorized for this route.');
      this.router.navigate(['/403']);
      return false;
    } else {
      this.logger.debug('AuthGuard: User authenticated for this route.');
      return true;
    }

  }

  isAuthorizedForRoute(next): boolean {

    const tempRights = [];
    let routeRights = [];
    let routeResource = '';

    if (!this.sessionService.getCurrentUser().roles ||
      this.sessionService.getCurrentUser().roles.length === 0) {
      this.router.navigate(['/403']);
      return false;
    } else {

      if (!next.children[0]) {
        routeRights = next.data['rights'];
        routeResource = next.data['resource'];
      } else {
        routeRights = next.children[0].data['rights'];
        routeResource = next.children[0].data['resource'];
      }

      let canRoute = true;

      // Walk trough the user rights.
      this.sessionService.getCurrentUser().roles.forEach((userRole) => {
        // Walk trough the rights of the user per role
        userRole.resourceRights.forEach((userRoleRight) => {
          // If the user has any rights on the resource of the route
          if (userRoleRight.resource.url === routeResource) {
            for (const routeRight of routeRights) {
              for (const userRight of userRoleRight.rights) {
                if (userRight.type === routeRight) {
                  if (tempRights.indexOf(userRight.type) === -1) {
                    tempRights.push(routeRight);
                  }
                }
              }
            }
          }
        });
      });

      if (tempRights.length < 1) {
        canRoute = false;
      }

      return canRoute;
    }
  }
}
