import { ElementRef, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { loadModules } from 'esri-loader';
import { environment } from '../../../../environments/environment';
import { LoaderOptions, sensorAction } from '../config/map-properties';
import { logger } from 'codelyzer/util/logger';

@Injectable()
export class EsriMapService {

  constructor() { }

  loadMap(router: Router, mapContainer: ElementRef) {
    loadModules([
      'esri/identity/IdentityManager',
      'esri/views/MapView',
      'esri/config',
      'esri/WebMap',
      'esri/portal/Portal',
      'esri/identity/OAuthInfo',
      'esri/PopupTemplate',
      'esri/widgets/Legend',
      'dojo/date/locale',
    ], LoaderOptions).then(([IdentityManager, MapView, Config, WebMap,
               Portal, OAuthInfo, PopupTemplate, Legend, locale]) => {

      const info = new OAuthInfo({
        appId: '84gy7sPkZZcCxxXm',
        popup: false,
        portalUrl: 'https://portofrotterdam.maps.arcgis.com',
      });
      IdentityManager.registerOAuthInfos([info]);

      const webmap = new WebMap({
        portalItem: {
          id: environment.mapPortalId,
        },
      });

      const mapViewer: any = new MapView({
        container: 'mapRef',
        map: webmap,
      });

      const pTemplate = new PopupTemplate({
        title: 'Sensor name: {SENSOR_FUNCTIONAL_NAME}',
        content:
        '<table style="width:90%">' +
        '<tr><th>Phenomenon Time: </th>       <th><b>{PHENOMENON_TIME:DateFormat(datePattern: "dd MMM yyyy", timePattern: "HH:mm:ss")}</b></th></tr>' +
        '<tr><th>Sensor Latest value: </th>   <th><b>{VALUE}{UOM_NAME} {DATUM_NAME}</b></th></tr>' +
        '<tr><th>Sensor Active: </th>         <th><b>{SENSOR_ACTIVE}</b></th></tr>' +
        '<tr><th>Sensor Publishing: </th>         <th><b>{SENSOR_PUBLISHING}</b></th></tr>' +
        '<tr><th>Connectivity Issue: </th>   <th><b>{CONNECTIVITY_ISSUE}</b></th></tr>' +
        '<tr><th>Feature Description: </th>   <th><b>{FEATURE_DESCRIPTION}</b></th></tr>' +
        '<tr><th>Property Description: </th>  <th><b>{PROPERTY_DESCRIPTION}</b></th></tr>' +
        '<tr style="visibility: hidden"><th></th> <th><b>{SENSOR_ID}</b></th></tr>',
        actions: [sensorAction],
      });

      mapViewer.on('layerview-create', () => {
        mapViewer.map.layers.items[0].popupTemplate = pTemplate;
      });

      const legend = new Legend({
        view: mapViewer,
        layer: webmap,
      });

      mapViewer.ui.add(legend, 'bottom-left');

      mapViewer.popup.on('trigger-action', (event) => {
        if (event.action.id === 'sensor-detail') {
          const sensorId = mapViewer.popup.selectedFeature.attributes.SENSOR_ID;
          router.navigate(['/app/sensors/detail/' + sensorId + '/stream']);
        }
      });

    }).catch((err) => {
      logger.error(err);
    });
  }
}
