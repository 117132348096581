import { WindowUtils } from '../../../../../shared/utils/window-utils';
import {
  navigationTextProperties, pageProperties, stepCompletionChecksProperties,
  stepperTextProperties,
} from '../general/form-properties';

interface ICompletionCheck {
  step_id: number;
  completed: boolean;
  nodeVisited: boolean;
}

export class PageController {
  public pageTitle: string;
  public navigationTextForward: string;
  public navigationTextBackward: string;
  public navigationTextBackToSensor: string;
  public stepCompletionChecks: ICompletionCheck[];
  public stepperTexts: any[];
  public currentStep: number;

  constructor() {
    this.pageTitle                  = pageProperties.title;
    this.navigationTextForward      = navigationTextProperties.nextStep;
    this.navigationTextBackward     = navigationTextProperties.previousStep;
    this.navigationTextBackToSensor = navigationTextProperties.backToSensor;
    this.stepCompletionChecks       = stepCompletionChecksProperties;
    this.stepperTexts               = stepperTextProperties;
    this.currentStep                = 0;
    this.clearStepper();
  }

  public static goToTop() {
    WindowUtils.scrollIntoView('add-sensor-page');
  }

  setCurrentStep(step: number): void {
    this.currentStep = step;
  }

  setNavigationTextForward(step: number): void {
    if (step === 3) {
      this.navigationTextForward = navigationTextProperties.addSensor;
    } else {
      this.navigationTextForward = navigationTextProperties.nextStep;
    }
  }

  isNodeVisitedAndNotCompleted(node: number): boolean {
    const check = this.stepCompletionChecks[node];
    return check.nodeVisited && !check.completed;
  }

  setStepVisited(step: number): void {
    this.stepCompletionChecks[step].nodeVisited = true;
  }

  setStepCompleted(step: number): void {
    this.stepCompletionChecks[step].completed = true;
  }

  setStepIncomplete(step: number): void {
    this.stepCompletionChecks[step].completed = false;
  }

  clearStepper(): void {
    this.stepCompletionChecks.forEach((check, index) => {
      check.nodeVisited = (index === 0);
      check.completed   = false;
    });
  }
}
