import { TableProperties } from '../../../../shared/ui/table/model/table-properties.model';

export const SensorOverviewTableProperties: TableProperties[] = [
  {
    header: 'Status',
    accessor: {main: 'status'},
    containsDate: false,
  },
  {
    header: 'Sensor Name',
    accessor: {main: 'name'},
    containsDate: false,
  },
  {
    header: 'Sensor Location Name',
    accessor: {
      main: 'locationName',
    },
    containsDate: false,
  },
  {
    header: 'Sensor type',
    accessor: {
      main: 'sensorTypeDescription',
    },
    containsDate: false,
  },
  {
    header: 'Start date',
    accessor: {main: 'startDate'},
    containsDate: true,
  },
  {
    header: 'End date',
    accessor: {main: 'endDate'},
    containsDate: true,
  },
  {
    header: 'Sensor Device ID',
    accessor: {main: 'deviceId'},
    containsDate: false,
  },
];
