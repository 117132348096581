export class ObservationUpdateInfo {

  stream: string;
  amount: number;

  constructor(stream: string, amount: number) {
    this.stream = stream;
    this.amount = amount;
  }
}
