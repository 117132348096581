export interface DeviceInterface {
  deviceId: string;
  dateAdded: string;
  location: string;
  gatewayId: string;
  gatewayTypeId: string;
  deviceType: string;
}

export class Device {
  public static readonly TEXT_SELECT: string   = 'SELECT';
  public static readonly TEXT_SELECTED: string = 'SELECTED';

  constructor(public deviceId: string = null,
              public dateAdded: string = null,
              public location: string = null,
              public gatewayId: string = null,
              public gatewayTypeId: string = null,
              public deviceType: string = null,
              public selected: boolean = false,
              public text: string = Device.TEXT_SELECT) {
  }

  public isSelected(): boolean {
    return this.selected;
  }

  public getText(): string {
    return this.text;
  }

  public changeSelectedToTrue(): void {
    this.selected = true;
    this.text     = Device.TEXT_SELECTED;
  }

  public changeSelectedToFalse(): void {
    this.selected = false;
    this.text     = Device.TEXT_SELECT;
  }
}
