import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { ResourceRightsService } from '../../components/authorization/service/resource-rights.service';
import { Sensor } from '../../components/sensor/model/sensor.model';
import { Feature } from '../../components/sensor/model/feature.model';
import { User } from '../../components/user/model/user.model';
import * as jwt from 'jwt-decode';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Observable } from 'rxjs/Observable';

@Injectable()
export class SessionService {

  private currentUser: User;
  private accessToken: string;
  // shared Sensor
  private behaviorSubjectSensor: BehaviorSubject<Sensor> = new BehaviorSubject<Sensor>(null);
  public sharedSensor: Observable<Sensor> = this.behaviorSubjectSensor.asObservable();
  // shared features
  private behaviorSubjectFeatures: BehaviorSubject<Feature[]> = new BehaviorSubject<Feature[]>([]);
  public sharedFeatures: Observable<Feature[]> = this.behaviorSubjectFeatures.asObservable();

  constructor(private logger: NGXLogger,
              private resourceRightsService: ResourceRightsService) {
    this.logger.debug('constructing SessionService.');
  }

  public init(): boolean {
    this.logger.debug('SessionService: initializing SessionService.');
    return true;
  }

  public updateSharedSensor(sensor: Sensor): void {
    this.logger.debug('[session-service] update shared sensor');
    this.behaviorSubjectSensor.next(sensor);
  }

  /* istanbul ignore next: not unit testing basic getters and setters */
  public getCurrentSensor(): Sensor {
    this.logger.debug('SessionService: retrieving current sensor.');
    return this.behaviorSubjectSensor.getValue();
  }

  public updateSharedFeatures(features: Feature[]): void {
    this.logger.debug('[session-service] update shared features');
    this.behaviorSubjectFeatures.next(features);
  }

  public getCurrentFeatures(): Feature[] {
    this.logger.debug('[SessionService]: retrieving current features.');
    return this.behaviorSubjectFeatures.getValue();
  }

  public getCurrentUser(): User {
    this.logger.debug('SessionService: getting current user.');
    return this.currentUser;
  }

  public setCurrentUser(user: User): void {
    this.logger.debug('SessionService: setting current user.');
    this.currentUser = user;
  }

  public setCurrentUserRoles(): void {
    this.logger.debug('SessionService: setting current user roles.');
    this.resourceRightsService.getRoles(this.currentUser.roles).subscribe((roles) => {
      this.logger.debug('SessionService: user roles set. ');
      this.currentUser.roles = roles;
    });
  }

  /**
   * Check if the current user is authorized to naviagte to a url. This method
   * is primarly used in the AuthGuard.
   */
  public isAuthenticated(): boolean {
    this.logger.debug('SessionService: checking if the user is authenticated.');
    if (this.currentUser) {

      return true;

    } else if ((this.accessToken && this.accessToken !== '') || (sessionStorage.getItem('access_token') && sessionStorage.getItem('access_token') !== '')) {

      this.accessToken = sessionStorage.getItem('access_token');
      this.setCurrentUserFromToken(jwt(sessionStorage.getItem('access_token')));

      return true;

    } else {

      return false;
    }
  }

  public setCurrentUserFromToken(decodedToken: object): boolean {
    this.logger.debug('SessionService: setting current user from access token.');

    this.currentUser = new User(decodedToken['email'], decodedToken['unique_name'], decodedToken['roles']);

    // TODO: can be done better, but ADFS returns no array if there is only 1 role.
    if (!(decodedToken['roles'] instanceof Array) && decodedToken['roles']) {
      const role = this.currentUser.roles;
      this.currentUser.roles = [];
      this.currentUser.roles.push(role);
    }

    this.setCurrentUserRoles();

    return true;
  }

  public getAccessToken(): string {
    if (this.accessToken) {
      return this.accessToken;
    } else {
      // If its not in memory, get it from storage and set it in memory
      this.setAccessTokenInMemory(sessionStorage.getItem('access_token'));
      return this.accessToken;
    }
  }

  public setAccessTokenInStorage(accessToken: string): void {
    this.logger.debug('SessionService: setting access token in storage');

    // set the token in the localStorage
    // Session Storage is sandboxed and not shared between domains.
    // We assume that the browser is safe enough because browser policies
    // restrict access to that storage
    sessionStorage.setItem('access_token', accessToken);
  }

  public setAccessTokenInMemory(accessToken: string): void {
    this.logger.debug('SessionService: setting access token in memory');

    // set it in the app's memory
    this.accessToken = accessToken;
  }
}
