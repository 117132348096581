import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/mapTo';
import 'rxjs/add/operator/takeUntil';
import { Sensor } from '../../model/sensor.model';
import { SensorService } from '../../service/sensor.service';
import { SensorModalInterface, sensorModalValues } from './properties/sensor-modal-properties';
import { Device } from '../../../../shared/iot-platform/model/iot-device';
import { UpdateSensorDto } from '../../model/update-sensor-dto.model';
import { FormBuilder, FormGroup } from '@angular/forms';
import { UpdateSensorFormConfig } from './properties/update-sensor-form';
import { UpdateSensorFormController } from './properties/update-sensor-form-controller';
import { ValidatorMessage } from '../../create/properties/validator/validator-enum';
import { UpdateSensorDtoFactory } from '../../factory/update-sensor-dto.factory';
import { SessionService } from '../../../../shared/service/session.service';
import { Feature } from '../../model/feature.model';
import { UpdateLocationComponent } from './update-location/update-location.component';
import { Subject } from 'rxjs/Subject';
import { AuthorizationComponent } from '../../../authorization/authorization.component';

@Component({
  selector: 'app-sensor-detail-info',
  templateUrl: './sensor-detail-info.component.html',
  styleUrls: ['./sensor-detail-info.component.scss'],
})
export class SensorDetailInfoComponent extends AuthorizationComponent implements OnInit, OnDestroy {
  public sensorModalValues: SensorModalInterface[] = sensorModalValues;

  public sensor: Sensor;
  public device: Device;
  public features: Feature[];

  public isEditSensor: boolean = false;
  public saving: boolean = false;

  public updateSensorForm: FormGroup;
  public updateSensorFormController: UpdateSensorFormController;

  public validatorMessage = ValidatorMessage;
  public updateSensorDtoFactory: UpdateSensorDtoFactory = new UpdateSensorDtoFactory();

  private observableGuard = new Subject<void>();

  @ViewChild('locationModal')
  public locationModal: UpdateLocationComponent;

  constructor(private formBuilder: FormBuilder,
              private route: ActivatedRoute,
              private router: Router,
              private sensorService: SensorService,
              sessionService: SessionService
  ) {
    super(sessionService, '/sensors', ['readOne', 'update']);
    this.sessionService.sharedSensor
      .takeUntil(this.observableGuard)
      .subscribe((sensor: Sensor) => {
        this.sensor = sensor;
      });
    this.sessionService.sharedFeatures
      .takeUntil(this.observableGuard)
      .subscribe((features: Feature[]) => {
        this.features = features;
      });
  }

  public ngOnInit(): void {
    this.device = this.route.snapshot.data['device'];
    this.updateSensorForm = this.formBuilder.group(UpdateSensorFormConfig);
    this.updateSensorFormController = new UpdateSensorFormController(this.updateSensorForm);
    this.updateSensorFormController.resetUpdateSensorForm(this.sensor);
  }

  public ngOnDestroy(): void {
    this.observableGuard.next();
    this.observableGuard.complete();
  }

  public onEditClick(): void {
    this.updateSensorFormController.resetUpdateSensorForm(this.sensor);
    this.isEditSensor = true;
  }

  public onCancelClick(): void {
    this.isEditSensor = false;
  }

  public onSubmitSensorChanges(): void {
    this.submitChanges();
    this.isEditSensor = false;
  }

  public submitChanges(): void {
    const updateSensorDto: UpdateSensorDto = this.updateSensorDtoFactory.create(
      this.updateSensorFormController.startDateISOString,
      this.updateSensorFormController.endDateISOString,
      this.updateSensorFormController.instrumentType,
      this.updateSensorFormController.locationName,
      this.updateSensorFormController.xcoordinate,
      this.updateSensorFormController.ycoordinate,
      this.updateSensorFormController.zcoordinate
    );

    this.saving = true;

    this.sensorService.updateSensor(updateSensorDto, this.sensor.id).subscribe(() => {
      this.sensorService.getSensor(this.sensor.id).subscribe((sensor: Sensor) => {
        this.sessionService.updateSharedSensor(sensor);
      });
      this.hideModalAndStopSaving();
    }, () => {
      this.hideModalAndStopSaving();
    });
  }

  private hideModalAndStopSaving() {
    this.locationModal.hide();
    this.saving = false;
  }
}
