import { PageController } from './page-controller';
import { AddNewSensorFormController } from './sensor-form-controller';
import { Device } from '../../../../../shared/iot-platform/model/iot-device';

export class DeviceController {
  public controller: AddNewSensorFormController;
  public devices: Device[];
  public selectedDevice: Device;

  constructor(controller: AddNewSensorFormController, devices: Device[]) {
    this.controller = controller;
    this.devices = devices;
    this.selectedDevice = null;
  }

  public selectOtherDevice(deviceIndex: number): void {
    const newSelectedDevice: Device = this.devices[deviceIndex];
    this.selectedDevice.changeSelectedToFalse();
    this.selectedDevice = newSelectedDevice;
    this.selectedDevice.changeSelectedToTrue();
    this.controller.deviceControl.setValue(this.selectedDevice);

    PageController.goToTop();
  }

  public getDevices(): Device[] {
    return this.devices;
  }

  public deselectDevice(): void {
    if (this.selectedDevice) {
      this.selectedDevice.changeSelectedToFalse();
      this.selectedDevice = null;
      this.controller.deviceControl.setValue(null);
    }
  }

  public selectDevice(event, deviceIndex: number): void {
    const newSelectedDevice: Device = this.devices[deviceIndex];
    if (typeof this.selectedDevice === 'undefined' || this.selectedDevice === null) {
      // case 0: no getDeviceAbstractControl was selected (select the new getDeviceAbstractControl)

      this.selectedDevice = newSelectedDevice;
      this.selectedDevice.changeSelectedToTrue();

      this.controller.deviceControl.setValue(this.selectedDevice);
      PageController.goToTop();
    } else if (newSelectedDevice === this.selectedDevice) {
      // case 1: same getDeviceAbstractControl has been selected (deselect the current getDeviceAbstractControl)
      this.deselectDevice();
    } else {
      this.selectOtherDevice(deviceIndex);
    }
  }
}
