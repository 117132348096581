import { SessionService } from '../../../shared/service/session.service';
import { PermissionBasedEntity } from '../../authorization/model/permission-based-entity.model';

export class SidebarItem extends PermissionBasedEntity {
  
  public label: string;
  public url: string;
  public icon: string;
  public subItems: SidebarItem[];

  constructor(sessionService: SessionService, label: string = 'unkown', url: string = '', icon: string = 'circle',
              resource: string, rights: string[], subItems?: SidebarItem[]) {

    super(sessionService, resource, rights);
    this.determineVisibility();

    this.label = label;
    this.url = url;
    this.icon = icon;
    this.subItems = subItems;
  }

}
