import {
  ChangeDetectionStrategy, Component
} from '@angular/core';
import { TableComponent } from '../../../../shared/ui/table/table.component';

@Component({
  selector:    'app-sensor-overview-table',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './sensor-overview-table.component.html',
  styleUrls: ['./sensor-overview-table.component.scss'],
})
export class SensorOverviewTableComponent extends TableComponent {

}
