import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { Feature } from '../../model/feature.model';
import { SensorLogInterface } from '../../model/sensor-log.model';

@Injectable()
export class SensorDetailStreamUpdateLogsNotificationService {
  private dataObs$ = new Subject<SensorLogInterface[]>();
  private feature$: Feature;

  getData() {
    return this.dataObs$;
  }

  updateData(data: SensorLogInterface[]) {
    this.dataObs$.next(data);
  }

  setFeature(feature: Feature) {
    this.feature$ = feature;
  }

  getFeature(): Feature {
    return this.feature$;
  }
}
