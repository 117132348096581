import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs/Observable';
import { environment } from '../../../environments/environment';
import { ModalStatus, StatusModalInfo } from '../../shared/modal/status-modal-info';
import { StatusModalService } from '../../shared/modal/status-modal.service';

/**
 * This class does HTTP calls seperatly because this class does not acces
 * the 'real' API. It makes it possible to retrieve a auth code from ADFS and
 * request a token
 */

@Injectable()
export class AuthenticationService {

  constructor(private logger: NGXLogger,
              private http: HttpClient,
              private statusModalService: StatusModalService) {
    this.logger.debug('constructing AuthService.');
  }

  /* istanbul ignore next: cannot unit test adfs redirecting */
  /**
   * redirect the user to the adfs login page.
   */
  public login(): void {
    window.location.href = 'https://login.microsoftonline.com/' + environment.activeDirectoryTenantId +
      '/oauth2/authorize?response_type=code&' +
      'client_id=' + environment.activeDirectoryClientId + '&' +
      'resource=' + environment.activeDirectoryResource + '&' +
      'redirect_uri=' + environment.activeDirectoryRedirectUrl;
  }

  /**
   * This function sets up and retrieves the token by an adfs call
   *
   * @param code the auth code retrieved from ADFS
   */
  public createTokenRequestData(code: string): string {
    return 'grant_type=authorization_code&' +
      'client_id=' + environment.activeDirectoryClientId + '&' +
      'redirect_uri=' + environment.activeDirectoryRedirectUrl + '&' +
      'resource=' + environment.activeDirectoryResource + '&' +
      'code=' + code;
  }

  /* istanbul ignore next: not unit testing http calls (same as in ApiDataService) */
  /**
   * This function is the actual token retrieval function.
   *
   * @param data parameters for retrieving the code.
   */
  public getToken(data: string): Observable<any> {
    this.statusModalService.changeModalVisibility(new StatusModalInfo(ModalStatus.LOADING, ''));
    return this.http.post(environment.apiAuthUrl, data).map((tokenData) => {
      return tokenData;
    }).catch((error) => {
      this.logger.debug(error);
      return error;
    }).finally(() => {
      this.statusModalService.changeModalVisibility(new StatusModalInfo(ModalStatus.CLOSE, ''));
    });
  }
}
