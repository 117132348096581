export enum SensorState {
  OFF = 'Sensor off',
  ON = 'Sensor on',
  PUBLISHING = 'PUBLISHING',
  NOT_PUBLISHING = 'NOT PUBLISHING',
  CREATED = 'CREATED',
  OK = 'ok',
  ERROR = 'error',
  WARNING = 'warn',

}

export enum SensorCommand {
  START = 'start',
  STOP = 'stop',
}
