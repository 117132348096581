import { IMyDate, IMyDateModel } from 'mydatepicker';

export class MyDatePickerMapper {

  public toIMyDateModel(date: Date): IMyDateModel {
    return {
      date: this.toIMyDate(date),
      jsdate: date,
      formatted: date.toLocaleDateString('en-GB'),
      epoc: date.valueOf() / 1000,
    };
  }

  public toIMyDate(date: Date): IMyDate {
    return {
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      day: date.getDate(),
    };
  }
}
