import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs/Observable';
import { SessionService } from '../../../shared/service/session.service';

import 'rxjs/add/operator/do';
import { SensorLogInterface } from '../model/sensor-log.model';
import { SensorService } from '../service/sensor.service';

@Injectable()
export class SensorLogResolver implements Resolve<SensorLogInterface> {

  constructor(private logger: NGXLogger,
              private sessionService: SessionService,
              private sensorDataService: SensorService,
              private router: Router) {
    this.logger.debug('constructing SensorLog resolver.');
  }

  resolve(route: ActivatedRouteSnapshot,
          state: RouterStateSnapshot): Observable<SensorLogInterface> {
    this.logger.debug(
      'SensorLogResolver: Waiting for sensor data to be available.');

    return this.sensorDataService.getAllSensorLogs(this.sessionService.getCurrentSensor().id).catch((error) => {
      if (error === 404) {
        this.router.navigate(['404']);
      } else {
        this.router.navigate(['error']);
      }
      return Observable.of(null);
    });
  }
}
