import { UpdateSensorDto } from '../model/update-sensor-dto.model';

export class UpdateSensorDtoFactory {
  public create(startDate: string,
                endDate: string,
                instrumentType: string,
                locationName: string,
                xcoordinate: number,
                ycoordinate: number,
                zcoordinate: number = null): UpdateSensorDto {

    return new UpdateSensorDto(
      startDate,
      endDate,
      instrumentType,
      locationName,
      xcoordinate,
      ycoordinate,
      zcoordinate
    );
  }
}
