import { Component } from '@angular/core';
import { environment } from '../../../environments/environment';
import { SessionService } from '../../shared/service/session.service';
import { SidebarItem } from './model/sidebar-item.model';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent {

  public collapsed: boolean = false;
  public menuItems: SidebarItem[];

  constructor(private sessionService: SessionService) {
    this.initMenuItems();
  }

  public collapseSidebar() {
    this.collapsed = !this.collapsed;
  }

  private initMenuItems() {

    this.menuItems = [];

    this.menuItems.push(
      new SidebarItem(this.sessionService, 'SENSORS', '/app/sensors', 'fa-microchip', '/sensors', ['readAll']),
      new SidebarItem(this.sessionService, 'MAP', '/external/' + encodeURIComponent(environment.naiadeMapUrl), 'fa-map', '/map', ['readAll']),
      new SidebarItem(this.sessionService, 'EXTERNAL INTERFACES', '', 'fa-external-link-square', '/external-interfaces', ['readAll'], [
        new SidebarItem(this.sessionService, 'Imports', '/app/external-interface/import/rws', 'fa-map', '/imports', ['readAll']),
        new SidebarItem(this.sessionService, 'Uploads', '/app/external-interface/upload', 'fa-map', '/upload/astro', ['readAll']),
      ]),
  );
  }
}
