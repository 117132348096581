
export class User {
  email?: string;
  unique_name: string;
  roles: any[]; // todo: create class roles?

  constructor(email: string = '', unique_name: string, roles: any[] = []) {
    this.email = email;
    this.unique_name = unique_name;
    this.roles = roles;
  }
}
