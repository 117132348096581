export class ObjectUtils {
  /*
  * isInstantiated check if an object has been instantiated.
  *
  * params:
  *   - obj: any object to check.
  * return:
  *   - true: obj is not undefined and not null;
  *   - false: if otherwise
  * */
  public static isInstantiated(obj: any) {
    return typeof obj !== 'undefined' && obj !== null;
  }
}
