import { MeasurementLocation } from '../model/measurement-location';

export class AddCommand {

  public sensorTypeId: string;
  public deviceId: string;
  public dateAdded: string;
  public gatewayId: string;
  public gatewayType: string;
  public name: string;
  public startDate: string;
  public endDate: string;
  public instrumentType: string;
  public locationName: string;
  public xcoordinate: number;
  public ycoordinate: number;
  public zcoordinate: number;
  public measurementLocations: MeasurementLocation[];

  constructor() {
    this.dateAdded = '';
    this.name = '';
    this.startDate = '';
    this.endDate = '';
    this.instrumentType = '';
    this.locationName = '';
    this.xcoordinate = 0;
    this.ycoordinate = 0;
    this.zcoordinate = 0;
    this.measurementLocations = [];
  }
}
