import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {TableComponent} from './table.component';

@NgModule({
  imports:      [
    CommonModule,
  ],
  declarations: [TableComponent],
  providers:    [],
  exports:      [
    TableComponent,
  ],
})

export class TableModule {

}
