export const pageProperties = {
  title: 'ADD SENSOR',
};

export const navigationTextProperties = {
  nextStep:     'NEXT STEP',
  previousStep: 'PREVIOUS STEP',
  backToSensor: 'BACK TO SENSOR',
  addSensor:    'ADD SENSOR',
};

export const routeProperties = {
  sensorsOverviewPage: 'app/sensors',
};

export const stepperTextProperties = [
  {id: 1, text: 'TYPE AND NAME'},
  {id: 2, text: 'DEVICE'},
  {id: 3, text: 'GENERAL INFORMATION'},
  {id: 4, text: 'SUMMARY'},
];

export const stepCompletionChecksProperties = [
  {step_id: 1, completed: false, nodeVisited: false},
  {step_id: 2, completed: false, nodeVisited: false},
  {step_id: 3, completed: false, nodeVisited: false},
  {step_id: 4, completed: false, nodeVisited: false},
];
