import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';

import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';

import { SensorService } from '../../../service/sensor.service';
import { Sensor } from '../../../model/sensor.model';
import { SessionService } from '../../../../../shared/service/session.service';
import { Property } from '../../../../property/model/property.model';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/switchMap';

@Injectable()
export class SensorStreamPropertiesResolver implements Resolve<Property[]> {

  constructor(private sensorDataService: SensorService,
              private sessionService: SessionService,
              private logger: NGXLogger) {
    this.logger.debug('constructing properties resolver.');
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Property[]> {

    const sensorId = route.parent.params['id'];
    const sensor: Sensor = this.sessionService.getCurrentSensor();

    // if the sensor is stored in the current session, use that one
    if (sensor) {
      return this.getStreamProperties(sensor);
    } else {
      // if the sensor is not yet set, retrieve it from the api again.
      return this.sensorDataService.getSensor(sensorId)
        .switchMap((sensorSub: Sensor) => this.getStreamProperties(sensorSub));
    }
  }

  /**
   * retrieve the observations per sensor.
   * It forkjoins the observables so it will wait till all requests are finished.
   *
   * @param sensor sensor to retrieve observation from.
   */
  private getStreamProperties(sensor: Sensor): Observable<Property[]> {

    const properties: Property[] = [];

    if (sensor.processes) {
      for (const process of sensor.processes) {
        for (const datastream of process.datastreams) {
          // for every datastream, retrieve the properties.
          properties.push(datastream.property);
        }
      }
    }
    return Observable.of(properties);
  }
}
