import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from '@angular/router';
import {NGXLogger} from 'ngx-logger';
import {Observable} from 'rxjs/Observable';

import 'rxjs/add/operator/do';
import {Activity} from '../model/activity.model';
import {ActivityService} from '../service/activity.service';

@Injectable()
export class ActivityResolver implements Resolve<Activity[]> {

  constructor(private logger: NGXLogger,
              private activityDataService: ActivityService,
              private router: Router) {
    this.logger.debug('constructing feature resolver.');
  }

  resolve(route: ActivatedRouteSnapshot,
          state: RouterStateSnapshot): Observable<Activity[]> {

    this.logger.debug(
      'SensorResolver: Waiting for activity data to be available.');
    return this.activityDataService.getAllActivities().do(
      (activity) => {
        return activity;
      },
      (error) => {
        if (error === 404) {
          this.router.navigate(['404']);
        } else {
          this.router.navigate(['error']);
        }
      }
    );
  }
}
