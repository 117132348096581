import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as Fuse from 'fuse.js';
import 'rxjs/add/operator/mergeMap';
import { SessionService } from '../../../shared/service/session.service';
import { AuthorizationComponent } from '../../authorization/authorization.component';
import { Sensor } from '../model/sensor.model';
import { searchOption } from './properties/search-sensor-propeties';
import { SensorSummary } from './model/sensor-overview.model';
import { SensorOverviewTableProperties } from './properties/SensorOverviewTableProperties';
import { TableProperties } from '../../../shared/ui/table/model/table-properties.model';

@Component({
  selector: 'app-sensor-overview',
  templateUrl: './sensor-overview.component.html',
  styleUrls: ['./sensor-overview.component.scss'],
})
export class SensorOverviewComponent extends AuthorizationComponent {

  public sensors: SensorSummary[];

  public searchTerm: string;
  public tableProperties: TableProperties[] = SensorOverviewTableProperties;

  public listSensors: SensorSummary[] = [];
  public displayedSensors: SensorSummary[] = [];
  public filteredSensors: SensorSummary[] = [];
  public steps: number[] = [10, 20, 50, 100];
  public pages: number[] = [];

  public step: number;
  public page: number;
  public maxPage: number;

  public isPreviousButtonDisabled: boolean;
  public isNextButtonDisabled: boolean;

  constructor(sessionService: SessionService, private route: ActivatedRoute, private router: Router) {
    super(sessionService, '/sensors', ['readAll', 'readOne', 'create']);

    this.sensors = this.route.snapshot.data['sensorSummaries'];
    this.listSensors = this.sensors;
    this.step = this.steps[2];
    this.page = 0;

    this.filterSensors();
    this.updateSensorsArray(this.step, this.page);
  }

  public updateSensorsArray(step: number, page: number): void {
    const currentSensors: SensorSummary[] = [];
    for (let i = page * step; i < step + page * step; i++) {
      if (this.filteredSensors && this.filteredSensors[i]) {
        currentSensors.push(this.filteredSensors[i]);
      } else {
        break;
      }
    }

    this.displayedSensors = currentSensors;
    this.maxPage = Math.ceil(this.filteredSensors.length / this.step);
    this.pages = [];
    for (let i = 0; i < this.maxPage; i++) {
      this.pages.push(i);
    }

    this.isPreviousButtonDisabled = this.page <= 0;
    this.isNextButtonDisabled = this.page >= this.maxPage - 1;
  }

  public filterSensors() {
    if (this.searchTerm === undefined || this.searchTerm === '') {
      this.filteredSensors = this.sensors;
    } else {
      this.filteredSensors = [];
      for (const summary of this.sensors) {
        if (this.shouldFilterSearchTerm(summary)) {
          this.filteredSensors.push(summary);
        }
      }
    }
  }

  private shouldFilterSearchTerm(summary: SensorSummary): boolean {
    if (this.searchTerm !== undefined) {
      const termToLowerCase = this.searchTerm.toLowerCase();
      if (((summary.name + '').toLowerCase().indexOf(termToLowerCase) > -1) ||
        ((summary.locationName + '').toLowerCase().indexOf(termToLowerCase) > -1)) {
        return true;
      }
    } else {
      return true;
    }

    return false;
  }

  public changeStep(step: number): void {
    this.step = step;
    this.page = 0;
    this.updateSensorsArray(this.step, this.page);
  }

  public changePage(page: number): void {
    this.page = page;
    this.updateSensorsArray(this.step, this.page);
  }

  public goToAddSensor(): Promise<boolean> {
    return this.router.navigate(['/app/sensors/create']);
  }

  public onRowClick(sensor: Sensor): Promise<boolean> {
    return this.router.navigate(['/app/sensors/detail/' + sensor.id + '/info']);
  }
}
