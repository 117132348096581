import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { ModalModule } from '../../shared/modal/modal.module';
import { TableModule } from '../../shared/ui/table/table.module';
import { UiModule } from '../../shared/ui/ui.module';
import { DeviceService } from '../../shared/iot-platform/service/device.service';
import { ObservationModule } from '../observation/observation.module';
import { SensorTypeResolver } from '../sensor-type/resolver/sensor-type.resolver';
import { SensorTypeService } from '../sensor-type/service/sensor-type.service';
import { AddSensorComponent } from './create/create-sensor.component';
import { MeteoChartsModule } from './detail/data/charts/meteo-charts.module';
import { SensorDetailModule } from './detail/sensor-detail.module';
import { SensorOverviewComponent } from './overview/sensor-overview.component';
import { ActivityResolver } from './resolver/activity.resolver';
import { SensorResolver } from './resolver/sensor.resolver';
import { SensorsResolver } from './resolver/sensors.resolver';
import { SensorComponent } from './sensor.component';
import { SensorRoutingModule } from './sensor.routing.module';
import { ActivityService } from './service/activity.service';
import { SensorService } from './service/sensor.service';
import { CreateSensorDataService } from './service/create-sensor-data.service';
import { FeatureTypeService } from './service/feature-type.service';
import { SensorSummaryResolver } from './resolver/sensor-summary.resolver';
import { SensorOverviewTableComponent } from './overview/table/sensor-overview-table.component';

@NgModule({
  imports: [
    UiModule,
    RouterModule,
    SensorRoutingModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    Ng2SearchPipeModule,
    ModalModule,
    ObservationModule,
    MeteoChartsModule,
    SensorDetailModule,
    TableModule,
  ],
  declarations: [
    SensorComponent,
    SensorOverviewComponent,
    SensorOverviewTableComponent,
    AddSensorComponent,
  ],
  providers: [
    SensorService,
    SensorResolver,
    SensorsResolver,
    SensorSummaryResolver,
    DeviceService,
    FeatureTypeService,
    ActivityResolver,
    ActivityService,
    SensorTypeService,
    SensorTypeResolver,
    CreateSensorDataService,
  ],
})
export class SensorModule {
}
