import { GenericMetaData } from './generic-meta-data';

export interface MetaDataInterface extends GenericMetaData {
  name: string;
  status: string;
  dateTime: string;
  message: string;
  fileType: string;
  location: string;
  user: string;
}

export class MetaData implements MetaDataInterface {

  public name: string;
  public status: string;
  public dateTime: string;
  public message: string;
  public fileType: string;
  public location: string;
  public user: string;
  // additional data
  public time: string;

  constructor(metaDataInterface: MetaDataInterface) {
    this.name     = metaDataInterface.name;
    this.status   = metaDataInterface.status;
    this.dateTime = metaDataInterface.dateTime;
    this.message  = metaDataInterface.message;
    this.fileType = metaDataInterface.fileType;
    this.location = metaDataInterface.location;
    this.user     = metaDataInterface.user;
    // dateTime format is: '2018-01-17 15:40:46.0'
    // dateTime will split into 2 strings.
    // date == '2018-01-17'
    // time == '15:40:46'
    const dateSplit: string[] = (metaDataInterface.dateTime.split('.'))[0].split(' ');
    if (dateSplit && dateSplit[1]) {
      this.time = dateSplit[1];
    } else {
      this.time = null;
    }
  }

  public getDate(): Date {
    return new Date(this.dateTime);
  }
}
