import { AbstractControl, FormGroup } from '@angular/forms';
import { myParseFloat } from '../../../create/properties/utility/float-parser';
import { MeasurementLocation } from '../../../../../shared/iot-platform/model/measurement-location';
import { MeasurementLocationFactory } from '../../../factory/measurement-location-factory';
import { IMyDateModel } from 'mydatepicker';
import { MyDatePickerMapper } from '../properties/MyDatePickerMapper';

export class UpdateLocationFormController {

  private myDatePickerMapper: MyDatePickerMapper;

  constructor(private updateLocationForm: FormGroup) {
    this.myDatePickerMapper = new MyDatePickerMapper();
  }

  public get xCoordinateControl(): AbstractControl {
    return this.updateLocationForm.get('x');
  }

  public get xCoordinate(): number {
    return myParseFloat(this.xCoordinateControl.value);
  }

  public get yCoordinateControl(): AbstractControl {
    return this.updateLocationForm.get('y');
  }

  public get yCoordinate(): number {
    return myParseFloat(this.yCoordinateControl.value);
  }

  public get zCoordinateControl(): AbstractControl {
    return this.updateLocationForm.get('z');
  }

  public get zCoordinate(): number {
    return myParseFloat(this.zCoordinateControl.value);
  }

  public get distanceControl(): AbstractControl {
    return this.updateLocationForm.get('distance');
  }

  public get distance(): string {
    return this.distanceControl.value;
  }

  public get napToALatConversionControl(): AbstractControl {
    return this.updateLocationForm.get('napToALatConversion');
  }

  public get napToALatConversion(): string {
    return this.napToALatConversionControl.value;
  }

  public get directionFromControl(): AbstractControl {
    return this.updateLocationForm.get('directionFrom');
  }

  public get directionFrom(): string {
    return this.directionFromControl.value;
  }

  public get directionToControl(): AbstractControl {
    return this.updateLocationForm.get('directionTo');
  }

  public get directionTo(): string {
    return this.directionToControl.value;
  }

  public get descriptionControl(): AbstractControl {
    return this.updateLocationForm.get('description');
  }

  public get description(): string {
    return this.descriptionControl.value;
  }

  public get typeControl(): AbstractControl {
    return this.updateLocationForm.get('type');
  }

  public get type(): string {
    return this.typeControl.value;
  }

  public get nameControl(): AbstractControl {
    return this.updateLocationForm.get('name');
  }

  public get name(): string {
    return this.nameControl.value;
  }

  public get endDateControl(): AbstractControl {
    return this.updateLocationForm.get('endDate');
  }

  public get endDate(): IMyDateModel {
    return this.endDateControl.value;
  }

  public get endDateISOString(): string {
    const date = this.endDate ? this.endDate.jsdate : null;

    return date ? date.toISOString() : '';
  }

  public resetForm(measurementLocation: MeasurementLocation) {
    this.descriptionControl.setValue(measurementLocation.description);
    this.directionFromControl.setValue(measurementLocation.directionFrom);
    this.directionToControl.setValue(measurementLocation.directionTo);
    this.distanceControl.setValue(measurementLocation.distance);
    this.napToALatConversionControl.setValue(measurementLocation.napToALatConversion);
    this.xCoordinateControl.setValue(measurementLocation.x);
    this.yCoordinateControl.setValue(measurementLocation.y);
    this.zCoordinateControl.setValue(measurementLocation.z);
    this.endDateControl.setValue(this.convertDate(measurementLocation.endDate));
    this.typeControl.setValue(measurementLocation.type);
  }

  public getMeasurementLocation(): MeasurementLocation {
    return MeasurementLocationFactory.create(
      this.name,
      this.type,
      this.description,
      this.xCoordinate,
      this.yCoordinate,
      this.zCoordinate,
      (this.endDate === null ? null : this.endDate.jsdate),
      this.distance,
      this.directionFrom,
      this.directionTo,
      this.napToALatConversion
    );
  }

  public convertDate(date: Date): IMyDateModel {
    return date ? this.myDatePickerMapper.toIMyDateModel(date) : null;
  }
}
