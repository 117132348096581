export const sensorStates = [
  {
    name:   'Sensor off',
    info:   'No observation data will be received',
    hidden: true,
  },
  {
    name:   'Not publishing',
    info:   'Observation data will be received',
    hidden: true,
  },
  {
    name:   'Publishing',
    info:   'Data will be published to customers',
    hidden: true,
  },
];

export const optionTabs = [
  {
    info: 'general information',
    path: 'info',
  },
  {
    info: 'data stream',
    path: 'stream',
  },
  {
    info: 'checks',
    path: 'checks',
  },
  {
    info: 'incoming data',
    path: 'data',
  },
];

export const defautModeMsg = {
  mode:   null,
  reason: '',
  date:   new Date(),
  user:   'Samira',
};
