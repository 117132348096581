export enum ValidatorRegex {
    //Decimal = '^[+-]?([1-9][0-9]*|0)(\\.[0-9]+)?$',
    Decimal = '^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$',
    PositiveInteger = '^([1-9][0-9]*|0)$',
}

export enum ValidatorMessage {
    Required = 'Required field',
    PositiveDecimal = 'Only positive number (decimal) allowed',
    PositiveInteger = 'Only positive integer allowed',
    Decimal = 'Insert a number (decimals allowed)',
    InvalidEndDate = 'Sensor end date cannot be earlier or equal than sensor start date',
    InvalidRange = 'Insert an angle value between 0 and 359',
}
