import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../authorization/service/auth-guard';
import { UploadResolver } from './resolver/upload.resolver';
import { AstronomicalDataService } from './service/astronomical-data.service';
import { OsrMetaDataService } from './service/osr-data.service';
import { RwsDataService } from './service/rws-data.service';
import { UploadComponent } from './upload/upload.component';

const routes: Routes = [
    {
      path: 'upload',
      data: {
        resource: '/upload/astro',
        rights: ['readAll'],
      },
      canActivate: [AuthGuard],
      component: UploadComponent,
      resolve: { astronomicalPredictionsMetaData: UploadResolver },
    },
    {
      path: 'import',
      data: {
        resource: '/imports',
        rights: ['readAll'],
      },
      canActivate: [AuthGuard],
      loadChildren: 'app/components/ext-interface/import/import.module#ImportModule',
    },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [
    UploadResolver,
    AstronomicalDataService,
    OsrMetaDataService,
    RwsDataService,
  ],
})
export class ExtInterfaceRoutingModule {
}
