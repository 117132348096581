import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ModalModule} from './modal/modal.module';

@NgModule({
  imports:      [
    CommonModule,
    ModalModule,
  ],
  declarations: [],
  providers:    [],
  exports:      [
    CommonModule,
    ModalModule,
  ],
})

export class SharedModule {

}
