import { AbstractControl, FormGroup } from '@angular/forms';
import { myParseFloat } from '../utility/float-parser';
import { Device } from '../../../../../shared/iot-platform/model/iot-device';
import { IMyDateModel } from 'mydatepicker';
import { SensorType } from '../../../model/sensor-type.model';
import { MeasurementLocation } from '../../../../../shared/iot-platform/model/measurement-location';

export class AddNewSensorFormController {
  public addNewSensorForm: FormGroup;

  constructor(addNewSensorForm: FormGroup) {
    this.addNewSensorForm = addNewSensorForm;
  }

  public get sensorTypeControl(): AbstractControl {
    return this.addNewSensorForm.get('sensorType');
  }

  public get sensorType(): SensorType {
    return this.sensorTypeControl.value;
  }

  public get sensorNameControl(): AbstractControl {
    return this.addNewSensorForm.get('sensorName');
  }

  public get sensorName(): string {
    return this.sensorNameControl.value;
  }

  public get deviceControl(): AbstractControl {
    return this.addNewSensorForm.get('device');
  }

  public get device(): Device {
    return this.deviceControl.value;
  }

  public get instrumentTypeControl(): AbstractControl {
    return this.addNewSensorForm.get('instrumentType');
  }

  public get instrumentType(): string {
    return this.instrumentTypeControl.value;
  }

  public get locationNameControl(): AbstractControl {
    return this.addNewSensorForm.get('locationName');
  }

  public get locationName(): string {
    return this.locationNameControl.value;
  }

  public setLocationName(name: string): void {
    this.locationNameControl.setValue(name);
  }

  public get startDateControl(): AbstractControl {
    return this.addNewSensorForm.get('startDate');
  }

  public get startDate(): IMyDateModel {
    return this.startDateControl.value;
  }

  public get startDateISOString(): string {
    const date: Date = this.startDate.jsdate;

    return date ? date.toISOString() : '';
  }

  public get endDateControl(): AbstractControl {
    return this.addNewSensorForm.get('endDate');
  }

  public get endDate(): IMyDateModel {
    return this.endDateControl.value;
  }

  public get endDateISOString(): string {
    const date = this.endDate ? this.endDate.jsdate : null;

    return date ? date.toISOString() : '';
  }

  public get locationXControl(): AbstractControl {
    return this.addNewSensorForm.get('locationX');
  }

  public get locationX(): number {
    return myParseFloat(this.locationXControl.value);
  }

  public get locationYControl(): AbstractControl {
    return this.addNewSensorForm.get('locationY');
  }

  public get locationY(): number {
    return myParseFloat(this.locationYControl.value);
  }

  public get locationZControl(): AbstractControl {
    return this.addNewSensorForm.get('locationZ');
  }

  public get locationZ(): number {
    return myParseFloat(this.locationZControl.value);
  }

  public get measurementLocationsControl(): AbstractControl {
    return this.addNewSensorForm.get('measurementLocations');
  }

  public get measurementLocations(): MeasurementLocation[] {
    return this.measurementLocationsControl.value;
  }

  public setMeasurementLocations(measurementLocations: MeasurementLocation[]) {
    this.measurementLocationsControl.setValue(measurementLocations);
  }

  public get summaryMeasurementLocations(): MeasurementLocation[] {
    let summaryMeasurementLocations: MeasurementLocation[] = [];
    summaryMeasurementLocations.push(new MeasurementLocation(
      this.locationName,
      '',
      '',
      this.locationX,
      this.locationY,
      this.locationZ,
      null
    ));

    if (this.measurementLocations && this.measurementLocations.length > 0) {
      summaryMeasurementLocations = summaryMeasurementLocations.concat(this.measurementLocations);
    }

    return summaryMeasurementLocations;
  }

  public isTypeAndNameInvalid() {
    return this.sensorTypeControl.invalid || this.sensorNameControl.invalid;
  }

  public isDeviceInvalid() {
    return this.deviceControl.invalid;
  }

  public isGeneralInformationInvalid() {
    return this.instrumentTypeControl.invalid ||
      this.locationNameControl.invalid ||
      this.startDateControl.invalid ||
      this.endDateControl.invalid ||
      this.locationXControl.invalid ||
      this.locationYControl.invalid ||
      this.locationZControl.invalid ||
      this.measurementLocationsControl.invalid;
  }
}
