import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ObservationsConvertService } from '../sensor/detail/data/charts/observations/sensor/service/observations-conversion.service';

@NgModule({
  imports:      [
    CommonModule,
  ],
  declarations: [],
  providers:    [
    ObservationsConvertService,
  ],
})
export class ObservationModule {
}
