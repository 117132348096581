import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../authorization/service/auth-guard';
import { SensorTypeResolver } from '../sensor-type/resolver/sensor-type.resolver';
import { AddSensorComponent } from './create/create-sensor.component';
import { SensorOverviewComponent } from './overview/sensor-overview.component';
import { SensorResolver } from './resolver/sensor.resolver';
import { SensorsResolver } from './resolver/sensors.resolver';
import { SensorSummaryResolver } from './resolver/sensor-summary.resolver';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'overview',
    pathMatch: 'full',
  },
  {
    path: 'overview',
    canActivate: [AuthGuard],
    component: SensorOverviewComponent,
    data: {
      resource: '/sensors',
      rights: ['readAll'],
    },
    resolve:
      {
        sensorSummaries: SensorSummaryResolver,
      },
  },
  {
    path: 'create',
    canActivate: [AuthGuard],
    component: AddSensorComponent,
    data: {
      resource: '/sensors',
      rights: ['create'],
    },
    resolve: {
      sensorTypes: SensorTypeResolver,
      sensorSummaries: SensorSummaryResolver,
    },
  },
  {
    path: 'detail/:id',
    canActivate: [AuthGuard],
    resolve: { sensor: SensorResolver },
    data: {
      resource: '/sensors',
      rights: ['readOne'],
    },
    loadChildren: 'app/components/sensor/detail/sensor-detail.module#SensorDetailModule',
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
  ],
})
export class SensorRoutingModule {
}
