import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { SessionService } from '../../../shared/service/session.service';
import { MetaData, MetaDataInterface } from '../model/meta-data.model';
import { AstronomicalDataService } from '../service/astronomical-data.service';
import { TableFunctionality } from '../utilities/table-functionality';
import { TableTimeConversion } from '../utilities/table-time-conversion';

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss'],
  providers: [AstronomicalDataService],
})
export class UploadComponent extends TableFunctionality implements OnInit {
  public astronomicalPredictionsMetaData: MetaData[];
  public file: any;
  public selectedFile: any;
  public hideUploadStatusMessage: boolean;
  public fileName: string;
  public isSuccessfullUploaded = false;
  public tableProperties: any;

  public activeFileType = false;
  public activeFile = false;
  public uploadFileForm: FormGroup;
  // TODO: demo purpose
  // public modal: boolean        = false;
  @ViewChild('uploadForm')
  public uploadForm: NgForm;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private astroService: AstronomicalDataService,
              private formBuilder: FormBuilder,
              private logger: NGXLogger,
              sessionService: SessionService) {

    super(route.snapshot.data['astronomicalPredictionsMetaData'], sessionService, '/imports/osr', ['readAll', 'update']);

    this.hideUploadStatusMessage = true;
    this.tableProperties = [
      {
        header: 'Date',
        accessor: { main: 'dateTime' },
        containsDate: true,
      },
      {
        header: 'Local Time',
        accessor: { main: 'time' },
        containsDate: false,
      },
      {
        header: 'Location',
        accessor: { main: 'location' },
        containsDate: false,
      },
      {
        header: 'File Type',
        accessor: { main: 'fileType' },
        containsDate: false,
      },
      {
        header: 'File Name',
        accessor: { main: 'name' },
        containsDate: false,
      },
      {
        header: 'Uploaded by',
        accessor: { main: 'user' },
        containsDate: false,
      },
      {
        header: 'Status',
        accessor: { main: 'status' },
        containsDate: false,
      },
      {
        header: 'Message',
        accessor: { main: 'message' },
        containsDate: false,
      },
    ];

    this.filterMetaData();
    this.updateMetaDataArray();
  }

  ngOnInit() {
    this.createForm();
    this.convertDateTimeToLocalOnInit();
  }

  public createForm() {
    this.uploadFileForm = this.formBuilder.group({
      file: [],
      fileType: [],
    });
  }

  public onFileTypeChange(event): void {
    this.activeFileType = true;
  }

  public resetValues(): void {
    if (document.getElementsByClassName('modal').length > 0) {
      if (document.getElementsByClassName('modal')[0].getElementsByTagName('form').length > 0) {
        document.getElementsByClassName('modal')[0].getElementsByTagName('form')[0].reset();
      }
    }
  }

  public onFileChange(event): void {
    this.activeFile = true;
    this.selectedFile = event.target.files[0];
  }

  public uploadFile(): void {

    this.hideUploadStatusMessage = true;

    const formData = new FormData();

    formData.append('user', this.sessionService.getCurrentUser().unique_name);
    formData.append('file', this.selectedFile);
    this.fileName = this.selectedFile.name;

    if (this.uploadFileForm.value.fileType === 'waterLevel') {
      this.astroService.postWaterData(formData, 'posting astronomical data (Water Level)', 'timeseries').subscribe(() => {
        this.isSuccessfullUploaded = true;
        this.hideUploadStatusMessage = false;
      }, () => {
        this.isSuccessfullUploaded = false;
        this.hideUploadStatusMessage = false;
      });
    } else if (this.uploadFileForm.value.fileType === 'highAndLowWaterMarks') {
      this.astroService.postWaterData(
        formData,
        'posting astronomical data (High and Low Water Marks)',
        'waterheight').subscribe(() => {
        this.isSuccessfullUploaded = true;
        this.hideUploadStatusMessage = false;
      }, () => {
        this.isSuccessfullUploaded = false;
        this.hideUploadStatusMessage = false;
      });
    }
  }

  public updateMetaDataAfterPost(metaDataList: MetaDataInterface[]) {
    const metaData: MetaData[] = [];
    metaDataList.forEach((item) => {
      metaData.unshift(new MetaData(item));
    });

    /* converting date from UTC to local date time
     * when the page is refreshed */
    this.logger.debug('METADATA', metaData);

    const conversion = new TableTimeConversion(metaData);
    conversion.convertDateTimeFormat();
    this.setListMetaData(conversion.data);

    this.filterMetaData();
    /* setting the displayed metadata
     into new converted data format based on local time
     */
    this.setDisplayedMetadata(conversion.data);
  }

  public refreshList() {
    this.hideUploadStatusMessage = true;
    this.astroService.getAllAstronomicalPredictionsMetaData().subscribe((metaDataList) => {
      this.updateMetaDataAfterPost(metaDataList);
    });

  }

  // conversion from UTC date to local date in the UI
  public convertDateTimeToLocalOnInit(): void {
    // getting list of metadata
    const data = this.getListMetadata();

    const conversion = new TableTimeConversion(data);
    conversion.convertDateTimeFormat();

    // setting list of metadata
    this.setListMetaData(conversion.data);

    // setting list of displayed data
    this.setDisplayedMetadata(conversion.data);
  }

  // TODO line for custom validtation
  // <div *ngIf="addNewSensorForm.get('deviceName').errors['validDeviceName']">invalid devicename!</div>
}
