import { AbstractControl, FormGroup } from '@angular/forms';
import { myParseFloat } from '../utility/float-parser';
import { FeatureType } from '../../../model/feature-type.model';
import { MeasurementLocation } from '../../../../../shared/iot-platform/model/measurement-location';
import { AddNewSensorFormController } from './sensor-form-controller';
import { IMyDateModel } from 'mydatepicker';

export class MeasurementLocationFormController {
  public measurementLocations: MeasurementLocation[] = [];
  public measurementLocationForm: FormGroup;
  public addNewSensorFormController: AddNewSensorFormController;
  public index: number;
  public isEdit: boolean;

  constructor(measurementLocationForm: FormGroup, addNewSensorFormController: AddNewSensorFormController) {
    this.measurementLocationForm = measurementLocationForm;
    this.addNewSensorFormController = addNewSensorFormController;
    this.measurementLocations = [];
    this.index = 0;
    this.isEdit = false;
  }

  public get featureNameControl(): AbstractControl {
    return this.measurementLocationForm.get('featureName');
  }

  public get featureName(): string {
    return this.featureNameControl.value;
  }

  public setFeatureName(name: string): void {
    this.featureDescriptionControl.setValue(name);
  }

  public get featureTypeControl(): AbstractControl {
    return this.measurementLocationForm.get('featureType');
  }

  public get featureType(): string {
    return this.featureTypeControl.value;
  }

  public get featureDescriptionControl(): AbstractControl {
    return this.measurementLocationForm.get('featureDescription');
  }

  public get featureDescription(): string {
    return this.featureDescriptionControl.value;
  }

  public get xCoordinateMeasurementControl(): AbstractControl {
    return this.measurementLocationForm.get('xCoordinateMeasurement');
  }

  public get xCoordinateMeasurement(): number {
    return myParseFloat(this.xCoordinateMeasurementControl.value);
  }

  public get yCoordinateMeasurementControl(): AbstractControl {
    return this.measurementLocationForm.get('yCoordinateMeasurement');
  }

  public get yCoordinateMeasurement(): number {
    return myParseFloat(this.yCoordinateMeasurementControl.value);
  }

  public get zCoordinateMeasurementControl(): AbstractControl {
    return this.measurementLocationForm.get('zCoordinateMeasurement');
  }

  public get zCoordinateMeasurement(): number {
    return myParseFloat(this.zCoordinateMeasurementControl.value);
  }

  public get distanceControl(): AbstractControl {
    return this.measurementLocationForm.get('distance');
  }

  public get distance(): string {
    return this.distanceControl.value;
  }

  public get directionFromControl(): AbstractControl {
    return this.measurementLocationForm.get('directionFrom');
  }

  public get napToALatConversion(): string {
    return this.napToALatConversionControl.value;
  }

  public get napToALatConversionControl(): AbstractControl {
    return this.measurementLocationForm.get('napToALatConversion');
  }

  public get directionFrom(): string {
    return this.directionFromControl.value;
  }

  public get directionToControl(): AbstractControl {
    return this.measurementLocationForm.get('directionTo');
  }

  public get directionTo(): string {
    return this.directionToControl.value;
  }

  public get endDateControl(): AbstractControl {
    return this.measurementLocationForm.get('endDate');
  }

  public get endDate(): IMyDateModel {
    return this.endDateControl.value;
  }

  public get endDateISOString(): string {
    const date = this.endDate ? this.endDate.jsdate : null;

    return date ? date.toISOString() : '';
  }

  public resetMeasurementLocationForm(): void {
    this.measurementLocationForm.reset();
    this.measurementLocationForm.markAsPristine();
    this.measurementLocationForm.markAsUntouched();
    this.index = 0;
    this.isEdit = false;
  }

  public setFirstFeatureType(featureTypes: FeatureType[]) {
    if (featureTypes && featureTypes.length > 0) {
      this.featureTypeControl.setValue(featureTypes[0].name);
    }
  }

  public disableAddMeasurementLocation(): boolean {
    if (this.featureType === 'Tidal stream') {
      return this.measurementLocationForm.invalid;
    } else {
      return this.isMeasurementFormInvalid();
    }
  }

  private isMeasurementFormInvalid() {
    return this.featureTypeControl.invalid ||
      this.featureNameControl.invalid ||
      this.featureDescriptionControl.invalid ||
      this.xCoordinateMeasurementControl.invalid ||
      this.yCoordinateMeasurementControl.invalid ||
      this.zCoordinateMeasurementControl.invalid;
  }

  public setMeasurementInActiveForm(index: number): void {
    this.isEdit = true;
    this.index = index;

    const measurement = this.measurementLocations[index];

    this.measurementLocationForm.patchValue({
      featureType: measurement.type,
      featureName: measurement.name,
      featureDescription: measurement.description,
      xCoordinateMeasurement: measurement.x,
      yCoordinateMeasurement: measurement.y,
      zCoordinateMeasurement: measurement.z,
      endDate: { jsdate: measurement.endDate },
      distance: measurement.distance,
      directionFrom: measurement.directionFrom,
      directionTo: measurement.directionTo,
      napToALatConversion: measurement.napToALatConversion,
    });
  }

  public updateMeasurementLocation(): void {
    if (this.isIndexValid()) {
      const measurement: MeasurementLocation = this.measurementLocations[this.index];
      this.setMeasurementData(measurement);
    }
  }

  private isIndexValid() {
    return typeof this.index === 'number' && this.index <= this.measurementLocations.length;
  }

  private setMeasurementData(measurement: MeasurementLocation): void {
    measurement.name = this.featureName;
    measurement.type = this.featureType;
    measurement.description = this.featureDescription;
    measurement.x = this.xCoordinateMeasurement;
    measurement.y = this.yCoordinateMeasurement;
    measurement.z = this.zCoordinateMeasurement;
    measurement.endDate = (this.endDate === null ? null : this.endDate.jsdate);
    measurement.distance = this.distance;
    measurement.directionFrom = this.directionFrom;
    measurement.directionTo = this.directionTo;
    measurement.napToALatConversion = this.napToALatConversion;
  }

  public addCurrentMeasurementsData(): void {
    this.measurementLocations.push(new MeasurementLocation(
      this.featureName,
      this.featureType,
      this.featureDescription,
      this.xCoordinateMeasurement,
      this.yCoordinateMeasurement,
      this.zCoordinateMeasurement,
      (this.endDate === null ? null : this.endDate.jsdate),
      this.distance,
      this.directionFrom,
      this.directionTo,
      this.napToALatConversion)
    );
  }

  public submitMeasurementLocationData(): void {
    if (!this.isEdit) {
      this.addCurrentMeasurementsData();
    } else {
      this.updateMeasurementLocation();
    }
    this.addNewSensorFormController.setMeasurementLocations(this.measurementLocations);
  }
}
