import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthenticationComponent } from './authentication/authentication.component';
import { AuthenticationResolver } from './authentication/resolver/authentication.resolver';
import { AuthGuard } from './components/authorization/service/auth-guard';
import { ErrorComponent } from './error/error.component';
import { NotAuthorizedComponent } from './error/not-authorized-component';
import { PageNotFoundComponent } from './error/page-not-found.component';
import { LoginComponent } from './login/login.component';
import { ExternalUrlComponent } from './shared/external-url/external-url.component';
import { ExternalUrlResolver } from './shared/external-url/external-url.resolver';

/**
 * The base app router module, handles everything not in the component router module, located at /app/components.
 */

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: '/app/sensors/overview', pathMatch: 'full' },
    ],
  },
  {
    path: 'external/:url',
    resolve: {
      url: ExternalUrlResolver,
    },
    component: ExternalUrlComponent,
  },
  { path: '404', component: PageNotFoundComponent },
  { path: '403', component: NotAuthorizedComponent },
  { path: 'error', component: ErrorComponent },
  { path: 'login', component: LoginComponent },
  { path: 'auth', component: AuthenticationComponent, resolve: { token: AuthenticationResolver } },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { useHash: false, paramsInheritanceStrategy: 'always' }),
  ],
})

export class AppRoutingModule {

}
