import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs/Observable';
import { ApiDataService } from '../../../api/api.data.service';
import { DatastreamCheck } from '../detail/checks/interface/datastream-check.interface';
import { SensorLog } from '../model/sensor-log.model';
import { Sensor, SensorDTO } from '../model/sensor.model';
import { SensorSummary } from '../overview/model/sensor-overview.model';
import { UpdateSensorDto } from '../model/update-sensor-dto.model';
import { MeasurementLocation } from '../../../shared/iot-platform/model/measurement-location';
import { UpdateSensorStatusDto } from '../model/update-sensor-status-dto.model';

@Injectable()
export class SensorService {

  constructor(private logger: NGXLogger,
              private apiDataService: ApiDataService) {
    this.logger.debug('constructing SensorService.');
  }

  public getSensor(id: string): Observable<Sensor> {
    return this.apiDataService.getOne('sensors', id).map((sensorDTO: SensorDTO) => new Sensor(sensorDTO));
  }

  public getAllSensors(): Observable<Sensor[]> {
    this.logger.debug('SensorService: retrieving sensors');

    return this.apiDataService.getAll('sensors').map((sensorDTOs: SensorDTO[]) => {
      return sensorDTOs.map((sensorDTO: SensorDTO) => new Sensor(sensorDTO));
    });
  }

  public createSensor(sensor: Sensor): Observable<Sensor> {
    return this.apiDataService.createOne('sensors', sensor);
  }

  public updateSensor(updateSensorDto: UpdateSensorDto, id: string): Observable<any> {
    return this.apiDataService.updateOneWithId('sensors', updateSensorDto, id);
  }

  public updateSensorStatus(updateSensorStatusDto: UpdateSensorStatusDto, id: string): Observable<any> {
    return this.apiDataService.updateOneWithId('sensors/status', updateSensorStatusDto, id);
  }

  public updateSensorLocation(measurementLocation: MeasurementLocation, sensorId: string, featureId: string): Observable<any> {
    return this.apiDataService.updateOneForMultiplePaths(measurementLocation, 'sensors', sensorId,
      'features', featureId);
  }

  public addSensorLocation(measurementLocation: MeasurementLocation, sensorId: string): Observable<any> {
    return this.apiDataService.createOneForMultiplePaths(measurementLocation, 'sensors', sensorId,
      'features');
  }

  public updateChecks(datastreamCheckArray: DatastreamCheck[]) {
    return this.apiDataService.updateOne('datastreamChecks/update', datastreamCheckArray);
  }

  public getAllSensorLogs(sensorId: string): Observable<SensorLog[]> {
    return this.apiDataService.getOne('sensorLogs', sensorId);
  }

  public getAllSensorSummaries(): Observable<SensorSummary[]> {
    return this.apiDataService.getAll('sensors/overview');
  }
}
