import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs/Observable';

import 'rxjs/add/operator/do';

@Injectable()
export class ExternalUrlResolver implements Resolve<any> {

  constructor(private logger: NGXLogger,
              private router: Router) {
    this.logger.debug('constructing external url resolver.');
  }

  resolve(route: ActivatedRouteSnapshot,
          state: RouterStateSnapshot): Observable<any> {

    this.logger.debug('ExternalUrlResolver: resolving external url.');

    // we have to trick the app into navigating the the current url so
    // that the extension of url + external url does not get routed.
    // so we actually say 'angular, route to the current rout' and that
    // in turn routes nthing because angular is smart enough to not do
    // routing when its not needed!
    this.router.navigate([this.router.url]);
    window.open(decodeURIComponent(route.params['url']));

    return Observable.of(true);
  }
}
