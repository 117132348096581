import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs/Observable';
import { ApiDataService } from '../../../api/api.data.service';
import { Device, DeviceInterface } from '../model/iot-device';

@Injectable()
export class DeviceService {

  constructor(private logger: NGXLogger, private apiDataService: ApiDataService) {
    this.logger.debug('constructing DeviceService.');
  }

  public getAllDevices(): Observable<Device> {
    return this.apiDataService.getAll('watson-iot/devices/findAll');
  }

  public getAllDevicesBySensorType(sensorType: string): Observable<DeviceInterface[]> {
    return this.apiDataService.findAllById('watson-iot/devices/findAllBySensorType', sensorType);
  }

  public getDeviceBySensorTypeAndDeviceId(sensorType: string, deviceId: string): Observable<Device> {
    return this.apiDataService.findAllById('watson-iot/devices/findOneBySensorTypeAndDeviceId', sensorType, deviceId);
  }
}
