import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { SearchBarComponent } from './search-bar.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
  ],
  declarations: [SearchBarComponent],
  exports: [
    SearchBarComponent,
  ],
})
export class SearchBarModule {}
