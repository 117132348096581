export class PaginationProperties {

  private displayedData: any[];
  private pages: any[];
  private maxPage: number;
  private isPreviousButtonDisabled: boolean;
  private isNextButtonDisabled: boolean;
  private step: number;
  private page: number;
  private steps: number[];
  private filteredData: number[]; // this is filtered data from search bar component

  constructor() {
    this.displayedData = [];
    this.page = 0;
    this.pages = [];
    this.maxPage = 1;
    this.isPreviousButtonDisabled = true;
    this.isNextButtonDisabled = true;
    this.step = 10;
    this.steps = [10, 20, 50, 100];
    this.filteredData = [];
  }

  public getDisplayedData(): any[] {
    return this.displayedData;
  }

  public setDisplayedData(displayedData: any[]): void {
    this.displayedData = displayedData;
  }

  public getPages(): any[] {
    return this.pages;
  }

  public setPages(pages: any[]) {
    this.pages = pages;
  }

  public getMaxPage(): number {
    return this.maxPage;
  }

  public setMaxPage(maxPage: number): void {
    this.maxPage = maxPage;
  }

  public getIsPreviousButtonDisabled(): boolean {
    return this.isPreviousButtonDisabled;
  }

  public setIsPreviousButtonDisabled(isPreviousButtonDisabled: boolean): void {
    this.isPreviousButtonDisabled = isPreviousButtonDisabled;
  }

  public getIsNextButtonDisabled(): boolean {
    return this.isNextButtonDisabled;
  }

  public setIsNextButtonDisabled(isNextButtonDisabled: boolean): void {
    this.isNextButtonDisabled = isNextButtonDisabled;
  }

  public getStep(): number {
    return this.step;
  }

  public setStep(step: number): void {
    this.step = step;
  }

  public getPage(): number {
    return this.page;
  }

  public setPage(page: number): void {
    this.page = page;
  }

  public getSteps(): number[] {
    return this.steps;
  }

  public setSteps(steps: number[]): void {
    this.steps = steps;
  }

  public getFilteredData(): any[] {
    return this.filteredData;
  }

  public setFilteredData(filteredData: any[]): void {
    this.filteredData = filteredData;
  }

  /* define specific function to calculate max page */
  public calculateMaxPage(data: any[], step: number): number {
    return Math.ceil(data.length / step);
  }

  /* define specific function to calculate no. of pages */
  public calculateNumberOfPages(maxPage: number): any[] {
    const pages = [];
    for (let i = 0; i < maxPage; i++) {
      pages.push(i);
    }
    return pages;
  }

  public disablePreviousButton(page: number): boolean {
    return page <= 0;
  }

  public disableNextButton(page: number, maxPage: number): boolean {
    return page >= (maxPage - 1);
  }

  public initializeDisplayDataset(datasets: any[]) {
    const displayInitialDataset = [];
    const defaultDataset = datasets;

    for (let i = 0; i < 10; i++) {
      if (defaultDataset[i]) {
        displayInitialDataset.push(defaultDataset[i]);
      } else {
        break;
      }
    }
    return displayInitialDataset;
  }
}
