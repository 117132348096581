export interface SensorModalInterface {
  idx: number;
  status: string;
  title: string;
  info: string;
  okButton: string;
}

export const sensorModalValues: SensorModalInterface[] = [
  {
    idx: 0,
    status: '',
    title: '',
    info: '',
    okButton: '',
  }, {
    idx: 1,
    status: 'add',
    title: 'Add measurement location',
    info: 'You can add the coordinates of a measurement location',
    okButton: 'Add',
  }, {
    idx: 2,
    status: 'change',
    title: 'Change sensor location',
    info: 'You can change the coordinates of a sensor location',
    okButton: 'Update',
  }, {
    idx: 3,
    status: 'change',
    title: 'Change measurement location',
    info: 'You can change the coordinates of a measurement location',
    okButton: 'Update',
  },
];
