import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { SessionService } from '../../shared/service/session.service';
import { AuthorizationComponent } from '../authorization/authorization.component';
import { EsriMapService } from './service/map.service';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})
export class MapComponent extends AuthorizationComponent implements OnInit {
  @ViewChild('mapRef', { read: ElementRef }) mapRef: ElementRef;

  constructor(private mapService: EsriMapService, private router: Router, sessionService: SessionService) {
    super(sessionService, '/map', ['readAll', 'readOne']);
  }

  ngOnInit() {
    this.mapService.loadMap(this.router, this.mapRef);
  }
}
