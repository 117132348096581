export function myParseFloat(toParse: string): number | null {
  const toReturn: number = parseFloat(toParse);
  if (isNaN(toReturn)) { // avoid returning NaN
    return null;
  }
  if (toReturn === 0) { // avoid returning negative zero (-0)
    return 0;
  }
  return toReturn;
}
