import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs/Observable';
import { environment } from '../../../../environments/environment';
import { ApiDataService } from '../../../api/api.data.service';
import { MetaDataInterface } from '../model/meta-data.model';

@Injectable()
export class AstronomicalDataService {

  public constructor(private logger: NGXLogger,
                     private apiDataService: ApiDataService) {
    this.logger.debug('constructing AstronomicalDataService');
  }

  public getAllAstronomicalPredictionsMetaData(): Observable<MetaDataInterface[]> {
    this.logger.debug('AstronomicalDataService: retrieving metadata.');
    return this.apiDataService.getAll('filemetadata/astro');
  }

  public postWaterData(astronomicalPayload: FormData, message: string, endpoint: string) {
    this.logger.debug(`AstronomicalDataService: ${message}`);
    const url: string = `${environment.astro_api}astro/fileupload/${endpoint}`;

    return this.apiDataService.postAstro(url, astronomicalPayload);
  }
}
