import { Injectable } from '@angular/core';
/**
 * Wrapper service for wrapping the real ADFS calls.
 */
import { NGXLogger } from 'ngx-logger';
import { AuthenticationService } from '../../authentication/service/authentication.service';

@Injectable()
export class LoginService {

  constructor(private logger: NGXLogger, private api: AuthenticationService) {
    this.logger.debug('constructing LoginService.');
  }

  /**
   * Call the login function of the authorize service.
   */
  login(): void {
    this.api.login();
  }

}
