export const DefaultHChartProperties: any = {
  height: 700,
  width: 1500,
  spacingLeft: 20,
  spacingRight: 40,

  title: {
    text: 'Height of Tide',
  },

  tooltip: {
      shared: true,
  },

  chart: {
    spacingLeft: 20,
    spacingRight: 40,
    spacingBottom: 30,

    style: {
      fontFamily: 'Helvetica Neue',
    },
  },

  plotOptions: {
    series: {
      dataGrouping: {
        enabled: false,
      },
      states: {
        // disable the line thickening when whe hover over it
        hover: {
          lineWidthPlus: 0,
        },
      },
      marker: {
        // disable dots on every entry in the line
        enabled: false,
        states: {
          // but enable them on hover
          hover: {
            enabled: true,
            radius: 2,
          },
        },
      },
    },
  },

  legend: {
    y: 50,
    verticalAlign: 'top',
    enabled: true,
  },

  navigator: {
    enabled: true,
    adaptToUpdatedData: false,
  },

  scrollbar: {
    liveRedraw: true,
  },

  series: null,

  xAxis: {
    type: 'datetime',
    ordinal: false,
    title: {
      text: 'Time',
    },
  },

  rangeSelector: {
    x: 0,
    y: -3,
    inputEnabled: false,
    selected: 1,
    buttons: [
      {
        type: 'all',
        text: 'All',
      },
      {
        type: 'hour',
        count: 1,
        text: '1H',
      },
      {
        type: 'hour',
        count: 6,
        text: '6H',
      },
      {
        type: 'hour',
        count: 12,
        text: '12H',
      },
      {
        type: 'hour',
        count: 24,
        text: '24H',
      },
      {
        type: 'hour',
        count: 48,
        text: '48H',
      },
    ],
  },

  responsive: {
    rules: [
      {
        condition: {
          maxWidth: 1100,
        },
        chartOptions: {
          rangeSelector: {
            align: 'left',
            y: 43,
          },
          legend: {
            align: 'right',
            y: 39,
          },
        },
      },
    ],
  },

  yAxis: [{ // Observations chart.
    opposite: false,
    labels: {
      align: 'right',
      x: -3,
      format: '{value}',
    },
    title: {
      text: 'value',
    },
    height: '80%',
    lineWidth: 2,
    resize: {
      enabled: true,
    },
  },
  { // Checks chart.
    labels: {
      enabled: false,
      align: 'right',
      x: -3,
      formatter() {
        return this.value;
      },
    },
    title: {
      text: 'Checks',
    },
    top: '85%',
    height: '15%',
    offset: 0,
    lineWidth: 2,
  }],
};
