import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {ChartModule} from 'angular-highcharts';
import {ToasterModule, ToasterService} from 'angular5-toaster';
import { UiModule } from '../../../../../../shared/ui/ui.module';
import { ObservationModule } from '../../../../../observation/observation.module';
import { ObservationChartService } from './observation-chart.service';
import { SensorObservationsChartComponent } from './sensor/sensor-observations-chart.component';

@NgModule({
  imports: [
    UiModule,
    ObservationModule,
    CommonModule,
    ChartModule,
    ToasterModule,
  ],
  declarations: [
    SensorObservationsChartComponent,
  ],
  providers: [
    ObservationChartService,
  ],
  exports: [
    SensorObservationsChartComponent,
  ],
})

export class ObservationChartModule {

}
