import { ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { StructuredObservations } from './charts/observations/sensor/model/structured-observations.model';

import * as moment from 'moment';
import 'rxjs/add/observable/timer';

import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { ObservationService } from '../../../observation/service/observation.service';
import { Feature } from '../../model/feature.model';
import { Sensor } from '../../model/sensor.model';
import { SensorDetailStreamUpdateLogsNotificationService } from '../stream/sensor-detail-stream-update-logs-notification-service';
import { ObservationUpdateInfo } from './service/observation-data-update-info.model';
import { SensorDetailDataUpdatingService } from './service/sensor-detail-data-updating.service';
import { Process } from '../../../process/model/process.model';
import { Observation } from '../../../observation/model/observation.model';

@Component({
  selector: 'app-sensor-detail-data',
  templateUrl: './sensor-detail-data.component.html',
  styleUrls: ['./sensor-detail-data.component.scss'],
})
export class SensorDetailDataComponent implements OnDestroy {

  public readonly NO_OBSERVATIONS_AVAILABLE = 'No observations available for this sensor.';
  public readonly LOADING_TEXT: string = 'Retrieving observations...';
  private readonly TIME_TO_SUBTRACT: number = 900000;

  public observations: StructuredObservations[] = [];
  public sensor: Sensor;
  public feature: Feature;
  public isLoading: boolean = true;

  private lastUpdateTime: Date;
  private streamUpdates: Subscription;
  public featureId = '';
  public performanceMode: boolean = true;

  constructor(private readonly route: ActivatedRoute,
              private readonly router: Router,
              private readonly observationDataService: ObservationService,
              private readonly updateService: SensorDetailDataUpdatingService,
              private readonly logger: NGXLogger,
              public readonly streamNotificationService: SensorDetailStreamUpdateLogsNotificationService,
              private readonly changeDetectorRef: ChangeDetectorRef) {

    this.logger.debug('SensorDetailDataComponent: constructing.');
    this.sensor = route.snapshot.data['sensor'];
  }

  ngOnDestroy(): void {
    if (this.streamUpdates) {
      this.streamUpdates.unsubscribe();
    }
  }

  private loadChartObservations(processes: Process[], beginDate: Date, endDate: Date, shouldModalBeDisplayed: boolean): void {
    this.observationDataService.getObservationsByProcesses(processes, 10000,  beginDate, endDate, shouldModalBeDisplayed)
      .subscribe(
        (unstructuredObservations: Observable<Observation>) => {
          this.observations = [...this.observations, ...this.structureObservationDataByStream(unstructuredObservations)];
          this.changeDetectorRef.detectChanges();
          this.updateService.manualDateSelectNotifier({
            from: beginDate,
            to: endDate,
          });
          this.isLoading = false;
          this.changeDetectorRef.detectChanges();
        }
      );
  }

  public updateSingleCurrentFeature(feature: Feature): Feature {

    if (feature !== undefined && feature.id !== this.featureId) {

      if (this.feature !== feature) {
        this.observations = [];
        this.performanceMode = true;
      }

      this.feature = feature;
      this.featureId = feature.id;

      this.disableUpdating();

      const beginDate = moment().add(-2, 'days').toDate();
      const endDate = moment().toDate();
      this.changeDetectorRef.detectChanges();
      this.largeQueryInitiated();

      const shortIntervalProcesses: Process[] = [];
      const biggerIntervalProcesses: Process[] = [];

      for (const process of feature.processes) {
        if (this.doesProcessHaveShortInterval(process)) {
          shortIntervalProcesses.push(process);
        } else {
          biggerIntervalProcesses.push(process);
        }
      }

      this.loadChartObservations(
        this.performanceMode ? biggerIntervalProcesses : shortIntervalProcesses, beginDate, endDate, this.performanceMode);

      if (shortIntervalProcesses.length === 0) {
        this.performanceMode = false;
      }

      this.lastUpdateTime = moment().toDate();

      this.disableUpdating();

      if (this.observations) {
        this.streamUpdates = Observable.timer(10000, 10000).subscribe(() => this.update());
      }

      this.logger.info(`SensorDetailDataComponent: Changed feature id to '${feature.id}'`);
    }

    return feature;
  }

  public largeQueryInitiated(): void {
    this.isLoading = true;
    this.changeDetectorRef.detectChanges();
  }

  /**
   * Structure the data so that we know which list of observation is part of which stream (e.g. H, H10 etc.)
   *
   * @param streamData the data to be structured
   */
  public structureObservationDataByStream(streamData: any): StructuredObservations[] {
    this.logger.debug('SensorDetailDataComponent: structuring observation data by datastream id / name');

    if (!streamData || streamData.length < 1) {
      this.logger.debug('SensorDetailDataComponent: no data available to structure! returning empty list');
      return [];
    }

    const structuredData: StructuredObservations[] = [];

    for (const streamObservations of streamData) {
      if (streamObservations.length > 1) {

        if (this.shouldObservationsBeReversed(streamObservations[0].ticks,
                                              streamObservations[streamObservations.length - 1].ticks)) {
          streamObservations.reverse();
        }

        const structuredObservations = new StructuredObservations(streamObservations[0]['datastream'], streamObservations);
        structuredData.push(structuredObservations);
      }
    }

    return structuredData;
  }

  private shouldObservationsBeReversed(firstObservationTick: number, lastObservationTick): boolean {
    return firstObservationTick > lastObservationTick;
  }

  /**
   * this method is called every 10 seconds to retrieve the latest observations.
   */
  public update(): void {
    this.logger.debug('SensorDetailDataComponent: streams are being updated.');
    this.observationDataService.getObservationsByProcesses(this.feature.processes, 5, this.lastUpdateTime).subscribe(
      (unstructuredObservations: Observable<Observation>) => {
        // structure the latest retrieved data.
        const latestObservations: StructuredObservations[] = this.structureObservationDataByStream(unstructuredObservations);
        const updateInfo: ObservationUpdateInfo[] = this.updateService.setObservationUpdateInfo(this.observations,
                                                                                                latestObservations);

        // if there are updates, update the current dataset
        if (updateInfo.length > 0) {
          this.logger.debug(updateInfo);
          this.updateService.streamUpdateNotifier(updateInfo);

          const observations: Observation[] = latestObservations[latestObservations.length - 1].observations;
          if (observations.length > 0) {
            this.lastUpdateTime = this.getDateFromTickMinusDefaultTimeToSubtract(observations[observations.length - 1].ticks);
          }
        }
      }
    );
  }

  private getDateFromTickMinusDefaultTimeToSubtract(tick: number): Date {
    return moment(tick - this.TIME_TO_SUBTRACT).toDate();
  }

  /**
   * Whenever the user has selected two dates, this function is triggers and calls new observations.
   *
   * @param dateSelectionInfo information for which dates are selected
   */
  public getObservationsByDate(dateSelectionInfo: any): void {
    this.performanceMode = false;
    this.largeQueryInitiated();
    this.logger.debug('SensorDetailDateComponent: User selected a date for the observation graph.');

    if (this.streamUpdates) {
      this.logger.debug('SensorDetailDataComponent: Unsubscribing from stream updates.');
      this.streamUpdates.unsubscribe();
    }

    this.observationDataService.getObservationsByProcesses(this.feature.processes, 10000,
      moment(dateSelectionInfo.from).toDate(), moment(dateSelectionInfo.to).toDate(), true)
        .subscribe(
          (unstructuredObservations: Observable<Observation>) => {
            this.observations = this.structureObservationDataByStream(unstructuredObservations);
            this.changeDetectorRef.detectChanges();
            this.updateService.manualDateSelectNotifier(dateSelectionInfo);
          }
        );

    this.isLoading = false;
  }

  public shouldGraphBeDisplayed(): boolean {
    return this.observations.length > 0 || this.isLoading;
  }

  public onLoadMoreDataClick(): void {
    this.performanceMode = false;
    this.featureId = '';
    this.updateSingleCurrentFeature(this.streamNotificationService.getFeature());
  }

  public shouldLoadMoreDataButtonBeDisplayed(): boolean {
    return this.performanceMode && this.shouldGraphBeDisplayed();
  }

  public shouldLoadingSpinnerBeDisplayed(): boolean {
    return this.isLoading && this.performanceMode;
  }

  public shouldLoadingMoreDataIndicatorBeDisplayed(): boolean {
    return !this.performanceMode && this.shouldGraphBeDisplayed() && this.isLoading;
  }

  private disableUpdating(): void {
    if (this.streamUpdates) {
      this.logger.debug('SensorDetailDataComponent: Unsubscribing from stream updates.');
      this.streamUpdates.unsubscribe();
    }
  }

  private doesProcessHaveShortInterval(process: Process): boolean {
    return process.description.includes('seconds');
  }
}
