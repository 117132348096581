import { Component } from '@angular/core';
import { SessionService } from '../../shared/service/session.service';
import { PermissionBasedEntity } from './model/permission-based-entity.model';

/**
 * Super class for enabling authorzation in the UI.
 */

@Component({
  template: '<div></div>',
})
export class AuthorizationComponent extends PermissionBasedEntity {

  constructor(sessionService: SessionService,
              resource: string, rights: string[]) {

    super(sessionService, resource, rights);

  }

}
