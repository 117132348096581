import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MyDatePickerModule } from 'mydatepicker';
import { ErrorMessageModule } from './error-message/error-message.module';
import { PaginationModule } from './pagination/pagination.module';
import { SearchBarModule } from './search-bar/search-bar.module';
import { TableModule } from './table/table.module';

@NgModule({
  imports:      [
    CommonModule,
    MyDatePickerModule,
    ReactiveFormsModule,
    FormsModule,
  ],
  declarations: [],
  providers:    [],
  exports:      [
    MyDatePickerModule,
    TableModule,
    ReactiveFormsModule,
    FormsModule,
    ErrorMessageModule,
    PaginationModule,
    SearchBarModule,
  ],
})

export class UiModule {

}
