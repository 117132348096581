import {Injectable} from '@angular/core';
import {NGXLogger} from 'ngx-logger';
import {Observable} from 'rxjs/Observable';
import {ApiDataService} from '../../../api/api.data.service';
import {FeatureType} from '../model/feature-type.model';

@Injectable()
export class FeatureTypeService {

  constructor(private logger: NGXLogger, private apiDataService: ApiDataService) {
    this.logger.debug('constructing FeatureTypeService.');
  }

  public getAllFeatureTypesBySensorType(sensorType: string): Observable<FeatureType[]> {
    this.logger.debug(`FeatureTypeService: retrieving featureTypes by sensorType (${sensorType})`);

    return this.apiDataService.findAllById('featureTypes/findAllBySensorType', sensorType);
  }
}
