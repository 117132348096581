import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { ApiDataService } from '../../../api/api.data.service';
import { SensorType } from '../model/sensor-type.model';

@Injectable()
export class SensorTypeService {

  constructor(private apiDataService: ApiDataService) {

  }

  getAllSensorTypes(page: number = 0, size: number = 0, sort: string = 'desc'): Observable<SensorType[]> {
    return this.apiDataService.getAll('sensorTypes', page, size, sort);
  }

}
