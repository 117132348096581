import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {CommonModule} from '@angular/common';
import {ModalModule} from '../../shared/modal/modal.module';
import {ErrorMessageModule} from '../../shared/ui/error-message/error-message.module';
import {TableModule} from '../../shared/ui/table/table.module';
import {UiModule} from '../../shared/ui/ui.module';
import {ExtInterfaceComponent} from './ext-interface.component';
import {ExtInterfaceRoutingModule} from './ext-interface.routing.module';
import {UploadComponent} from './upload/upload.component';

@NgModule({
  imports:      [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    ModalModule,
    ExtInterfaceRoutingModule,
    UiModule,
    TableModule,
    ErrorMessageModule,
  ],
  declarations: [
    ExtInterfaceComponent,
    UploadComponent,
  ],
  exports:      [
    ExtInterfaceComponent,
  ],
})
export class ExtInterfaceModule {
}
