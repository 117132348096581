export const SaChartProperties: any = {
  yAxis: [
    { // Observations chart left.
      opposite: false,
      labels: {
        align: 'right',
        x: -3,
        format: '{value} g/kg',
      },
      title: {
        text: 'Salinity (g/kg)',
      },
      height: '80%',
      lineWidth: 2,
      resize: {
        enabled: true,
      },
    },
    { // Checks chart.
      opposite: true,
      showEmpty: false,
      labels: {
        enabled: false,
        align: 'right',
        x: -3,
        formatter() {
          return this.value + 'm';
        },
      },
      title: {
        text: 'Checks',
      },
      top: '85%',
      height: '15%',
      offset: 0,
      lineWidth: 2,
      resize: {
        enabled: true,
      },
    },
    { // Observations chart right.
      opposite: true,
      showEmpty: false,
      labels: {
        align: 'right',
        x: -3,
        format: '{value} ',
      },
      title: {
        text: 'Conductivity (mS/cm)',
      },
      height: '80%',
      lineWidth: 2,
      resize: {
        enabled: true,
      },
      offset: 0,
      floating: true,
    },
    { // Observations chart right.
      opposite: true,
      showEmpty: false,
      labels: {
        align: 'right',
        x: -3,
        format: '{value}',
      },
      title: {
        text: 'Water temprature (°C)',
      },
      height: '80%',
      lineWidth: 2,
      resize: {
        enabled: true,
      },
      offset: 0,
      floating: true,
    },
    { // Observations chart right.
      opposite: true,
      showEmpty: false,
      labels: {
        align: 'right',
        x: -3,
        format: '{value}',
      },
      title: {
        text: 'Chlorosity (mg/l)',
      },
      height: '80%',
      lineWidth: 2,
      resize: {
        enabled: true,
      },
      floating: true,
    },
    { // Observations chart right.
      opposite: true,
      showEmpty: false,
      labels: {
        align: 'right',
        x: -3,
        format: '{value}',
      },
      title: {
        text: 'Density (kg/m3)',
      },
      height: '80%',
      lineWidth: 2,
      resize: {
        enabled: true,
      },
      floating: true,
    },
  ],
};
