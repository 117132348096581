export enum ObservationMarkerSymbols {
  // CHECKS
  Extreme      = 'circle',
  Delta        = 'triangle',
  Count        = 'square',
  Distribution = 'diamond',
  Missing      = 'triangle-down',
  // Water
  H10sec       = 'circle',
  H01          = 'circle',
  H10          = 'circle',
  LW           = 'triangle-down',
  HW           = 'triangle',
  // VIS
  VIS12sec     = 'CIRCLE',
  VIS01        = 'CIRCLE',
  VIS10        = 'CIRCLE',
  // SA
  CL10         = 'CIRCLE',
  CON10        = 'CIRCLE',
  CON10sec     = 'CIRCLE',
  RHO10        = 'CIRCLE',
  SA10         = 'CIRCLE',
  // SA selection 2
  WT10sec      = 'CIRCLE',
  WT10         = 'CIRCLE',
  // TS
  TSD01        = 'CIRCLE',
  TSD10        = 'CIRCLE',
  TSR01        = 'CIRCLE',
  TSR10        = 'CIRCLE',
  // W
  WD10sec      = 'CIRCLE',
  WV10sec      = 'CIRCLE',
  WD12sec      = 'CIRCLE',
  WV12sec      = 'CIRCLE',
  WDV10        = 'CIRCLE',
  WV10         = 'CIRCLE',
  WD10         = 'CIRCLE',
  WG10         = 'CIRCLE',
  WDB10        = 'CIRCLE',
  WD01         = 'CIRCLE',
  WV01         = 'CIRCLE',
}

export enum ObservationTypesLineWidth {
  // Water
  H10sec = 2,
  H01 = 2,
  H10 = 2,
  LW = 2,
  HW = 2,
  CHECKS = 0,
  // VIS
  VIS12sec = 2,
  VIS01 = 2,
  VIS10 = 2,
  // SA
  CL10 = 2,
  CON10 = 2,
  CON10sec = 2,
  RHO10 = 2,
  SA10 = 2,
  // SA selection 2
  WT10sec = 2,
  WT10 = 2,
  // TS
  TSD01 = 2,
  TSD10 = 2,
  TSR01 = 2,
  TSR10 = 2,
  // W
  WD10sec = 2,
  WV10sec = 2,
  WD12sec = 2,
  WV12sec = 2,
  WDV10 = 2,
  WV10 = 2,
  WD10 = 2,
  WG10 = 2,
  WDB10 = 2,
  WD01 = 2,
  WV01 = 2,
}

export enum ObservationTypesGapSize {
  // Water
  H10sec = 10,
  H01 = 600,
  H10 = 6000,
  LW = 0.1, // show no line but needed for the legenda
  HW = 0.1, // show no line but needed for the legenda
  CHECKS = 0,
  // VIS
  VIS12sec = 12,
  VIS01 = 600,
  VIS10 = 600,
  // SA
  CL10 = 6000,
  CON10 = 6000,
  CON10sec = 10,
  RHO10 = 6000,
  SA10 = 6000,
  // SA selection 2
  WT10sec = 10,
  WT10 = 6000,
  // TS
  TSD01 = 600,
  TSD10 = 6000,
  TSR01 = 600,
  TSR10 = 6000,
  // W
  WD10sec = 10,
  WV10sec = 10,
  WD12sec = 12,
  WV12sec = 12,
  WDV10 = 600,
  WV10 = 600,
  WD10 = 600,
  WG10 = 600,
  WDB10 = 600,
  WD01 = 600,
  WV01 = 600,
}

export enum ObservationTypesColors {
  // Water
  H10sec = '#ff0000',
  H01 = '#00ff00',
  H10 = '#0000ff',
  LW = '#cc3399',
  HW = '#333300',
  CHECKS = '#000000',
  // VIS
  VIS12sec = '#ff0000',
  VIS01 = '#00ff00',
  VIS10 = '#0000ff',
  // SA
  CL10 = '#ff0000',
  CON10 = '#00ff00',
  CON10sec = '#0000ff',
  RHO10 = '#cc3399',
  SA10 = '#333300',
  // SA selection 2
  WT10sec = '#ff0000',
  WT10 = '#00ff00',
  // TS
  TSD01 = '#ff0000',
  TSD10 = '#00ff00',
  TSR01 = '#0000ff',
  TSR10 = '#cc3399',
  // W
  WD10sec = '#ff0000',
  WV10sec = '#00ff00',
  WD12sec = '#ff0000',
  WV12sec = '#00ff00',
  WDV10 = '#0000ff',
  WV10 = '#cc3399',
  WD10 = '#333300',
  WG10 = '#69c660',
  WDB10 = '#9c8c7d',
  WD01 = '#e4ce6d',
  WV01 = '#c83457',
}

export enum ObservationMarkerEnabled {
  // Water
  H10sec = 0,
  H01 = 0,
  H10 = 0,
  LW = 1,
  HW = 1,
  CHECKS = 1,
  // VIS
  VIS12sec = 0,
  VIS01 = 0,
  VIS10 = 0,
  // SA
  CL10 = 0,
  CON10 = 0,
  CON10sec = 0,
  RHO10 = 0,
  SA10 = 0,
  // SA selection 2
  WT10sec = 0,
  WT10 = 0,
  // TS
  TSD01 = 0,
  TSD10 = 0,
  TSR01 = 0,
  TSR10 = 0,
  // W
  WD10sec = 0,
  WV10sec = 0,
  WD12sec = 0,
  WV12sec = 0,
  WDV10 = 0,
  WV10 = 0,
  WD10 = 0,
  WG10 = 0,
  WDB10 = 0,
  WD01 = 0,
  WV01 = 0,
}

export enum ObservationTypesMarkerRadius {
  // Water
  H10sec = 0,
  H01 = 0,
  H10 = 0,
  LW = 3,
  HW = 3,
  CHECKS = 3,
  // VIS
  VIS12sec = 0,
  VIS01 = 0,
  VIS10 = 0,
  // SA
  CL10 = 0,
  CON10 = 0,
  CON10sec = 0,
  RHO10 = 0,
  SA10 = 0,
  // SA selection 2
  WT10sec = 0,
  WT10 = 0,
  // TS
  TSD01 = 0,
  TSD10 = 0,
  TSR01 = 0,
  TSR10 = 0,
  // W
  WD10sec = 0,
  WV10sec = 0,
  WD12sec = 0,
  WV12sec = 0,
  WDV10 = 0,
  WV10 = 0,
  WD10 = 0,
  WG10 = 0,
  WDB10 = 0,
  WD01 = 0,
  WV01 = 0,
}

export enum ObservationTypesEnabledInNavigator  {
  // Water
  H10sec = 1,
  H01 = 1,
  H10 = 1,
  LW = 0,
  HW = 0,
  CHECKS = 0,
  // VIS
  VIS12sec = 1,
  VIS01 = 1,
  VIS10 = 1,
  // SA
  CL10 = 1,
  CON10 = 1,
  CON10sec = 1,
  RHO10 = 1,
  SA10 = 1,
  // SA selection 2
  WT10sec = 1,
  WT10 = 1,
  // TS
  TSD01 = 1,
  TSD10 = 1,
  TSR01 = 1,
  TSR10 = 1,
  // W
  WD10sec = 1,
  WV10sec = 1,
  WD12sec = 1,
  WV12sec = 1,
  WDV10 = 1,
  WV10 = 1,
  WD10 = 1,
  WG10 = 1,
  WDB10 = 1,
  WD01 = 1,
  WV01 = 1,
}

export enum ObservationMarkerIndex {
  // SA
  CL10 = 4,
  RHO10 = 5,
  // SA selection 2
  WT10sec = 3,
  WT10 = 3,
  // TS
  CON10 = 2,
  CON10sec = 2,
  TSD01 = 2,
  TSD10 = 2,
  // W
  WD10sec = 2,
  WD12sec = 2,
  WD10 = 2,
  WD01 = 2,
  WG10 = 3,
  WDB10 = 4,
  WDV10 = 5,
}

export enum ObservationDefaultHidden {
  // SA
  CL10 = 1,
  RHO10 = 1,
  // W
  WG10 = 1,
  WDB10 = 1,
  WDV10 = 1,
}

export enum ObservationRounding {
  // Water
  H10sec       = 0,
  H01          = 0,
  H10          = 0,
  LW           = 0,
  HW           = 0,
  // VIS
  VIS12sec     = 0,
  VIS01        = 0,
  VIS10        = 0,
  // SA
  CL10         = 0,
  CON10        = 2,
  CON10sec     = 2,
  RHO10        = 1,
  SA10         = 3,
  // SA selection 2
  WT10sec      = 1,
  WT10         = 1,
  // TS
  TSD01        = 1,
  TSD10        = 1,
  TSR01        = 3,
  TSR10        = 3,
  // Wind
  WD10sec      = 1,
  WV10sec      = 3,
  WD12sec      = 1,
  WV12sec      = 3,
  WDV10        = 1,
  WV10         = 3,
  WD10         = 1,
  WG10         = 3,
  WDB10        = 1,
  WD01         = 1,
  WV01         = 3,
}
