import { FormControl, Validators } from '@angular/forms';
import { RangeValidator } from '../validator/range-validator';
import { ValidatorRegex } from '../validator/validator-enum';

interface MeasurementFormConfigInterface {
  featureName: FormControl;
  featureType: FormControl;
  featureDescription: FormControl;
  xCoordinateMeasurement: FormControl;
  yCoordinateMeasurement: FormControl;
  zCoordinateMeasurement: FormControl;
  endDate: FormControl;
  distance: FormControl;
  directionFrom: FormControl;
  directionTo: FormControl;
  napToALatConversion: FormControl;
}

export const TidalStreamMeasurementFormConfig: MeasurementFormConfigInterface = {
  featureName: new FormControl(null, [Validators.maxLength(40), Validators.required]),
  featureType: new FormControl(null, [Validators.required]),
  featureDescription: new FormControl(null, [Validators.maxLength(254), Validators.required]),
  xCoordinateMeasurement: new FormControl(null, [
    Validators.required,
    Validators.pattern(ValidatorRegex.Decimal),
  ]),
  yCoordinateMeasurement: new FormControl(null, [
    Validators.required,
    Validators.pattern(ValidatorRegex.Decimal),
  ]),
  zCoordinateMeasurement: new FormControl(null, [
    Validators.pattern(ValidatorRegex.Decimal),
  ]),
  endDate: new FormControl(null, [
  ]),
  distance: new FormControl(null, [
    Validators.required,
    Validators.pattern(ValidatorRegex.PositiveInteger),
  ]),
  directionFrom: new FormControl(null, [
    Validators.required,
    Validators.pattern(ValidatorRegex.PositiveInteger),
    RangeValidator.validate(0, 359),
  ]),
  directionTo: new FormControl(null, [
    Validators.required,
    Validators.pattern(ValidatorRegex.PositiveInteger),
    RangeValidator.validate(0, 359),
  ]),
  napToALatConversion: new FormControl(null, [
    Validators.pattern(ValidatorRegex.Decimal),
  ]),
};

export const GenericMeasurementFormConfig: MeasurementFormConfigInterface = {
  featureName: new FormControl(null, [Validators.maxLength(40), Validators.required]),
  featureType: new FormControl(null, [Validators.required]),
  featureDescription: new FormControl(null, [Validators.maxLength(254), Validators.required]),
  xCoordinateMeasurement: new FormControl(null, [
    Validators.required,
    Validators.pattern(ValidatorRegex.Decimal),
  ]),
  yCoordinateMeasurement: new FormControl(null, [
    Validators.required,
    Validators.pattern(ValidatorRegex.Decimal),
  ]),
  zCoordinateMeasurement: new FormControl(null, [
    Validators.pattern(ValidatorRegex.Decimal),
  ]),
  endDate: new FormControl(null, [
  ]),
  distance: new FormControl(null, [
    Validators.pattern(ValidatorRegex.PositiveInteger),
  ]),
  directionFrom: new FormControl(null, [
    Validators.pattern(ValidatorRegex.PositiveInteger),
    RangeValidator.validate(0, 359),
  ]),
  directionTo: new FormControl(null, [
    Validators.pattern(ValidatorRegex.PositiveInteger),
    RangeValidator.validate(0, 359),
  ]),
  napToALatConversion: new FormControl(null, [
    Validators.pattern(ValidatorRegex.Decimal),
  ]),
};


export const HeightOfTideMeasurementFormConfig: MeasurementFormConfigInterface = {
  featureName: new FormControl(null, [Validators.maxLength(40), Validators.required]),
  featureType: new FormControl(null, [Validators.required]),
  featureDescription: new FormControl(null, [Validators.maxLength(254), Validators.required]),
  xCoordinateMeasurement: new FormControl(null, [
    Validators.required,
    Validators.pattern(ValidatorRegex.Decimal),
  ]),
  yCoordinateMeasurement: new FormControl(null, [
    Validators.required,
    Validators.pattern(ValidatorRegex.Decimal),
  ]),
  zCoordinateMeasurement: new FormControl(null, [
    Validators.pattern(ValidatorRegex.Decimal),
  ]),
  endDate: new FormControl(null, [
  ]),
  distance: new FormControl(null, [
    Validators.pattern(ValidatorRegex.PositiveInteger),
  ]),
  directionFrom: new FormControl(null, [
    Validators.pattern(ValidatorRegex.PositiveInteger),
    RangeValidator.validate(0, 359),
  ]),
  directionTo: new FormControl(null, [
    Validators.pattern(ValidatorRegex.PositiveInteger),
    RangeValidator.validate(0, 359),
  ]),
  napToALatConversion: new FormControl(null, [
    Validators.required,
    Validators.pattern(ValidatorRegex.Decimal),
  ]),
};
