import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ModalComponent} from './modal.component';
import {StatusModalComponent} from './status-modal.component';

@NgModule({
  declarations: [
    ModalComponent,
    StatusModalComponent,
  ],
  exports:      [
    ModalComponent,
    StatusModalComponent,
  ],
  providers:    [
  ],
  imports:      [
    CommonModule,
  ],
})

export class ModalModule {

}
