import * as moment from 'moment';
import * as momentTimezone from 'moment-timezone';

export class TimeUtils {

  static dateTimeFormatUTC: string = 'YYYY-MM-DD HH:mm:ss.SSS Z';
  static dateTimeFormatString: string = 'YYYY-MM-DD HH:mm:ss.SSS';
  static timeFormatString: string = 'HH:mm:ss';

  static utcTimeZone: string = 'UTC';
  static dynamicLocalTimeZoneFormat: string = momentTimezone.tz.guess();

  static timeToISO(time: Date): string {
    const defaultTime = new Date(time);
    return defaultTime.toISOString();
  }

  static checkInvalidDates(startDateEpoc: number, endDateEpoc: number): boolean {
    return endDateEpoc <= startDateEpoc;
  }

  static convertUTCToLocal(timeInUTC: Date): any {
    const dateUTC = moment.utc(timeInUTC);
    /* detecting timezone based on browser,
     it is assumed that browser is accessed in Amsterdam/Rotterdam*/

    return momentTimezone(dateUTC).tz(TimeUtils.dynamicLocalTimeZoneFormat).format();
  }

}
