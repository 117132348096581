import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {SharedModule} from '../../shared/shared.module';
import {UiModule} from '../../shared/ui/ui.module';
import {ExtInterfaceModule} from '../ext-interface/ext-interface.module';
import {SidebarComponent} from './sidebar.component';

@NgModule({
  imports:      [
    RouterModule,

    UiModule,
    SharedModule,
    ExtInterfaceModule,
  ],
  exports:      [
    SidebarComponent,
  ],
  declarations: [
    SidebarComponent,
  ],
})

export class SidebarModule {

}
