import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Device } from '../../../shared/iot-platform/model/iot-device';
import { NGXLogger } from 'ngx-logger';
import { DeviceService } from '../../../shared/iot-platform/service/device.service';
import { SessionService } from '../../../shared/service/session.service';
import { Observable } from 'rxjs/Observable';
import { Sensor } from '../model/sensor.model';

@Injectable()
export class DeviceResolver implements Resolve<Device> {

  constructor(private logger: NGXLogger, private sessionService: SessionService, private deviceDataService: DeviceService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Device> {
    this.logger.debug('DeviceResolver: Waiting for sensor data to be available.');

    const sensor: Sensor = this.sessionService.getCurrentSensor();
    return this.deviceDataService.getDeviceBySensorTypeAndDeviceId(sensor.sensorType.deviceType, sensor.deviceId);
  }
}
