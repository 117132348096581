import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NGXLogger } from 'ngx-logger';
import { environment } from '../environments/environment';
import { SessionService } from './shared/service/session.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {

  constructor(private sessionService: SessionService,
              private logger: NGXLogger,
              private titleService: Title) {

    this.logger.debug('constructing AppComponent');
    this.sessionService.init();
    this.titleService.setTitle(environment.title);
  }
}
