import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { environment } from '../../../../environments/environment';
import { ApiDataService } from '../../../api/api.data.service';
import {AddCommand} from '../../../shared/iot-platform/commands/create-command';
import {Observable} from 'rxjs/Observable';

@Injectable()
export class CreateSensorDataService {

  public constructor(private logger: NGXLogger,
                     private apiDataService: ApiDataService) {
    this.logger.debug('constructing CreateSensorDataService');
  }

  public postCreateSensorData(createSensorPayload: AddCommand): Observable<any> {
    this.logger.debug(`CreateSensorDataService: ${createSensorPayload}`);
    const url: string = 'sensors';
    return this.apiDataService.post(url, createSensorPayload);
  }
}
