import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs/Observable';
import { ApiDataService } from '../../../api/api.data.service';
import { Feature, FeatureDTO } from '../model/feature.model';

@Injectable()
export class FeatureService {

  constructor(private logger: NGXLogger,
              private apiDataService: ApiDataService) {
    this.logger.debug('constructing FeatureService.');
  }

  public getSensorFeatures(id: string): Observable<Feature[]> {
    return this.apiDataService.findAllById('sensors/FeatureList', id)
    .map((featureDtos: FeatureDTO[]) => {
      return featureDtos.map((sensorDTO: FeatureDTO) => new Feature(sensorDTO));
    });
  }

  public getMetadataByFeatureId(id: string): Observable<any> {
    return this.apiDataService.findAllById('featuremetadata/featureid', id);
  }
}
