import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { ObservationChartUpdateInfo } from '../charts/observations/sensor/model/observation-chart-update-info.model';
import { StructuredObservations } from '../charts/observations/sensor/model/structured-observations.model';
import { ObservationUpdateInfo } from './observation-data-update-info.model';

@Injectable()
export class SensorDetailDataUpdatingService {

  // notifier for updating of chart data.
  private streamUpdate: BehaviorSubject<ObservationChartUpdateInfo[]>
    = new BehaviorSubject<ObservationChartUpdateInfo[]>([]);
  public streamUpdateObservable = this.streamUpdate.asObservable();
  // notifier for selection of dates by user.
  private manualDateSelect: BehaviorSubject<ObservationUpdateInfo> = new BehaviorSubject<ObservationUpdateInfo>(null);
  public manualDateSelectObservable = this.manualDateSelect.asObservable();

  constructor(private logger: NGXLogger) {
    this.logger.debug('StreamUpdateService: constructing.');
  }

  manualDateSelectNotifier(dateSelectionInfo: any): void {
    this.logger.debug('StreamUpdateService: user has selected date.');
    this.manualDateSelect.next(dateSelectionInfo);
  }
  streamUpdateNotifier(updateInfo: ObservationChartUpdateInfo[]): void {
    this.logger.debug('StreamUpdateService: one or more streams has been updated.');
    this.streamUpdate.next(updateInfo);
  }

  /**
   * This method checks if the latest retrieved value are indeed new and also updated the current observation list. It returns
   * an object with the needed information to update the current chart. this object has the following format:
   *
   * {
   *    stream: (which stream has updates)
   *    amount: (how many on the end of the observations list are new (this.observations))
   * }
   *
   * @param currentObservations current latest observations
   * @param latestObservations the observations retrieved to check if they are new
   */
  public setObservationUpdateInfo(
    currentObservations: StructuredObservations[],
    latestObservations: StructuredObservations[]): ObservationUpdateInfo[] {
    this.logger.debug('ObservationsUpdateService: Checking if the latest observations are new.');

    const updateInfo: ObservationUpdateInfo[] = [];

    for (const currentObservation of currentObservations) {
      for (const latestObservation of latestObservations) {
        if (currentObservation.stream === latestObservation.stream) {
          updateInfo.push(new ObservationUpdateInfo(currentObservation.stream, 0));
          for (const newObservation of latestObservation.observations) {
            // if later than our latest
            if (newObservation.ticks > currentObservation.observations[currentObservation.observations.length - 1].ticks) {
              // add it to corrrect stream of ours
              currentObservation.observations.push(newObservation);
              updateInfo[updateInfo.length - 1].amount++;
            }
          }
        }
      }
    }

    return updateInfo;
  }

}
