import {Injectable} from '@angular/core';
import {NGXLogger} from 'ngx-logger';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {StatusModalInfo} from './status-modal-info';

@Injectable()
export class StatusModalService {

  private modalTriggger = new BehaviorSubject<StatusModalInfo>(null);
  public trigger        = this.modalTriggger.asObservable();

  constructor(private logger: NGXLogger) {
    this.logger.debug('constructing StatusModalService.');
  }

  changeModalVisibility(info: StatusModalInfo) {
    this.modalTriggger.next(info);
  }

}
