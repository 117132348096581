/**
 * author: Jordy Roelofs
 */
export class StatusModalInfo {

  status: ModalStatus;
  message: string;

  constructor(status: ModalStatus, message: string) {
    this.status  = status;
    this.message = message;
  }
}

export enum ModalStatus {

  OK      = 'ok',
  CLOSE   = 'close',
  ERROR   = 'error',
  LOADING = 'loading',
  NONE    = '',

}
