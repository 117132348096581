import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs/Observable';
import { environment } from '../../environments/environment';
import { LoginService } from '../login/service/login.service';
import { ModalStatus, StatusModalInfo } from '../shared/modal/status-modal-info';
import { StatusModalService } from '../shared/modal/status-modal.service';

import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/finally';
import 'rxjs/add/operator/map';
import { Feature } from '../components/sensor/model/feature.model';


/* istanbul ignore next: not unit testing http calls */
export class ApiDataService {

  private readonly serverUrl: string = environment.apiUrl;
  private readonly defaultHeader = new HttpHeaders();

  constructor(private readonly logger: NGXLogger,
              private readonly http: HttpClient,
              private readonly router: Router,
              private readonly statusModalService: StatusModalService,
              private readonly loginService: LoginService) {
    this.logger.debug('constructing ApiDataService');
  }

  public getAll(apiUrl: string, page: number = 0, size: number = 0, sort: string = 'asc', indicator: boolean = true): Observable<any> {

    let observable: Observable<any>;

    if (indicator) {
      this.statusModalService.changeModalVisibility(new StatusModalInfo(ModalStatus.LOADING, ''));
    }

    switch (apiUrl) {
      case 'filemetadata/astro':
      case 'filemetadata/osr':
      case 'filemetadata/rws':
      case 'sensors/overview':
      case 'watson-iot/devices/findAll':
        observable = this.http.get(`${this.serverUrl}${apiUrl}?page=${page}&size=${size}&sort=${sort}`,
          {headers: this.defaultHeader}).map((response: Response) => response);
        break;
      default:
        this.logger.debug(this.serverUrl + apiUrl);
        observable = this.http.get(`${this.serverUrl}${apiUrl}?page=${page}&size=${size}&sort=${sort}`,
          {headers: this.defaultHeader}).map((response: Response) => response['_embedded']['content']);
        break;
    }

    return observable.catch(this.handleError).finally(() => {
      this.statusModalService.changeModalVisibility(new StatusModalInfo(ModalStatus.CLOSE, ''));
    });
  }

  public findByQuery(apiUrl: string, param1: string, param2: string, param3: string,
                     page: number = 0, indicator: boolean = true, size: number = 10000, sort: string = 'asc'): Observable<any> {

    if (indicator) {
      this.statusModalService.changeModalVisibility(new StatusModalInfo(ModalStatus.LOADING, ''));
    }

    return this.http.get(`${this.serverUrl}${apiUrl}/findAllByQueryForChart?page=${page}&size=${size}&sort=${sort}&${param1}&${param2}&${param3}`,
      {headers: this.defaultHeader}).map((response: Response) => response['_embedded']['content']).catch(this.handleError).finally(() => {
      this.statusModalService.changeModalVisibility(new StatusModalInfo(ModalStatus.CLOSE, ''));
    });
  }

  public findAllById(apiUrl: string, idOne: string, idTwo: string = ''): Observable<any> {
    this.statusModalService.changeModalVisibility(new StatusModalInfo(ModalStatus.LOADING, ''));
    switch (apiUrl) {
      case 'sensors/FeatureList':
        return this.http.get(`${this.serverUrl}sensors/findAllWithFeatureList?sensorId=${idOne}`,
          {headers: this.defaultHeader}).map((response: Response) => {
            return response['_embedded']['content'][0]['features'] as Feature[];
          }
        ).catch(this.handleError)
        .finally(() => {
          this.statusModalService.changeModalVisibility(new StatusModalInfo(ModalStatus.CLOSE, ''));
        });
      case 'featuremetadata/featureid':
        return this.http.get(`${this.serverUrl}featuremetadata/findAllProjectedByQuery?featureId=${idOne}`,
          {headers: this.defaultHeader}).catch(this.handleError).finally(() => {
          this.statusModalService.changeModalVisibility(new StatusModalInfo(ModalStatus.CLOSE, ''));
        });
      case 'watson-iot/devices/findAllBySensorType':
        return this.http.get(`${this.serverUrl}watson-iot/devices/findAllBySensorType?sensorType=${idOne}`,
          {headers: this.defaultHeader}).catch(this.handleError).finally(() => {
          this.statusModalService.changeModalVisibility(new StatusModalInfo(ModalStatus.CLOSE, ''));
        });
      case 'watson-iot/devices/findOneBySensorTypeAndDeviceId':
        return this.http.get(`${this.serverUrl}watson-iot/devices/findOneBySensorTypeAndDeviceId?sensorType=${idOne}&deviceId=${idTwo}`,
          {headers: this.defaultHeader}).catch(this.handleError).finally(() => {
          this.statusModalService.changeModalVisibility(new StatusModalInfo(ModalStatus.CLOSE, ''));
        });
      case 'featureTypes/findAllBySensorType':
        return this.http.get(`${this.serverUrl}featureTypes/findAllBySensorType?sensorType=${idOne}`,
          {headers: this.defaultHeader}).catch(this.handleError).finally(() => {
          this.statusModalService.changeModalVisibility(new StatusModalInfo(ModalStatus.CLOSE, ''));
        });
      default:
        return Observable.throw(`Unknown api url provided: '${apiUrl}'`).catch(this.handleError).finally(() => {
          this.statusModalService.changeModalVisibility(new StatusModalInfo(ModalStatus.CLOSE, ''));
        });
    }
  }

  public getOne(apiUrl: string, id: number | string): Observable<any> {
    this.logger.debug(this.serverUrl + apiUrl + '/' + id);
    this.statusModalService.changeModalVisibility(new StatusModalInfo(ModalStatus.LOADING, ''));

    return this.http.get(this.serverUrl + apiUrl + '/' + id, {headers: this.defaultHeader})
    .map((data: any) => data['_embedded']['content'])
    .catch(this.handleError)
    .finally(() => {
      this.statusModalService.changeModalVisibility(new StatusModalInfo(ModalStatus.CLOSE, ''));
    });
  }

  public createOne(apiUrl, object: Object): Observable<any> {
    return this.post(apiUrl, object);
  }

  public updateOne(apiUrl, object: Object): Observable<any> {
    this.statusModalService.changeModalVisibility(new StatusModalInfo(ModalStatus.LOADING, ''));

    return this.http.put(this.serverUrl + apiUrl, object, {headers: this.defaultHeader}).catch(this.handleError).finally(() => {
      this.statusModalService.changeModalVisibility(new StatusModalInfo(ModalStatus.CLOSE, ''));
    });
  }

  public updateOneWithId(apiUrl: string, object: any, id: string = ''): Observable<any> {
    this.statusModalService.changeModalVisibility(new StatusModalInfo(ModalStatus.LOADING, ''));

    return this.http.put(
      this.serverUrl + apiUrl + '/' + id,
      object,
      {headers: this.defaultHeader})
    .catch(this.handleError)
    .finally(() => {
      this.statusModalService.changeModalVisibility(new StatusModalInfo(ModalStatus.CLOSE, ''));
    });
  }

  public updateOneForMultiplePaths(object: any, primaryPath: string = '', primaryId: string = '',
                                   secondaryPath: string = '', secondaryId: string = ''): Observable<any> {
    this.statusModalService.changeModalVisibility(new StatusModalInfo(ModalStatus.LOADING, ''));

    return this.http.put(this.serverUrl + primaryPath + '/' + primaryId + '/' + secondaryPath + '/' + secondaryId,
      object, {headers: this.defaultHeader})
        .catch(this.handleError)
        .finally(() => {
          this.statusModalService.changeModalVisibility(new StatusModalInfo(ModalStatus.CLOSE, ''));
        });
  }

  public createOneForMultiplePaths(object: any, primaryPath: string = '', primaryId: string = '',
                                   secondaryPath: string = ''): Observable<any> {

    const url = primaryPath + '/' + primaryId + '/' + secondaryPath;

    return this.post(url, object);
  }

  public post(apiUrl, object: any): Observable<any> {
    this.statusModalService.changeModalVisibility(new StatusModalInfo(ModalStatus.LOADING, ''));

    return this.http.post(this.serverUrl + apiUrl, object).catch(this.handleError).finally(() => {
      this.statusModalService.changeModalVisibility(new StatusModalInfo(ModalStatus.CLOSE, ''));
    });
  }

  public postAstro(url: string, object: any): Observable<any> {

    this.statusModalService.changeModalVisibility(new StatusModalInfo(ModalStatus.LOADING, ''));

    return this.http.post(url, object, {
      headers: {
        'cache-control': 'no-cache',
        'x-ibm-client-id': environment.astro_api_clientId,
        'accept': '*/*',
      },
    }).catch(this.handleError)
    .finally(() => {
      this.statusModalService.changeModalVisibility(new StatusModalInfo(ModalStatus.CLOSE, ''));
    });
  }

  public getRWSMetaData(url: string): Observable<any> {
    this.statusModalService.changeModalVisibility(new StatusModalInfo(ModalStatus.LOADING, ''));

    return this.http.get(url, {
      headers: {
        'cache-control': 'no-cache',
        'x-ibm-client-id': environment.rws_api_clientId,
        'accept': '*/*',
      },
    }).catch(this.handleError)
    .finally(() => {
      this.statusModalService.changeModalVisibility(new StatusModalInfo(ModalStatus.CLOSE, ''));
    });
  }

  public handleError(error: any) {
    if (error.value.status === 500 && error.value.statusText === 'Invalid-JWT-Validate') {
      sessionStorage.clear();
      if (this.loginService) {
        this.loginService.login();
      } else {
        window.location.href = '';
      }
    }

    return Observable.throw(error);
  }
}
