import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../../authorization/service/auth-guard';
import { SensorResolver } from '../resolver/sensor.resolver';
import { SensorLogResolver } from '../resolver/sensorLog.resolver';
import { SensorDetailChecksComponent } from './checks/sensor-detail-checks.component';
import { SensorDetailDataComponent } from './data/sensor-detail-data.component';
import { SensorDetailComponent } from './sensor-detail.component';
import { SensorStreamPropertiesResolver } from './stream/resolver/sensor-stream-properties-resolver';
import { SensorDetailStreamComponent } from './stream/sensor-detail-stream.component';
import { SensorDetailInfoComponent } from './info/sensor-detail-info.component';
import { DeviceResolver } from '../resolver/device.resolver';
import { FeatureResolver } from '../resolver/feature.resolver';

const routes: Routes = [
  {
    path: '',
    component: SensorDetailComponent,
    canActivate: [AuthGuard],
    resolve: {
      sensor: SensorResolver,
      features: FeatureResolver,
    },
    pathMatch: 'prefix',
    children: [
      {
        path: 'stream',
        component: SensorDetailStreamComponent,
        data: {
          resource: '/sensors',
          rights: ['readOne'],
        },
        resolve: {
          streamProperties: SensorStreamPropertiesResolver,
          sensorLog: SensorLogResolver,
        },
      },
      {
        path: 'data',
        data: {
          resource: '/sensors/observations',
          rights: ['readOne'],
        },
        component: SensorDetailDataComponent,
      },
      {
        path: 'checks',
        data: {
          resource: '/sensors/checks',
          rights: ['readOne'],
        },
        component: SensorDetailChecksComponent,
      },
      {
        path: 'info',
        data: {
          resource: '/sensors',
          rights: ['readOne'],
        },
        component: SensorDetailInfoComponent,
        resolve: {
          device: DeviceResolver,
        },
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
  ],
})
export class SensorDetailRoutingModule {
}
