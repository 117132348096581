import { Process } from '../../process/model/process.model';
import { SensorType } from './sensor-type.model';

export interface SensorDTO {
  id: string;
  name: string;
  startDate: string;
  endDate: string;
  instrumentType: string;
  processes: Process[];
  wkid: number;
  x: number;
  y: number;
  z: number;
  active: boolean;
  status: string;
  deviceId: string;
  gatewayId: string;
  gatewayType: string;
  publishing: boolean;
  sensorType: SensorType;
  locationName: string;
}

export class Sensor {

  public id: string;
  public name: string;
  public startDate: Date;
  public endDate: Date;
  public instrumentType: string;
  public processes: Process[];
  public wkid: number;
  public x: number;
  public y: number;
  public z: number;
  public active: boolean;
  public status: string;
  public publishing: boolean;
  public deviceId: string;
  public gatewayId: string;
  public gatewayType: string;
  public sensorType: SensorType;
  public locationName: string;

  constructor(sensorDTO: SensorDTO) {
    this.id = sensorDTO.id;
    this.name = sensorDTO.name;
    this.startDate = sensorDTO.startDate ? new Date(sensorDTO.startDate) : null;
    this.endDate = sensorDTO.endDate ? new Date(sensorDTO.endDate) : null;
    this.instrumentType = sensorDTO.instrumentType;
    this.processes = sensorDTO.processes;
    this.wkid = sensorDTO.wkid;
    this.x = sensorDTO.x;
    this.y = sensorDTO.y;
    this.z = sensorDTO.z;
    this.active = sensorDTO.active;
    this.status = sensorDTO.status;
    this.publishing = sensorDTO.publishing;
    this.deviceId = sensorDTO.deviceId;
    this.gatewayId = sensorDTO.gatewayId;
    this.gatewayType = sensorDTO.gatewayType;
    this.sensorType = sensorDTO.sensorType;
    this.locationName = sensorDTO.locationName;
  }
}
