import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs/Observable';
import { Device } from '../model/iot-device';
import { DeviceService } from '../service/device.service';

import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/finally';
import 'rxjs/add/operator/map';

@Injectable()
export class IotDevicesResolver implements Resolve<Device[]> {

  constructor(private deviceService: DeviceService,
              private logger: NGXLogger,
              private router: Router) {
    this.logger.debug('constructing devices resolver.');
  }

  resolve(route: ActivatedRouteSnapshot,
          state: RouterStateSnapshot): Observable<Device[]> {
    this.logger.debug('IotDevicesResolver: Waiting for sensor data to be available.');

    return this.deviceService.getAllDevices().catch((error) => {
      if (error === 404) {
        this.router.navigate(['404']);
      } else {
        this.router.navigate(['error']);
      }
      return Observable.of(null);
    });
  }
}
