import { UpdateSensorStatusDto } from '../model/update-sensor-status-dto.model';

export class UpdateSensorStatusDtoFactory {
  public create(reason: string, active: boolean, publishing: boolean): UpdateSensorStatusDto {

    return new UpdateSensorStatusDto(
      reason, active, publishing
    );
  }
}
