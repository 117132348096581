import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs/Observable';
import { environment } from '../../../../environments/environment';
import { ApiDataService } from '../../../api/api.data.service';
import { RwsMetaData, RwsMetaDataInterface } from '../model/rws-meta-data.model';

@Injectable()
export class RwsDataService {

  constructor(private logger: NGXLogger,
              private apiDataService: ApiDataService) {
    this.logger.debug('constructing RwsDataService');
  }

  public getAllRwsMetaData(): Observable<RwsMetaData[]> {
    this.logger.debug('RwsDataService: retrieving metadata.');
    const url: string = environment.rws_api + 'rwsMetaData';
    return this.apiDataService.getRWSMetaData(url).map((metaDataArray: RwsMetaDataInterface[]) => {
      const rwsMetaData: RwsMetaData[] = [];
      for (const metaData of metaDataArray) {
        rwsMetaData.push(new RwsMetaData(metaData));
      }
      return rwsMetaData;
    });
  }
}
