import { PermissionBasedEntity } from '../../../../components/authorization/model/permission-based-entity.model';
import { SessionService } from '../../../service/session.service';

export class TabItem extends PermissionBasedEntity {

  public label: string;
  public url: string;

  constructor(sessionService: SessionService, label: string = 'unkown', url: string = '',
              resource: string, rights: string[]) {

    super(sessionService, resource, rights);
    this.determineVisibility();

    this.label = label;
    this.url = url;
  }
}
