import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {SharedModule} from '../shared/shared.module';
import {UiModule} from '../shared/ui/ui.module';
import {ExtInterfaceModule} from './ext-interface/ext-interface.module';
import {MainComponent} from './main.component';
import {MainRoutingModule} from './main.routing.module';
import {MapModule} from './map/map.module';
import {MenuBarModule} from './menubar/menu-bar.module';
import {SensorModule} from './sensor/sensor.module';
import {SidebarModule} from './sidebar/sidebar.module';

@NgModule({
  imports:      [
    RouterModule,
    MainRoutingModule,

    UiModule,
    SharedModule,

    SidebarModule,
    MenuBarModule,
    MapModule,
    SensorModule,
    ExtInterfaceModule,
  ],
  declarations: [MainComponent],
  providers:    [Window],
})

export class MainModule {

}
