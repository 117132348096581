import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs/Observable';
import { environment } from '../../../environments/environment';
import { SessionService } from './session.service';

import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/finally';
import 'rxjs/add/operator/map';

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {

  private clientIdInjectorMap = {};
  private expectedEndPointIndicator = environment.expectedEndPointIndicator;

  constructor(private router: Router,
              private sessionsService: SessionService,
              private logger: NGXLogger) {

    this.logger.debug('HttpInterceptorService: constructing.');
    this.clientIdInjectorMap['observations'] = environment.apiKey;
    this.clientIdInjectorMap['astro'] = environment.astro_api_clientId;
    this.clientIdInjectorMap['osr'] = environment.osr_api_clientId;
  }

  getAppUrl(url: string): string {
    this.logger.debug('HttpInterceptorService: getting app url.');
    const urlParts = url.split('/');
    return urlParts[this.expectedEndPointIndicator];
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    this.logger.debug('HttpInterceptorService: intercepting.');

    // TODO: determine if this section is really needed except for the CLIENT ID and acces-control-allow-origin
    let appUrl;
    let clientId;
    appUrl = this.getAppUrl(request.url);
    clientId = this.clientIdInjectorMap[appUrl];
    if (clientId === undefined) {
      clientId = this.clientIdInjectorMap['observations'];
    }
    const newRequest = request.clone({
      headers: request.headers.set('X-IBM-CLIENT-ID', clientId)
      .set('Authorization', 'Bearer ' + this.sessionsService.getAccessToken())
      .set('Access-Control-Allow-Origin', '*'),
    });

    return next.handle(newRequest).catch((error: HttpErrorResponse) => {
      if (error.status >= 200 && error.status < 300) {
        const res = new HttpResponse({
          body: null,
          headers: error.headers,
          status: error.status,
          statusText: error.statusText,
          url: error.url,
        });
        return Observable.of(res);
      } else {
        throw Observable.of(error);
      }
    });
  }

}
