export const HChartProperties: any = {
  yAxis: [
    { // Observations chart.
      opposite: false,
      labels: {
        align: 'right',
        x: -3,
        format: '{value} cm',
      },
      title: {
        text: 'Height in Centimeters (cm)',
      },
      height: '80%',
      lineWidth: 2,
      resize: {
        enabled: true,
      },
    },
    { // Checks chart.
      labels: {
        enabled: false,
        align: 'right',
        x: -3,
        formatter() {
          return this.value + 'cm';
        },
      },
      title: {
        text: 'Checks',
      },
      top: '85%',
      height: '15%',
      offset: 0,
      lineWidth: 2,
    },
  ],
};
